import { Button, CircularProgress, Collapse, Divider, Grid, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useRootStore } from "../../contexts/RootContext";

const CompanyEdit = observer(() => {
  const { myPageStore } = useRootStore();
  const { organization } = myPageStore;
  const [pending, setPending] = useState(false);
  useEffect(() => {
    myPageStore.fetchOrganization();
  }, []);
  return (
    <>
      <Typography variant="h3" marginTop={"46px"}>
        소속기관 정보
      </Typography>
      <Divider sx={{ marginTop: "10px", marginBottom: "15px" }} />
      <Grid container alignItems={"center"} rowSpacing="12px">
        <Grid item md={2}>
          <Typography className="input-label">기관명</Typography>
        </Grid>
        <Grid item md={10} container>
          <input className="input" value={organization.name} onChange={(e) => organization.setName(e.target.value)} />
        </Grid>
        <Grid item md={2}>
          <Typography className="input-label">부서명</Typography>
        </Grid>
        <Grid item md={10} container>
          <input
            type="text"
            className="input secondary"
            value={organization.department_name}
            onChange={(e) => organization.setDepartmentName(e.target.value)}
          />
        </Grid>
        <Grid item md={2}>
          <Typography className="input-label">담당자</Typography>
        </Grid>
        <Grid item md={10} container>
          <input
            type="text"
            className="input secondary"
            value={organization.charger_name}
            onChange={(e) => organization.setChargerName(e.target.value)}
          />
        </Grid>
        <Grid item md={2}>
          <Typography className="input-label">
            연락처 <span style={{ color: "#50CDD0" }}>*</span>
          </Typography>
        </Grid>
        <Grid item md={10} container display={"flex"} alignItems="center">
          <input
            type="text"
            maxLength={3}
            className="input phone-number"
            style={{ width: "137px" }}
            value={organization.contactNumberPart(0)}
            onChange={(e) => /^[0-9]{0,3}$/.test(e.target.value) && organization.setPart(e.target.value, 0)}
          />
          <span className="dash">-</span>
          <input
            type="text"
            maxLength={4}
            className="input phone-number"
            value={organization.contactNumberPart(1)}
            onChange={(e) => /^[0-9]{0,4}$/.test(e.target.value) && organization.setPart(e.target.value, 1)}
          />
          <span className="dash">-</span>
          <input
            type="text"
            maxLength={4}
            className="input phone-number"
            value={organization.contactNumberPart(2)}
            onChange={(e) => /^[0-9]{0,4}$/.test(e.target.value) && organization.setPart(e.target.value, 2)}
          />
        </Grid>
      </Grid>
      <Grid container marginTop={"40px"}>
        <ul>
          <li>소속기관 정보를 입력하시면 기관회원으로 상태가 변경됩니다.</li>
        </ul>
      </Grid>
      <Grid container justifyContent={"center"}>
        <Button
          className="submit-btn"
          disabled={pending}
          onClick={() => organization.updateOrganization(setPending)}
          sx={{
            width: "180px",
            height: "38px",
            backgroundColor: "#50cdd0",
            fontSize: "18px",
            fontWeight: "bold",
            color: "#fff",
            borderRadius: "23.5px",
            marginTop: "20px",
            "&:hover": {
              backgroundColor: "#3d9ea1",
            },
          }}
        >
          {pending && <CircularProgress size={20} sx={{ position: "absolute", color: "#fff" }} />}
          수정완료
        </Button>
      </Grid>
    </>
  );
});

export default CompanyEdit;
