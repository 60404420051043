import { useState, ChangeEvent } from "react";
import { Button, Checkbox, Container, Dialog, FormControlLabel, Grid } from "@mui/material";
import { RequiredMark } from "../RequiredMark";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../../../contexts/RootContext";

type PrivacyPolicyModalProps = {
  open: boolean;
  setOpen: Function;
};

const PrivacyPolicyModal = (props: PrivacyPolicyModalProps) => {
  const closeHandler = () => {
    props.setOpen(false);
  };

  const submitHandler = () => {
    closeHandler();
  };

  return (
    <Dialog aria-labelledby="privacy-dialog-title" open={props.open} onClose={closeHandler} maxWidth="md">
      <Grid container padding={"2rem"} spacing={2} justifyContent={"center"}>
        <Grid item xs={12}>
          <h1 className="modal-title">서비스 이용약관</h1>
        </Grid>
        <Grid item xs={12}>
          <h3>1. 개인정보처리방침의 의의</h3>
        </Grid>
        <Grid item xs={12}>
          <p className="privicy-modal-text">
            네이버는 본 개인정보처리방침은 개인정보보호법을 기준으로 작성하되, 네이버 내에서의 이용자 개인정보 처리
            현황을 최대한 알기 쉽고 상세하게 설명하기 위해 노력하였습니다. 이는 쉬운 용어를 사용한 개인정보처리방침 작성
            원칙인 ‘Plain Language Privacy Policy(쉬운 용어를 사용한 개인정보처리방침)’를 도입한 것입니다. ‘쉬운 용어를
            사용한 개인정보처리방침 작성 체크리스트(영문)’ 바로가기 개인정보처리방침은 다음과 같은 중요한 의미를 가지고
            있습니다. 네이버가 어떤 정보를 수집하고, 수집한 정보를 어떻게 사용하며, 필요에 따라 누구와 이를 공유(‘위탁
            또는 제공’)하며, 이용목적을 달성한 정보를 언제·어떻게 파기하는지 등 ‘개인정보의 한살이’와 관련한 정보를
            투명하게 제공합니다. 정보주체로서 이용자는 자신의 개인정보에 대해 어떤 권리를 가지고 있으며, 이를 어떤
            방법과 절차로 행사할 수 있는지를 알려드립니다. 또한, 법정대리인(부모 등)이 만14세 미만 아동의 개인정보
            보호를 위해 어떤 권리를 행사할 수 있는지도 함께 안내합니다. 개인정보 침해사고가 발생하는 경우, 추가적인
            피해를 예방하고 이미 발생한 피해를 복구하기 위해 누구에게 연락하여 어떤 도움을 받을 수 있는지 알려드립니다.
            그 무엇보다도, 개인정보와 관련하여 네이버와 이용자간의 권리 및 의무 관계를 규정하여 이용자의
            ‘개인정보자기결정권’을 보장하는 수단이 됩니다.
          </p>
        </Grid>
        <Grid item xs={12}>
          <h3>2. 수집하는 개인정보</h3>
        </Grid>
        <Grid item xs={12}>
          <p className="privicy-modal-text">
            이용자는 회원가입을 하지 않아도 정보 검색, 뉴스 보기 등 대부분의 네이버 서비스를 회원과 동일하게 이용할 수
            있습니다. 이용자가 메일, 캘린더, 카페, 블로그 등과 같이 개인화 혹은 회원제 서비스를 이용하기 위해 회원가입을
            할 경우, 네이버는 서비스 이용을 위해 필요한 최소한의 개인정보를 수집합니다. 회원가입 시점에 네이버가
            이용자로부터 수집하는 개인정보는 아래와 같습니다. 회원 가입 시에 ‘아이디, 비밀번호, 이름, 생년월일, 성별,
            휴대전화번호’를 필수항목으로 수집합니다. 만약 이용자가 입력하는 생년월일이 만14세 미만 아동일 경우에는
            법정대리인 정보(법정대리인의 이름, 생년월일, 성별, 중복가입확인정보(DI), 휴대전화번호)를 추가로 수집합니다.
            그리고 선택항목으로 이메일 주소, 프로필 정보를 수집합니다. 단체아이디로 회원가입 시 단체아이디, 비밀번호,
            단체이름, 이메일주소, 휴대전화번호를 필수항목으로 수집합니다. 그리고 단체 대표자명을 선택항목으로
            수집합니다.
          </p>
        </Grid>
        <Grid item xs={12} md={4} container spacing={2}>
          <Grid item xs={6}>
            <Button variant="outlined" onClick={closeHandler} fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" onClick={submitHandler} fullWidth>
              Submit
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

type PersonalInformationCollectionModalProps = {
  open: boolean;
  setOpen: Function;
};

const PersonalInformationCollectionModal = (props: PersonalInformationCollectionModalProps) => {
  const closeHandler = () => {
    props.setOpen(false);
  };

  const submitHandler = () => {
    closeHandler();
  };

  return (
    <Dialog aria-labelledby="privacy-dialog-title" open={props.open} onClose={closeHandler} maxWidth="md">
      <Grid container padding={"2rem"} spacing={2} justifyContent={"center"}>
        <Grid item xs={12}>
          <h1 className="modal-title">개인정보 수집 및 이용 동의</h1>
        </Grid>
        <Grid item xs={12}>
          <h3>1. 개인정보처리방침의 의의</h3>
        </Grid>
        <Grid item xs={12}>
          <p className="privicy-modal-text">
            네이버는 본 개인정보처리방침은 개인정보보호법을 기준으로 작성하되, 네이버 내에서의 이용자 개인정보 처리
            현황을 최대한 알기 쉽고 상세하게 설명하기 위해 노력하였습니다. 이는 쉬운 용어를 사용한 개인정보처리방침 작성
            원칙인 ‘Plain Language Privacy Policy(쉬운 용어를 사용한 개인정보처리방침)’를 도입한 것입니다. ‘쉬운 용어를
            사용한 개인정보처리방침 작성 체크리스트(영문)’ 바로가기 개인정보처리방침은 다음과 같은 중요한 의미를 가지고
            있습니다. 네이버가 어떤 정보를 수집하고, 수집한 정보를 어떻게 사용하며, 필요에 따라 누구와 이를 공유(‘위탁
            또는 제공’)하며, 이용목적을 달성한 정보를 언제·어떻게 파기하는지 등 ‘개인정보의 한살이’와 관련한 정보를
            투명하게 제공합니다. 정보주체로서 이용자는 자신의 개인정보에 대해 어떤 권리를 가지고 있으며, 이를 어떤
            방법과 절차로 행사할 수 있는지를 알려드립니다. 또한, 법정대리인(부모 등)이 만14세 미만 아동의 개인정보
            보호를 위해 어떤 권리를 행사할 수 있는지도 함께 안내합니다. 개인정보 침해사고가 발생하는 경우, 추가적인
            피해를 예방하고 이미 발생한 피해를 복구하기 위해 누구에게 연락하여 어떤 도움을 받을 수 있는지 알려드립니다.
            그 무엇보다도, 개인정보와 관련하여 네이버와 이용자간의 권리 및 의무 관계를 규정하여 이용자의
            ‘개인정보자기결정권’을 보장하는 수단이 됩니다.
          </p>
        </Grid>
        <Grid item xs={12}>
          <h3>2. 수집하는 개인정보</h3>
        </Grid>
        <Grid item xs={12}>
          <p className="privicy-modal-text">
            이용자는 회원가입을 하지 않아도 정보 검색, 뉴스 보기 등 대부분의 네이버 서비스를 회원과 동일하게 이용할 수
            있습니다. 이용자가 메일, 캘린더, 카페, 블로그 등과 같이 개인화 혹은 회원제 서비스를 이용하기 위해 회원가입을
            할 경우, 네이버는 서비스 이용을 위해 필요한 최소한의 개인정보를 수집합니다. 회원가입 시점에 네이버가
            이용자로부터 수집하는 개인정보는 아래와 같습니다. 회원 가입 시에 ‘아이디, 비밀번호, 이름, 생년월일, 성별,
            휴대전화번호’를 필수항목으로 수집합니다. 만약 이용자가 입력하는 생년월일이 만14세 미만 아동일 경우에는
            법정대리인 정보(법정대리인의 이름, 생년월일, 성별, 중복가입확인정보(DI), 휴대전화번호)를 추가로 수집합니다.
            그리고 선택항목으로 이메일 주소, 프로필 정보를 수집합니다. 단체아이디로 회원가입 시 단체아이디, 비밀번호,
            단체이름, 이메일주소, 휴대전화번호를 필수항목으로 수집합니다. 그리고 단체 대표자명을 선택항목으로
            수집합니다.
          </p>
        </Grid>
        <Grid item xs={12} md={4} container spacing={2}>
          <Grid item xs={6}>
            <Button variant="outlined" onClick={closeHandler} fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" onClick={submitHandler} fullWidth>
              Submit
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

const PrivacyAndPolicyValidator = observer(() => {
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [showCollectionModal, setShowCollectionModal] = useState(false);
  const { signupStore } = useRootStore();
  const {
    agree_to_terms_and_conditions,
    agree_to_collect_and_use_personal_info,
    setAgreeToTermsAndConditions,
    setAgreeToCollectAndUsePersonalInfo,
  } = signupStore;

  return (
    <Container>
      <PrivacyPolicyModal open={showPrivacyModal} setOpen={setShowPrivacyModal} />
      <PersonalInformationCollectionModal open={showCollectionModal} setOpen={setShowCollectionModal} />

      <Grid container marginTop={"20px"}>
        <Grid item xs={9} md={11}>
          <FormControlLabel
            value="end"
            className="privacy-checkbox"
            control={
              <>
                <Checkbox
                  color="primary"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setAgreeToTermsAndConditions(e.target.checked);
                  }}
                  checked={agree_to_terms_and_conditions}
                  size="small"
                />
                <RequiredMark />
              </>
            }
            label="서비스 이용약관 동의"
            labelPlacement="end"
          />
        </Grid>
        <Grid item xs={3} md={1}>
          <Button fullWidth onClick={() => setShowPrivacyModal(true)} color="primary" size="small">
            약관 보기{" "}
          </Button>
        </Grid>
        <Grid item xs={9} md={11}>
          <FormControlLabel
            value="end"
            className="privacy-checkbox"
            control={
              <>
                <Checkbox
                  color="primary"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setAgreeToCollectAndUsePersonalInfo(e.target.checked);
                  }}
                  checked={agree_to_collect_and_use_personal_info}
                  size="small"
                />
                <RequiredMark />
              </>
            }
            label="개인정보 수집 및 이용 동의"
            labelPlacement="end"
          />
        </Grid>
        <Grid item xs={3} md={1}>
          <Button fullWidth onClick={() => setShowCollectionModal(true)} color="primary" size="small">
            약관 보기{" "}
          </Button>
        </Grid>
      </Grid>
      <hr />
    </Container>
  );
});

export default PrivacyAndPolicyValidator;
