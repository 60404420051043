import Grid from "@mui/material/Grid";
import { observer } from "mobx-react-lite";
import { useDetailedDataStore } from "../../../../../contexts/DetailedDataContext";
import CustomSelect from "../common/CustomSelect";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { DataGrid, GridColDef, GridRowParams } from "@mui/x-data-grid";
import moment from "moment";

import { AiGateSectionOneType } from "../../../../../models/detailed-data/ai-gate/AiGateSectionOne";
import { TableStyle } from "../GateMoreDetailed/GateSectionOne";

const chartOptions: ApexOptions = {
  chart: {
    type: "area",
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  },

  colors: ["#449496", "#352D59", "#6049CC"],
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
    colors: ["#449496", "#352D59", "#6049CC"],
    width: 1,
  },
  markers: {
    size: 2,
    strokeWidth: 0,
    colors: ["#449496", "#352D59", "#6049CC"],
  },
  xaxis: {
    categories: "datetime",
    labels: {
      formatter: (value) => {
        return moment(value).format("LT");
      },
      hideOverlappingLabels: true,
    },
  },

  yaxis: {},
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.7,
      opacityTo: 0.9,
      stops: [0, 100],
    },
  },
};

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "No",
    width: 90,
    align: "center",
    headerAlign: "center",
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
  },
  {
    field: "created_at",
    headerName: "날짜(시간)",
    width: 150,
    sortable: true,
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    flex: 3,
  },
  {
    field: "location",
    headerName: "설치장소",
    width: 150,
    sortable: false,
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    flex: 2,
  },
  {
    field: "camera_ip",
    headerName: "Camera IP",
    width: 110,
    sortable: false,
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    flex: 3,
  },
  {
    field: "count",
    headerName: "인구수 COUNT",
    sortable: false,
    width: 160,
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    flex: 2,
  },
];

const AiGateLineAndTable = observer(
  ({ options, selected, series, tableData, fetchSeries, setSelected }: AiGateSectionOneType) => {
    return (
      <Grid container flexDirection={"column"}>
        <Grid item container justifyContent={"space-between"}>
          <span className="title">전체 카메라 입출입 데이터 비교 분석</span>
          <CustomSelect
            selected={selected}
            list={options.slice()}
            onSelect={(e: number) => setSelected(e, fetchSeries)}
          />
        </Grid>
        <Grid item>
          <Grid item flex={1}>
            <ReactApexChart options={chartOptions} series={series.slice()} height={250} type={"area"} />
          </Grid>
        </Grid>
        <Grid item>
          <Grid item flex={1} height={"400px"}>
            <DataGrid
              rows={tableData.slice()}
              columns={columns}
              //   disableSelectionOnClick
              sx={TableStyle}
              hideFooter
              // onRowClick={(params: GridRowParams) => {
              //   console.log(params);
              // }}
              isRowSelectable={(params: GridRowParams) => {
                return true;
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
);

export default AiGateLineAndTable;
