import { useState } from "react";
import { RequiredMark } from "../RequiredMark";
import { useFormInput } from "../../../utils/InputUtils";
import { TextField, Grid, Container } from "@mui/material";

export const ValidatePassword = (props: any) => {
  const password1 = useFormInput("", { password: true });
  const password2 = useFormInput("", { password: true });
  const [validPwd] = useState<boolean | undefined>();
  const [validConfPwd] = useState<boolean | undefined>();

  return (
    <Container>
      <Grid container columnSpacing={1} marginTop="20px">
        <Grid item xs={12} md={2}>
          <span style={{ fontSize: "18px", fontWeight: "bold" }}>
            <RequiredMark /> 비밀번호
          </span>
        </Grid>
        <Grid item xs={8} md={4}>
          <TextField
            fullWidth
            size="small"
            type="password"
            className="signup-text-input"
            variant={"outlined"}
            {...password1}
            helperText={"*띄어쓰기 없는 영문, 숫자, 특수문자를 포함한 8~15자"}
            FormHelperTextProps={{ style: { fontSize: "10px", color: "black" } }}
          />
        </Grid>
        <Grid item xs={1} md={1}>
          {validPwd !== undefined ? <ValidateMark offset={{ x: 40, y: 20 }} valid={validPwd} /> : null}
        </Grid>
      </Grid>
      <Grid container columnSpacing={1} marginTop="20px">
        <Grid item xs={12} md={2}>
          <span style={{ fontSize: "18px", fontWeight: "bold" }}>
            <RequiredMark /> 비밀번호 확인
          </span>
        </Grid>
        <Grid item xs={8} md={4}>
          <TextField
            type="password"
            size="small"
            fullWidth
            className="signup-text-input"
            {...password2}
            variant={"outlined"}
          />
        </Grid>
        <Grid item xs={1} md={1}>
          {validConfPwd !== undefined ? <ValidateMark offset={{ x: 40, y: 20 }} valid={validConfPwd} /> : null}
        </Grid>
      </Grid>
    </Container>
  );
};

export const ValidateMark = (props: any) => {
  const style = {
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    marginTop: "15px",
    backgroundColor: props.valid ? "#00ff00" : "#ff0000",
    // position: "absolute",
    // right: "40px",
    // top: "20px",
    right: String(props.offset.x) + "px",
    top: String(props.offset.y) + "px",
  };

  return <div style={style}> </div>;
};
