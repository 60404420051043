import { Button } from "@mui/material";
import MssIcon from "../common/MssIcon";
import "./index.css";

export const KakaoLoginButton = (props: any) => {
  const onClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };
  return (
    <Button
      fullWidth
      className="kakao-btn social-btn"
      variant="contained"
      onClick={onClick}
      style={{ fontWeight: "bold" }}
      startIcon={<MssIcon name="kakao-talk" style={{ color: "#333", fontSize: "38px" }} className="social-icon" />}
      color="inherit"
    >
      <span className="social-label">카카오톡으로 시작하기</span>
    </Button>
  );
};

export const NaverLoginButton = (props: any) => {
  const onClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };
  return (
    <Button
      fullWidth
      className="naver-btn  social-btn"
      variant="contained"
      style={{ fontWeight: "bold" }}
      onClick={onClick}
      startIcon={<MssIcon name="naver" height={20} width={20} />}
    >
      <span className="social-label">네이버로 시작하기</span>
    </Button>
  );
};
