// Common
import CloseIcon from "@mui/icons-material/Close";
import AccountCircleOutlined from "@mui/icons-material/AccountCircleOutlined";
import Logout from "@mui/icons-material/Logout";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import LandscapeRoundedIcon from "@mui/icons-material/LandscapeRounded";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import CheckIcon from "@mui/icons-material/Check";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { SvgIconProps } from "@mui/material";

const icons = {
  close: CloseIcon,
  "account-circle-outlined": AccountCircleOutlined,
  logout: Logout,
  "add-circle": AddCircleIcon,
  landscape: LandscapeRoundedIcon,
  upload: FileUploadOutlinedIcon,
  check: CheckIcon,
  "visibile-on": VisibilityOutlinedIcon,
  "visibility-off": VisibilityOffOutlinedIcon,
  edit: EditIcon,
  delete: DeleteIcon,
};

export type IconKey = keyof typeof icons;

type Props = SvgIconProps & {
  name: IconKey;
};

const MuiIcon = (props: Props) => {
  const Icon = icons[props.name as IconKey];

  return <Icon {...props} />;
};

export default MuiIcon;
