import Grid from "@mui/material/Grid";
import { observer } from "mobx-react-lite";
import { Circle, Map, MapMarker, useMap } from "react-kakao-maps-sdk";
import { usePlaceStore } from "../../contexts/PlaceContext";
import { useSensorRegisterStore } from "../../contexts/SensorRegisterContext";

const MAP_CENTER = { lat: 33.5563, lng: 126.79581 };

const Markers = observer(
  ({
    markerArea,
    markerAreaRadius,
    lat,
    lng,
  }: {
    markerArea?: boolean;
    markerAreaRadius?: number;
    lat: number | undefined;
    lng: number | undefined;
  }) => {
    const { selected } = usePlaceStore();

    const map = useMap();
    return (
      <>
        {lat && lng && (
          <>
            <MapMarker
              image={{ src: "/marker.png", size: { width: 46, height: 46 } }}
              onCreate={() => {
                if (selected) {
                  map.setCenter(new kakao.maps.LatLng(lat, lng));
                }
              }}
              position={{ lat, lng }}
            />
            {markerArea && (
              <Circle
                center={{ lat, lng }}
                radius={markerAreaRadius ? markerAreaRadius : 20}
                strokeWeight={0}
                fillOpacity={0.3}
                fillColor={"#50CDD0"}
              />
            )}
          </>
        )}
      </>
    );
  }
);

type Props = {
  height?: string;
  width?: string;
  markerArea?: boolean;
  markerAreaRadius?: number;
  tip?: boolean;
};

const LocationMap = observer((props: Props) => {
  const { location } = useSensorRegisterStore();
  const { selectedPlace } = usePlaceStore();
  let lat;
  let lng;
  if (location.selected && props.tip) {
    lat = Number(location.selected.y);
    lng = Number(location.selected.x);
  } else if (selectedPlace) {
    lat = selectedPlace.latitude;
    lng = selectedPlace.longitude;
  }

  return (
    <>
      <Grid item>
        <Map
          center={lat && lng ? { lng, lat } : MAP_CENTER}
          style={{
            height: props.height ? props.height : "214px",
            width: props.width ? props.width : "505px",
            marginRight: "7px",
            borderRadius: "5px",
          }}
        >
          <Markers
            markerArea={props.markerArea}
            markerAreaRadius={props.markerAreaRadius}
            lat={lat ? lat : undefined}
            lng={lng ? lng : undefined}
          />
        </Map>
      </Grid>

      {props.tip && (
        <Grid
          item
          container
          flex={1}
          sx={{
            backgroundColor: "#F7F7FC",
            flexDirection: "column",
            padding: "20px 15px",
            fontSize: "11px",
            color: "#7D88B0",
            borderRadius: "5px",
          }}
        >
          <span style={{ fontSize: "16px", color: "#352D59", fontWeight: "bold" }}>TIP!</span>
          <span style={{ display: "flex" }}>
            · <span> 지도위 핀으로 정확한 위치를 선택하세요</span>
          </span>
          <span style={{ display: "flex" }}>
            ·
            <span>
              {" "}
              아래와 같이 정보를 입력 하시면 더욱 정확한 <br /> 결과로 분석됩니다.
            </span>
          </span>
          <span style={{ display: "flex" }}>
            ·
            <span>
              {" "}
              한 공간에 여러 센서를 설치할 때 같은 <br /> 이름으로 입력해 주세요
            </span>
          </span>
          <span style={{ marginTop: "10px", fontWeight: "bold" }}>[건물 정보]</span>
          <span>ex) 부산문화컨텐츠 컴플렉스 8층, 806호</span>
          <span style={{ marginTop: "10px", fontWeight: "bold" }}>[공간정보]</span>
          <span>ex) 큰회의실, 멀티미디어실</span>
        </Grid>
      )}
    </>
  );
});

export default LocationMap;
