import { observer } from "mobx-react-lite";
import { memo } from "react";
import { Map, MapMarker, useMap } from "react-kakao-maps-sdk";
import { usePlaceStore } from "../../contexts/PlaceContext";

const Markers = observer(() => {
  const { list, selectedPlace } = usePlaceStore();
  const map = useMap();
  selectedPlace && map.panTo(new kakao.maps.LatLng(selectedPlace.latitude, selectedPlace.longitude));
  return (
    <>
      {list.slice().map((place) => {
        if (!place.latitude && !place.longitude) return;
        return <MapMarker key={place.id} position={{ lat: place.latitude, lng: place.longitude }} />;
      })}
    </>
  );
});

const KakaoMap = memo(() => {
  return (
    <Map
      center={{
        lat: 33.5563,
        lng: 126.79581,
      }}
      isPanto={true}
      style={{
        height: "400px",
        borderRadius: "10px",
        border: "0.5px solid #8992CE",
      }}
    >
      <Markers />
    </Map>
  );
});

export default KakaoMap;
