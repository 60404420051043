import { Button, Grid, List, ListItem } from "@mui/material";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import { CSVLink } from "react-csv";
import { DoorSectionOneType } from "../../../../../models/detailed-data/door/DoorSectionOne";
import { MssDateRangePicker } from "../common/MssDatePicker";
import { TableStyle } from "../GateMoreDetailed/GateSectionOne";
import { columns, EMPTY_CHART, LineOptions } from "./DoorConsts";
import { observer } from "mobx-react-lite";
import { DataGrid, GridRowParams } from "@mui/x-data-grid";
import CountUp from "react-countup";
import { useEffect } from "react";

const DoorSectionOne = observer(({ sectionOne, sensorId }: { sectionOne: DoorSectionOneType; sensorId: string }) => {
  const { startDate, endDate, setStartDate, setEndDate, tableData, dashboard, chartData, init } = sectionOne;
  useEffect(() => {
    if (startDate && endDate) {
      init(sensorId);
    }
    if (!startDate && !endDate) {
      setStartDate(moment().format("YYYY-MM-DD"));
    }
  }, [startDate, endDate]);
  return (
    <Grid item container>
      <Grid container>
        <Grid item flex={1}>
          <span className="title">전체 문열림 감지 데이터 (최근 24시간)</span>
          <span className="sub-title">각 실내 공간별 설정 기간에 따른 온습도 변화 추이를 나타냅니다</span>
        </Grid>
        <Grid item display="flex" justifyContent={"flex-end"} alignItems="center">
          <MssDateRangePicker
            startDate={startDate}
            setStartDate={(v: string) => {
              setStartDate(v);
            }}
            endDate={endDate}
            setEndDate={(v: string) => {
              setEndDate(v);
            }}
          />
        </Grid>
      </Grid>
      <Grid container height={"126px"} columnGap={2} marginTop="34px">
        <Grid
          item
          sx={{
            backgroundColor: "#F7F7FC",
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            paddingX: "38px",
            flex: 1,
          }}
        >
          <span style={{ fontSize: "18px" }}>총 문열림 횟수</span>
          <span style={{ fontSize: "30px", fontWeight: "bold" }}>
            <CountUp end={dashboard.open_count} duration={0.5} /> 회
          </span>
        </Grid>
        <Grid
          item
          container
          sx={{
            backgroundColor: "#F7F7FC",
            alignItems: "center",
            paddingX: "38px",
            flex: 1,
          }}
        >
          <List sx={{ width: "100%" }}>
            <ListItem alignItems="center" sx={{ justifyContent: "space-between", color: "#333" }}>
              <span style={{ fontSize: "18px" }}>처음 문열림 시간</span>
              <span style={{ fontSize: "30px" }}>{moment(dashboard.first_open, "HH:mm:ss").format("HH:mm")}</span>
            </ListItem>
            <ListItem alignItems="center" sx={{ justifyContent: "space-between", color: "#333" }}>
              <span style={{ fontSize: "18px" }}>최근 문열림 시간</span>
              <span style={{ fontSize: "30px" }}>{moment(dashboard.recent_open, "HH:mm:ss").format("HH:mm")}</span>
            </ListItem>
          </List>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item flex={1}>
          <ReactApexChart
            options={LineOptions}
            series={chartData ? chartData : EMPTY_CHART}
            type="line"
            height={200}
            width={"100%"}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent={"flex-end"} marginTop={"20px"}>
        <CSVLink data={tableData ? tableData : []} filename={`Door-sensor-data-for-${startDate}`}>
          <Button sx={{ color: "#333", borderRadius: "30px", border: "1px solid #333", minWidth: "125px" }}>
            데이터 추출하기
          </Button>
        </CSVLink>
      </Grid>
      <Grid container marginTop={"20px"}>
        <Grid item flex={1} height={"300px"}>
          <DataGrid
            rows={tableData ? tableData : []}
            columns={columns}
            //   disableSelectionOnClick
            sx={TableStyle}
            hideFooter
            // onRowClick={(params: GridRowParams) => {
            //   console.log(params);
            // }}
            isRowSelectable={(params: GridRowParams) => {
              return true;
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
});

export default DoorSectionOne;
