import { createContext, ReactNode, useContext } from "react";
import DetailedDataModel, { DetailedDataModelType } from "../models/detailed-data/DetailedDataModel";

const DetailedDataContext = createContext<DetailedDataModelType>({} as DetailedDataModelType);

const detailedDataStore = DetailedDataModel.create();

type Props = {
  children: ReactNode;
};

export const DetailedDataStoreProvider = ({ children }: Props) => {
  return <DetailedDataContext.Provider value={detailedDataStore}>{children}</DetailedDataContext.Provider>;
};
export const useDetailedDataStore = () => useContext(DetailedDataContext);
