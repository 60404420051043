import { Dialog, DialogContent, Grid } from "@mui/material";
import { StyleSheet } from "../dashboard/sensor/overview/SpotDataInDetail";
import {Unity, useUnityContext} from "react-unity-webgl";
import { MdClose } from "react-icons/md";

type Props = {
    open: boolean;
    onClose: (confirm: boolean) => void;
};

const Unity3DBuildingViewModal = ({ open, onClose }: Props) => {

    const { unityProvider, unload } = useUnityContext({
        loaderUrl: "https://static.mss.zeroweb.cloud/unity/build/mss.loader.js",
        dataUrl: "https://static.mss.zeroweb.cloud/unity/build/mss.data",
        frameworkUrl: "https://static.mss.zeroweb.cloud/unity/build/mss.framework.js",
        codeUrl: "https://static.mss.zeroweb.cloud/unity/build/mss.wasm",
    });
    return (
        <Dialog
            open={open}
            onClose={() => {
                onClose(false);
            }}
            maxWidth="md"
            fullWidth
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "90%",  // Set your width here
                        height: "100%"
                    },
                },
            }}
        >
            <DialogContent>
                <Grid container justifyContent={"space-between"} mt="30px">
                    <div style={{width: 100}}></div>
                    <span style={{ color: "#333", fontWeight: "bold", fontSize: "20px" }}>3D Building View</span>
                    <div style={{width: 30}}>
                        <MdClose size={30} style={{cursor: "pointer"}} onClick={() => {
                            onClose(false);
                            unload();
                        }}/>
                    </div>
                </Grid>
                <Grid container marginTop={"10px"}>
                    <Unity unityProvider={unityProvider} style={{width: "100%", height: "100vh"}}/>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

const styles = StyleSheet.create({
    title: {
        fontSize: "16px",
        // fontWeight: "bold",
        color: "#4b4b4b",
        marginBottom: "2px",
        minWidth: "150px",
    },
    input: {
        height: "35px",
        borderRadius: "5px",
        outline: "none",
        backgroundColor: "#fafaff",
        padding: "0 20px",
        minWidth: "200px",
        fontSize: "14px",
        display: "flex",
        alignItems: "center",
        color: "#848484",
        border: "1px solid #abaaba",
        marginTop: "1px",
    },
    subTitle: {
        height: "35px",
        borderRadius: "5px",
        outline: "none",
        backgroundColor: "#fafaff",
        padding: "0 20px",
        minWidth: "200px",
        fontSize: "14px",
        display: "flex",
        alignItems: "center",
        color: "#848484",
        // border: "1px solid #abaaba",
        marginTop: "1px",
    },
});

export default Unity3DBuildingViewModal;
