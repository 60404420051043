import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import { Instance, types } from "mobx-state-tree";
import api from "../api/api";
import { StorageUtils } from "../utils/StorageUtils";

const UserModel = types.model({
  uuid: types.optional(types.string, ""),
  id: types.optional(types.number, 0),
  created_at: types.optional(types.number, 0),
  updated_at: types.optional(types.number, 0),
  deleted_at: types.maybeNull(types.number),
  type: types.optional(types.string, ""),
  username: types.optional(types.string, ""),
  should_change_password: types.optional(types.boolean, false),
  push_notification_agrement: types.optional(types.boolean, true),
  iat: types.optional(types.number, 0),
  nbf: types.optional(types.number, 0),
  exp: types.optional(types.number, 0),
});

const TokenModel = types.model({
  access: types.optional(types.string, ""),
  refresh: types.optional(types.string, ""),
});

const AuthenticationModel = types
  .model({
    user: types.optional(UserModel, {} as UserType),
    token: types.optional(types.string, ""),
  })
  .actions((self) => ({
    setUser(user: UserType) {
      self.user = user;
    },
    setToken(token: string) {
      self.token = token;
    },
    init() {
      const token = StorageUtils.getAuthorization();
      if (!token) return;
      self.token = token;
      self.user = jwt_decode(token) as UserType;
      api.setHeader("Authorization", token);
    },
  }))
  .actions((self) => ({
    signOut: (callback: VoidFunction) => {
      self.token = "";
      StorageUtils.setAuthorization("", false);
      api.deleteHeader("Authorization");
      callback();
    },
    signIn: async (username: string, password: string, finishPending: VoidFunction, callback: VoidFunction) => {
      if (!username) {
        finishPending();
        return toast.error("아이디를 입력해주세요");
      }
      if (!password) {
        finishPending();
        return toast.error("비밀번호를 입력해주세요");
      }

      const res = await api.post<{ code: string; token: string }>("/v1/auth/sign-in", {
        username: username,
        password: password,
      });
      if (!res.ok) {
        finishPending();
        return toast.error("아이디와 비밀번호가 일치하지 않습니다");
      }
      if (!res.data) return;
      const token = res.data.token;
      const user = jwt_decode(token) as UserType;

      self.setToken(res.data.token);
      self.setUser(user);
      api.setHeader("Authorization", token);
      StorageUtils.setAuthorization(token, true);
      toast.success("로그인되었습니다");
      setTimeout(() => {
        callback();
      }, 1000);
    },
  }));

export type UserType = Instance<typeof UserModel>;
export type TokenType = Instance<typeof TokenModel>;
export type AuthenticationType = Instance<typeof AuthenticationModel>;

export default AuthenticationModel;
