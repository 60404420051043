import { createContext, ReactNode, useContext } from "react";
import SensorHubModel from "../models/sensor-register/SensorHubModel";
import SensorLocationModel from "../models/sensor-register/SensorLocationModel";
import SensorRegisterModel, { SensorRegisterModelType } from "../models/sensor-register/SensorRegisterModel";
import { SENSORS_LIST } from "./dummies";

export const STEPS = [
  {
    type: "SPOT",
    steps: [
      {
        label: "센서 정보 입력",
      },
      {
        label: "센서 위치 확인",
      },
      {
        label: "사용 목적, 분석 반경 선택",
      },
      {
        label: "센서 세부 확인",
      },
      {
        label: "완료",
      },
    ],
  },
  {
    type: "GATE",
    steps: [
      {
        label: "센서 정보 입력",
      },
      {
        label: "센서 위치 확인",
      },
      {
        label: "센서 세부 확인",
      },
      {
        label: "완료",
      },
    ],
  },
  {
    type: "GATE_3D",
    steps: [
      {
        label: "센서 정보 입력",
      },
      {
        label: "센서 위치 확인",
      },
      {
        label: "센서 세부 확인",
      },
      {
        label: "완료",
      },
    ],
  },
  {
    type: "HUB",
    steps: [
      {
        label: "센서 정보 입력",
      },
      {
        label: "센서 HUB 연결",
      },
      {
        label: "센서 위치 확인",
      },
      {
        label: "센서 세부 확인",
      },
      {
        label: "완료",
      },
    ],
  },
];

const SensorRegisterContext = createContext<SensorRegisterModelType>({} as SensorRegisterModelType);

const sensorRegisterStore = SensorRegisterModel.create({
  stepper: { data: STEPS },
  sensor: { list: SENSORS_LIST },
  location: SensorLocationModel.create(),
  hub: SensorHubModel.create(),
});

type Props = {
  children: ReactNode;
};

export const SensorRegisterStoreProvider = ({ children }: Props) => {
  return <SensorRegisterContext.Provider value={sensorRegisterStore}>{children}</SensorRegisterContext.Provider>;
};
export const useSensorRegisterStore = () => useContext(SensorRegisterContext);
