import Grid from "@mui/material/Grid";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { StyleSheet } from "./SpotDataInDetail";
import { memo, useEffect, useState } from "react";
import { DoorOverview } from "../../../../../types/sensor";
import api from "../../../../api/api";
import moment from "moment";
import "moment/locale/ko";
import CountUp from "react-countup";

const styles = StyleSheet.create({
  box: {
    alignItems: "center",
    height: "28px",
    justifyContent: "space-between",
    marginTop: "10px",
  },
  key: {
    color: "#565656",
    fontSize: "12px",
  },
  value: {
    color: "#333333",
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "end",
    lineHeight: 0.7,
  },
  subValue: {
    color: "#ABAABA",
    fontSize: "10px",
    fontWeight: "normal",
  },
});

const chartOptions: ApexOptions = {
  chart: {
    id: "basic-bar",
    offsetX: 0,
    parentHeightOffset: 0,
    toolbar: {
      show: false,
    },
  },
  colors: ["#1C1344"],
  grid: {
    borderColor: "#F9F9F9",
  },
  stroke: {
    width: 2,
    curve: "straight",
    colors: ["#1C1344"],
  },
  markers: {
    size: 0,
    strokeColors: ["#50CDD0"],
    hover: {
      size: 2,
    },
  },
  xaxis: {
    labels: {
      rotate: 0,
      hideOverlappingLabels: true,
      offsetY: -5,
      maxHeight: 20,
      style: {
        fontSize: "5px",
        colors: "#000000",
      },
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      style: {
        fontSize: "5px",
        colors: "#9F9FB4",
      },
    },
  },
  legend: {
    show: false,
  },
  annotations: {
    yaxis: [
      {
        y: 40,
        y2: 41,
        fillColor: "#50CDD0",
        opacity: 1,
        borderColor: "#50CDD0",
      },
    ],
  },
};

export type SensorOverviewProps = {
  sensorId: string;
  setRssi?: Function;
  setBattery?: Function;
};

const InAndOutDataInDetail = memo(({ sensorId, setRssi, setBattery }: SensorOverviewProps) => {
  const [data, setData] = useState<DoorOverview | undefined>();
  const [lineData, setLineData] = useState<Array<any> | undefined>();

  const fetchData = async () => {
    const res = await api.get<DoorOverview>(`/v1/sensor/door/${sensorId}/overview`);
    if (!res.ok || !res.data) return;
    setRssi && setRssi(res.data.rssi);
    setBattery && setBattery(res.data.battery);
    setData(res.data);
  };
  const fetchLine = async () => {
    const res = await api.get<{ chart: Array<any> }>(`/v1/sensor/door/${sensorId}/table`, {
      start_date: moment().format("YYYY-MM-DD"),
    });
    if (!res.ok || !res.data) return;
    setLineData(res.data.chart);
  };

  useEffect(() => {
    fetchData();
    fetchLine();
  }, []);

  return (
    <Grid container marginTop={"7px"}>
      <Grid item container flex={1} flexDirection="column">
        <Grid item container style={styles.box}>
          <span style={styles.key}>가장 최근 문 열림</span>
          <span style={styles.value}>{moment(data?.recent_door_open).format("LT")}</span>
        </Grid>
        <Grid item container style={styles.box}>
          <span style={styles.key}>오늘 최초 문 열림</span>
          <span style={styles.value}>{moment(data?.first_door_open).format("LT")}</span>
        </Grid>
        <Grid item container style={styles.box}>
          <span style={styles.key}>오늘 문열린 횟수 </span>
          <span style={styles.value}>
            <CountUp end={data ? data.today_door_open_count : 0} duration={1} />회 <br />
          </span>
        </Grid>
      </Grid>
      <Grid item container flex={1} paddingLeft="45px">
        <Grid item container style={styles.box}>
          <span style={styles.key}>주간 평균 최초 열림</span>
          <span style={styles.value}>{moment(data?.weekly_avg_door_open_time).format("LT")}</span>
        </Grid>
        <Grid item container style={styles.box}>
          <span style={styles.key}>월간 평균 최초 열림</span>
          <span style={styles.value}>{moment(data?.monthly_avg_door_open_time).format("LT")}</span>
        </Grid>
        <Grid item container style={styles.box}>
          <span style={styles.key}>월간 일일 최대 문 개폐</span>
          <span style={styles.value}>
            <CountUp end={data ? data.monthly_most_door_open_cnt : 0} duration={1} />회 <br />
          </span>
        </Grid>
      </Grid>
      <Grid item container width={"318px"} paddingLeft="25px">
        <ReactApexChart
          options={chartOptions}
          series={lineData ? [{ data: lineData, name: "문열림 횟수" }] : []}
          type="line"
          height={"120px"}
        />
      </Grid>
    </Grid>
  );
});

export default InAndOutDataInDetail;
