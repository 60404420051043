import { Instance, types } from "mobx-state-tree";
import api from "../../../api/api";

const SensorSummaryModel = types.model({
  latest_detected_time: types.optional(types.string, "최근 기록 없음"),
  detected_time: types.optional(types.number, 0),
});

const SummaryModel = types.model({
  data: types.frozen<Array<any>>(),
  sensorSummary: types.optional(SensorSummaryModel, {} as SensorSummaryModelType),
});

const EmergencySectionOne = types
  .model({
    startDate: types.maybeNull(types.string),
    endDate: types.maybeNull(types.string),
    summary: types.optional(SummaryModel, {} as SummaryModelType),
    tableData: types.frozen<Array<any>>(),
  })
  .views((self) => ({
    get pieData() {
      const noData = {
        labels: [],
        series: [],
      };
      if (!self.summary) return noData;
      const normal = self.summary.data?.find((obj) => obj.id === "정상");
      const abnormal = self.summary.data?.find((obj) => obj.id === "비정상");
      if (!normal || !abnormal) {
        return noData;
      }

      return {
        labels: [normal.id, abnormal.id],
        series: [normal.value, abnormal.value],
      };
    },
    get dateRange() {
      return {
        start_date: self.startDate,
        end_date: self.endDate,
      };
    },
  }))
  .actions((self) => ({
    setStartDate(startDate: typeof self.startDate) {
      self.startDate = startDate;
    },
    setEndDate(endDate: typeof self.endDate) {
      self.endDate = endDate;
    },
    setSummary(summary: SummaryModelType) {
      self.summary = summary;
    },
    setTableData(tableData: typeof self.tableData) {
      self.tableData = tableData;
    },
  }))
  .actions((self) => ({
    fetchSummary: async (sensorId: string) => {
      const res = await api.get<SummaryModelType>(`/v1/sensor/sos/${sensorId}/summary`, self.dateRange);

      if (!res.ok || !res.data) return;
      self.setSummary(res.data);
    },
    fetchTableData: async (sensorId: string) => {
      const res = await api.get<{ data: any }>(`/v1/sensor/sos/${sensorId}`, self.dateRange);

      if (!res.ok || !res.data) return;
      self.setTableData(res.data.data);
    },
    init(sensorId: string) {
      this.fetchSummary(sensorId);
      this.fetchTableData(sensorId);
    },
  }));

export type SummaryModelType = Instance<typeof SummaryModel>;
export type SensorSummaryModelType = Instance<typeof SensorSummaryModel>;

export type EmergencySectionOneType = Instance<typeof EmergencySectionOne>;
export default EmergencySectionOne;
