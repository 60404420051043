import { GridColDef } from "@mui/x-data-grid";
import { ApexOptions } from "apexcharts";
import moment from "moment";

export const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "No",
    align: "center",
    headerAlign: "center",
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
  },
  {
    field: "created_at",
    headerName: "날짜(시간)",
    sortable: true,
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    flex: 2,
  },
  {
    field: "status",
    headerName: "상태",
    sortable: false,
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    flex: 1,
  },
  {
    field: "value",
    headerName: "화재감지여부",
    sortable: false,
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    flex: 2,
  },

  {
    field: "end_time",
    headerName: "화재종료",
    sortable: false,
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    flex: 1,
  },
];

export const DOOR_TABLE_DATA = [
  {
    id: 2,
    created_at: "2022-04-05 12:00:00",
    state: "화재감지",
    place: "큰회의실",
    termination: "2021-04-20 09:07",
  },
  {
    id: 3,
    state: "정상",
    created_at: "2022-04-05 12:00:00",
    place: "큰회의실",
    termination: "2021-04-20 09:07",
  },
  {
    id: 4,
    created_at: "2022-04-05 12:00:00",
    state: "비정상",
    place: "큰회의실",
    termination: "",
  },
  {
    id: 5,
    created_at: "2022-04-05 12:00:00",
    state: "정상",
    place: "큰회의실",
    termination: "",
  },
  {
    id: 6,
    created_at: "2022-04-05 12:00:00",
    state: "정상",
    place: "큰회의실",
    termination: "",
  },
  {
    id: 7,
    created_at: "2022-04-05 12:00:00",
    state: "정상",
    place: "큰회의실",
    termination: "",
  },
];

export const DOOR_SECTION_TWO_COLUMN_OPTIONS: ApexOptions = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "55%",
    },
  },
  dataLabels: {
    enabled: false,
  },
  colors: ["#352D59", "#6AE1E3", "#6852CF", "#5B8FF9"],
  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"],
  },

  fill: {
    opacity: 1,
  },
  tooltip: {
    y: {
      formatter: function (val) {
        return "$ " + val + " thousands";
      },
    },
  },
};

export const HEATMAP_CHART_OPTIONS: ApexOptions = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    heatmap: {
      shadeIntensity: 1,
      radius: 3,
      useFillColorAsStroke: false,
      colorScale: {
        ranges: [
          {
            from: 0,
            to: 25,
            name: "low",
            color: "#1B84A5",
          },
          {
            from: 26,
            to: 55,
            name: "extreme",
            color: "#352D59",
          },
        ],
      },
    },
  },
  legend: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: 1,
  },
};

export const COLUMN_OPTIONS: ApexOptions = {
  series: [
    {
      name: "Inflation",
      data: [
        2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2, 2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8,
        0.5, 0.2,
      ],
    },
  ],
  chart: {
    height: 350,
    type: "bar",
  },
  dataLabels: { enabled: false },

  xaxis: {
    categories: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],

    position: "bottom",
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    labels: { hideOverlappingLabels: true, rotateAlways: false, rotate: 0 },
    crosshairs: {
      fill: {
        type: "gradient",
        gradient: {
          colorFrom: "#D8E3F0",
          colorTo: "#BED1E6",
          stops: [0, 100],
          opacityFrom: 0.4,
          opacityTo: 0.5,
        },
      },
    },
    tooltip: {
      enabled: true,
    },
  },
  yaxis: {
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    labels: {
      show: false,
      formatter: function (val) {
        return val + "%";
      },
    },
  },
};

export const BAR_KEYS = ["A", "B"];

export const BAR_DATA = [
  {
    country: "AD",
    A: 300,
    B: -200,
  },
  {
    country: "AE",
    A: 250,
    B: -118,
  },
  {
    country: "AF",
    A: 200,
    B: -50,
  },
  {
    country: "AG",
    A: 140,
    B: -185,
  },
  {
    country: "AI",
    A: 165,
    B: -109,
  },
  {
    country: "AL",
    A: 210,
    B: -290,
  },
  {
    country: "AD1",
    A: 300,
    B: -200,
  },
  {
    country: "AE1",
    A: 250,
    B: -118,
  },
  {
    country: "AF1",
    A: 200,
    B: -50,
  },
  {
    country: "AG1",
    A: 140,
    B: -185,
  },
  {
    country: "AI1",
    A: 165,
    B: -109,
  },
  {
    country: "AL1",
    A: 210,
    B: -290,
  },
  {
    country: "AD2",
    A: 300,
    B: -200,
  },
  {
    country: "AE2",
    A: 250,
    B: -118,
  },
  {
    country: "AF2",
    A: 200,
    B: -50,
  },
  {
    country: "AG2",
    A: 140,
    B: -185,
  },
  {
    country: "AI2",
    A: 165,
    B: -109,
  },
  {
    country: "AL2",
    A: 210,
    B: -290,
  },
];

export const CANDLESTICK_CHART_OPTIONS: ApexOptions = {
  series: [
    {
      name: "candle",
      data: [
        {
          x: new Date(1538778600000),
          y: [6629.81, 6650.5, 6623.04, 6633.33],
        },
        {
          x: new Date(1538780400000),
          y: [6632.01, 6643.59, 6620, 6630.11],
        },
        {
          x: new Date(1538782200000),
          y: [6630.71, 6648.95, 6623.34, 6635.65],
        },
        {
          x: new Date(1538784000000),
          y: [6635.65, 6651, 6629.67, 6638.24],
        },
        {
          x: new Date(1538785800000),
          y: [6638.24, 6640, 6620, 6624.47],
        },
        {
          x: new Date(1538787600000),
          y: [6624.53, 6636.03, 6621.68, 6624.31],
        },
        {
          x: new Date(1538789400000),
          y: [6624.61, 6632.2, 6617, 6626.02],
        },
        {
          x: new Date(1538791200000),
          y: [6627, 6627.62, 6584.22, 6603.02],
        },
        {
          x: new Date(1538793000000),
          y: [6605, 6608.03, 6598.95, 6604.01],
        },
        {
          x: new Date(1538794800000),
          y: [6604.5, 6614.4, 6602.26, 6608.02],
        },
        {
          x: new Date(1538796600000),
          y: [6608.02, 6610.68, 6601.99, 6608.91],
        },
        {
          x: new Date(1538798400000),
          y: [6608.91, 6618.99, 6608.01, 6612],
        },
        {
          x: new Date(1538800200000),
          y: [6612, 6615.13, 6605.09, 6612],
        },
        {
          x: new Date(1538802000000),
          y: [6612, 6624.12, 6608.43, 6622.95],
        },
        {
          x: new Date(1538803800000),
          y: [6623.91, 6623.91, 6615, 6615.67],
        },
        {
          x: new Date(1538805600000),
          y: [6618.69, 6618.74, 6610, 6610.4],
        },
        {
          x: new Date(1538807400000),
          y: [6611, 6622.78, 6610.4, 6614.9],
        },
        {
          x: new Date(1538809200000),
          y: [6614.9, 6626.2, 6613.33, 6623.45],
        },
        {
          x: new Date(1538811000000),
          y: [6623.48, 6627, 6618.38, 6620.35],
        },
        {
          x: new Date(1538812800000),
          y: [6619.43, 6620.35, 6610.05, 6615.53],
        },
        {
          x: new Date(1538814600000),
          y: [6615.53, 6617.93, 6610, 6615.19],
        },
        {
          x: new Date(1538816400000),
          y: [6615.19, 6621.6, 6608.2, 6620],
        },
        {
          x: new Date(1538818200000),
          y: [6619.54, 6625.17, 6614.15, 6620],
        },
        {
          x: new Date(1538820000000),
          y: [6620.33, 6634.15, 6617.24, 6624.61],
        },
        {
          x: new Date(1538821800000),
          y: [6625.95, 6626, 6611.66, 6617.58],
        },
        {
          x: new Date(1538823600000),
          y: [6619, 6625.97, 6595.27, 6598.86],
        },
        {
          x: new Date(1538825400000),
          y: [6598.86, 6598.88, 6570, 6587.16],
        },
        {
          x: new Date(1538827200000),
          y: [6588.86, 6600, 6580, 6593.4],
        },
        {
          x: new Date(1538829000000),
          y: [6593.99, 6598.89, 6585, 6587.81],
        },
        {
          x: new Date(1538830800000),
          y: [6587.81, 6592.73, 6567.14, 6578],
        },
        {
          x: new Date(1538832600000),
          y: [6578.35, 6581.72, 6567.39, 6579],
        },
        {
          x: new Date(1538834400000),
          y: [6579.38, 6580.92, 6566.77, 6575.96],
        },
        {
          x: new Date(1538836200000),
          y: [6575.96, 6589, 6571.77, 6588.92],
        },
        {
          x: new Date(1538838000000),
          y: [6588.92, 6594, 6577.55, 6589.22],
        },
        {
          x: new Date(1538839800000),
          y: [6589.3, 6598.89, 6589.1, 6596.08],
        },
        {
          x: new Date(1538841600000),
          y: [6597.5, 6600, 6588.39, 6596.25],
        },
        {
          x: new Date(1538843400000),
          y: [6598.03, 6600, 6588.73, 6595.97],
        },
        {
          x: new Date(1538845200000),
          y: [6595.97, 6602.01, 6588.17, 6602],
        },
        {
          x: new Date(1538847000000),
          y: [6602, 6607, 6596.51, 6599.95],
        },
        {
          x: new Date(1538848800000),
          y: [6600.63, 6601.21, 6590.39, 6591.02],
        },
        {
          x: new Date(1538850600000),
          y: [6591.02, 6603.08, 6591, 6591],
        },
        {
          x: new Date(1538852400000),
          y: [6591, 6601.32, 6585, 6592],
        },
        {
          x: new Date(1538854200000),
          y: [6593.13, 6596.01, 6590, 6593.34],
        },
        {
          x: new Date(1538856000000),
          y: [6593.34, 6604.76, 6582.63, 6593.86],
        },
        {
          x: new Date(1538857800000),
          y: [6593.86, 6604.28, 6586.57, 6600.01],
        },
        {
          x: new Date(1538859600000),
          y: [6601.81, 6603.21, 6592.78, 6596.25],
        },
        {
          x: new Date(1538861400000),
          y: [6596.25, 6604.2, 6590, 6602.99],
        },
        {
          x: new Date(1538863200000),
          y: [6602.99, 6606, 6584.99, 6587.81],
        },
        {
          x: new Date(1538865000000),
          y: [6587.81, 6595, 6583.27, 6591.96],
        },
        {
          x: new Date(1538866800000),
          y: [6591.97, 6596.07, 6585, 6588.39],
        },
        {
          x: new Date(1538868600000),
          y: [6587.6, 6598.21, 6587.6, 6594.27],
        },
        {
          x: new Date(1538870400000),
          y: [6596.44, 6601, 6590, 6596.55],
        },
        {
          x: new Date(1538872200000),
          y: [6598.91, 6605, 6596.61, 6600.02],
        },
        {
          x: new Date(1538874000000),
          y: [6600.55, 6605, 6589.14, 6593.01],
        },
        {
          x: new Date(1538875800000),
          y: [6593.15, 6605, 6592, 6603.06],
        },
        {
          x: new Date(1538877600000),
          y: [6603.07, 6604.5, 6599.09, 6603.89],
        },
        {
          x: new Date(1538879400000),
          y: [6604.44, 6604.44, 6600, 6603.5],
        },
        {
          x: new Date(1538881200000),
          y: [6603.5, 6603.99, 6597.5, 6603.86],
        },
        {
          x: new Date(1538883000000),
          y: [6603.85, 6605, 6600, 6604.07],
        },
        {
          x: new Date(1538884800000),
          y: [6604.98, 6606, 6604.07, 6606],
        },
      ],
    },
  ],

  chart: {
    toolbar: {
      show: false,
    },
  },

  plotOptions: {
    candlestick: {
      colors: {
        upward: "#6AE1E3",
        downward: "#705BD2",
      },
      wick: {
        useFillColor: true,
      },
    },
  },
  colors: ["#705BD2", "#705BD2"],
  tooltip: {
    enabled: true,
  },
  xaxis: {
    type: "category",
    labels: {
      formatter: function (val) {
        return moment(val).format("HH:mm");
      },
      hideOverlappingLabels: true,
      rotate: 0,
      style: {
        colors: "#A3ABBD",
        fontSize: "12px",
        fontWeight: "medium",
      },
    },

    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    tickAmount: 10,
  },
  yaxis: {
    labels: {
      show: false,
    },
  },
  grid: {
    show: false,
  },
};

export const EMERGENCY_SECTION_FOUR_COLUMN_OPTIONS: ApexOptions = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "20%",
      borderRadius: 5,
    },
  },
  dataLabels: {
    enabled: false,
  },
  colors: ["#7F5AF0", "#6AE1E3", "#6852CF", "#5B8FF9"],
  stroke: {
    show: false,
  },
  xaxis: {
    labels: {
      style: {
        colors: "#A6ACBE",
        fontSize: "10px",
      },
      rotate: 0,
      hideOverlappingLabels: true,
      formatter: (val) => moment(val).format("Do"),
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    tickAmount: 10,
  },

  yaxis: {
    labels: {
      style: {
        colors: "#A6ACBE",
        fontSize: "10px",
      },
    },
  },

  fill: {
    opacity: 1,
  },
};
