import { Button, Collapse, Dialog, DialogContent, Grid, IconButton, Tooltip, Zoom } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useDetailedDataStore } from "../../../contexts/DetailedDataContext";
import { usePlaceStore } from "../../../contexts/PlaceContext";
import { getSensorInfo } from "../../../utils/SensorUtils";
import MssIcon, { IconKey } from "../../common/MssIcon";
import { useUserSensorListStore } from "../../../contexts/UserSensorContext";
import { UserSensorModelType } from "../../../models/main/UserSensorsModel";
import EmptyList from "../../common/EmptyList";
import MuiIcon from "../../common/MuiIcon";
import { PlaceListType } from "../../../models/main/PlaceModel";

type SensorProps = {
  sensor: UserSensorModelType;
  selected: boolean;
  onSelect: Function;
  place: PlaceListType | undefined;
};

const SensorItem = ({ sensor, selected, onSelect, place }: SensorProps) => {
  const sensorInfo = getSensorInfo(sensor.sensor_id, sensor.type);
  return (
    <Grid item md={12} key={sensor.uuid}>
      <Button
        fullWidth
        disableRipple
        sx={{
          height: "99px",
          outline: selected ? "2px solid #6049CC" : "1px solid #ddd",
          borderRadius: "8px",
          backgroundColor: "#fafaff",
          marginTop: "10px",
          justifyContent: "flex-start",
          color: "#333",
        }}
        onClick={() => onSelect(sensor.uuid)}
      >
        <MssIcon name={sensor.type as IconKey} height={70} width={115} />

        <Grid item sx={{ display: "flex", flexDirection: "column", flex: 1, alignItems: "flex-start" }}>
          <span style={{ fontWeight: "bold", fontSize: "20px", color: "#352D59" }}>
            {sensorInfo.en} <span style={{ fontSize: "14px", color: "#333" }}>{sensorInfo.kr}</span>
          </span>
          <span style={{ color: "#888", fontSize: "12px" }}>{sensor.sensor_id} </span>
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 2,
            alignItems: "flex-start",
            borderLeft: "1px solid #e8e8e8",
            paddingLeft: "25px",
          }}
        >
          <span style={{ fontWeight: "bold", fontSize: "20px" }}>{place?.name}</span>
          <span style={{ color: "#888", fontSize: "12px" }}>
            {place?.address}, {place?.address_detail}{" "}
          </span>
        </Grid>
        <Grid item width={"70px"}>
          {selected && <MuiIcon name="check" sx={{ color: "#6049CC", fontSize: "34px", marginRight: "34px" }} />}
        </Grid>
      </Button>
    </Grid>
  );
};

type Props = {
  open: boolean;
  onClose: () => void;
};

const SensorSelectModal = observer(({ open, onClose }: Props) => {
  const { list, listGroupedByPlace, listGroupedBySensorType, init, grouped, groupType, setGrouped, setGroupType } =
    useUserSensorListStore();
  const { getPlace } = usePlaceStore();
  const { setSelectedSlave, setShowSlave } = useDetailedDataStore();
  const [selected, setSelected] = useState("");
  const [searchValue, setSearch] = useState("");

  const onConfirm = () => {
    setSelectedSlave(selected);
    setShowSlave(true);
    onClose();
  };

  const renderSensorList = () => {
    switch (groupType) {
      case "PLACE":
        return listGroupedByPlace.length > 0 ? (
          listGroupedByPlace.map((group) => {
            const place = getPlace(group.type);
            const sensors = group.sensors.filter(
              (sensor) =>
                sensor.sensor_id.toLowerCase().includes(searchValue.toLowerCase()) ||
                place?.name.toLowerCase().includes(searchValue.toLowerCase())
            );
            return (
              sensors.length > 0 && (
                <Grid container sx={{ marginBottom: "40px" }} key={group.type}>
                  <span style={{ fontWeight: "bold", fontSize: "20px", color: "#352D59" }}>{place?.name}</span>
                  <Grid container>
                    {group.sensors
                      .filter(
                        (sensor) =>
                          sensor.sensor_id.toLowerCase().includes(searchValue.toLowerCase()) ||
                          place?.name.toLowerCase().includes(searchValue.toLowerCase())
                      )
                      .map((sensor) => (
                        <SensorItem
                          key={sensor.uuid}
                          sensor={sensor}
                          selected={sensor.sensor_id === selected ? true : false}
                          place={getPlace(sensor.place_uuid)}
                          onSelect={() => setSelected(sensor.sensor_id)}
                        />
                      ))}
                  </Grid>
                </Grid>
              )
            );
          })
        ) : (
          <EmptyList message="There are no sensor" />
        );

      case "SENSOR_TYPE":
        return listGroupedBySensorType.length > 0 ? (
          listGroupedBySensorType.map((group) => {
            const sensorInfo = getSensorInfo("", group.type);
            const sensors = group.sensors.filter(
              (sensor) =>
                sensor.sensor_id.toLowerCase().includes(searchValue.toLowerCase()) ||
                sensorInfo.kr.toLowerCase().includes(searchValue.toLowerCase()) ||
                sensorInfo.en.toLowerCase().includes(searchValue.toLowerCase()) ||
                getPlace(sensor.place_uuid)?.name.toLowerCase().includes(searchValue.toLowerCase())
            );

            return (
              sensors.length > 0 && (
                <Grid container sx={{ marginBottom: "40px" }}>
                  <span style={{ fontWeight: "bold", fontSize: "20px", color: "#352D59" }}>{sensorInfo.kr}</span>
                  <Grid container>
                    {sensors.map((sensor) => (
                      <SensorItem
                        key={sensor.uuid}
                        sensor={sensor}
                        selected={sensor.sensor_id === selected ? true : false}
                        place={getPlace(sensor.place_uuid)}
                        onSelect={() => setSelected(sensor.sensor_id)}
                      />
                    ))}
                  </Grid>
                </Grid>
              )
            );
          })
        ) : (
          <EmptyList message="There are no sensor" />
        );

      default:
        return list
          .filter((sensor) => sensor.sensor_id.toLowerCase().includes(searchValue.toLowerCase()))
          .map((sensorItem) => (
            <SensorItem
              key={sensorItem.uuid}
              sensor={sensorItem}
              selected={sensorItem.sensor_id === selected ? true : false}
              place={getPlace(sensorItem.place_uuid)}
              onSelect={() => setSelected(sensorItem.sensor_id)}
            />
          ));
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogContent>
        <Grid container minWidth={"830px"} minHeight="700px" padding="62px 48px">
          <Grid item container justifyContent={"center"}>
            <span style={{ fontWeight: "bold", fontSize: "26px" }}>비교하고자 하는 센서를 선택해 주세요</span>
          </Grid>

          <Grid item container justifyContent={"flex-end"} marginTop="20px" height={"40px"} paddingX="20px">
            <Tooltip
              title={grouped ? "센서별 그룹 해제" : " 센서별 그룹"}
              placement="top"
              TransitionComponent={Zoom}
              arrow
            >
              <IconButton
                onClick={() => {
                  grouped ? setGroupType("") : setGroupType("PLACE");
                  setGrouped(!grouped);
                }}
              >
                {grouped ? <MssIcon name="ungroup-by" /> : <MssIcon name="group-by" />}
              </IconButton>
            </Tooltip>

            <Collapse in={grouped}>
              <Tooltip
                title={groupType === "PLACE" ? "센서별 그룹" : "장소별 그룹"}
                placement="top"
                TransitionComponent={Zoom}
                arrow
              >
                <IconButton onClick={() => setGroupType(groupType === "PLACE" ? "SENSOR_TYPE" : "PLACE")}>
                  {groupType === "PLACE" ? <MssIcon name="wash-machine" /> : <MssIcon name="home-work" />}
                </IconButton>
              </Tooltip>
            </Collapse>
            <input
              style={{
                height: "35px",
                borderRadius: "25px",
                outline: "none",
                backgroundColor: "#fafaff",
                border: "1px solid #ddd",
                padding: "0 20px",
                minWidth: "200px",
                fontSize: "14px",
              }}
              placeholder="설치 장소를 입력해주세요"
              value={searchValue}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Grid>

          <Grid
            container
            sx={{
              height: "440px",
              overflowY: "scroll",
              paddingX: "20px",
              paddingY: "4px",
              marginTop: "10px",
            }}
          >
            {renderSensorList()}
          </Grid>
          <Grid item container justifyContent={"center"} marginTop="64px">
            <Button
              sx={{
                minWidth: "160px",
                backgroundColor: "#50CDD0",
                color: "#fff",
                borderRadius: "23.5px",
                "&:hover": {
                  backgroundColor: "#49BBBE",
                },
              }}
              onClick={onConfirm}
            >
              비교하기
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
});

export default SensorSelectModal;
