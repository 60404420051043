import { Box, Container, Grid, IconButton, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { Outlet, useNavigate } from "react-router-dom";
import { useRootStore } from "../../../contexts/RootContext";
import { useEffect } from "react";
import { CustomLink } from "../../../components/common/CustomLink";
import "./index.css";
import Button from "../../../components/common/Button";
import MssIcon from "../../../components/common/MssIcon";

const MyPageScreen = observer(() => {
  const { authStore } = useRootStore();
  const navigate = useNavigate();
  useEffect(() => {});
  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100%",
        overflowY: "hidden",
        backgroundColor: "#F7F7FC",
      }}
    >
      <Container style={{ marginTop: "70px" }}>
        <Grid container flexDirection={"column"}>
          <span style={{ marginTop: "64px", marginBottom: "32px", fontSize: "32px" }}>마이페이지</span>
          <div style={{ width: "53px", backgroundColor: "#333", height: "2px" }}></div>
        </Grid>
        <Grid container>
          <Grid item width={"260px"} marginTop="25px" display="flex" flexDirection="column" alignItems={"flex-start"}>
            <Button btnType="text">
              <CustomLink to="" class={"link"}>
                회원정보 수정
              </CustomLink>
            </Button>
            <Button btnType="text">
              <CustomLink to={"company-edit"} class={"link"} matchEnd={false}>
                소속기관 정보
              </CustomLink>
            </Button>
            <Button
              btnType="text"
              className="link"
              sx={{ color: "#868298" }}
              onClick={() => authStore.signOut(() => navigate("/"))}
            >
              로그아웃
            </Button>
          </Grid>
          <Grid item flex={1}>
            <Box
              sx={{
                backgroundColor: "#fff",
                padding: "50px",
                borderRadius: "10px",
                outline: "1px solid #D7D7D7",
                height: "100vh",
                flex: 3,
              }}
            >
              <Container maxWidth={false}>
                <Grid container alignItems={"center"}>
                  <Grid item marginRight={"15px"}>
                    <IconButton sx={{ position: "relative" }}>
                      {false ? null : ( // <img height={51} width={51} src={authStore.user.user_icon} />
                        <MssIcon name="user" />
                      )}
                      <MssIcon name="edit" style={{ position: "absolute", bottom: 7, right: 7 }} />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <Typography fontSize="18px">{authStore.user.type}</Typography>
                  </Grid>
                </Grid>
                <Outlet />
              </Container>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
});

export default MyPageScreen;
