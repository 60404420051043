import Grid from "@mui/material/Grid";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useDetailedDataStore } from "../../../../../contexts/DetailedDataContext";
import DoorSectionOne from "./DoorSectionOne";

export type SensorDetailProps = {
  main: boolean;
  sensorId: string;
  refresh?: boolean;
};

const InAndOutMoreDetailed = observer(({ main, sensorId, refresh }: SensorDetailProps) => {
  const { master, slave } = useDetailedDataStore();
  const door = main ? master.door : slave.door;

  useEffect(() => {
    door.refresh(sensorId);
  }, [refresh]);

  return (
    <Grid container className="more-detail-box">
      <DoorSectionOne sectionOne={door.sectionOne} sensorId={sensorId} />
    </Grid>
  );
});

export default InAndOutMoreDetailed;
