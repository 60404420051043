import Grid from "@mui/material/Grid";
import { observer } from "mobx-react-lite";
import { useSensorRegisterStore } from "../../../contexts/SensorRegisterContext";
import Spot from "../../../components/sensor-register/input/Spot";
import Gate from "../../../components/sensor-register/input/Gate";
import Hub from "../../../components/sensor-register/input/Hub";
import { useNavigate } from "react-router-dom";
import SensorLocation from "../../../components/sensor-register/SensorLocation";
import SelectPurpose from "../../../components/sensor-register/SelectPurpose";
import SensorHelp from "../../../components/sensor-register/SensorHelp";
import SensorHubConnection from "../../../components/sensor-register/SensorHubConnection";
import { useEffect } from "react";
import SensorConfirm from "../../../components/sensor-register/SensorConfirm";
import AiGate from "../../../components/sensor-register/input/AiGate";

const SPOT = [null, <Spot />, <SensorLocation />, <SelectPurpose />, <SensorConfirm />, <SensorHelp />];
const GATE = [null, <Gate />, <SensorLocation />, <SensorConfirm />, <SensorHelp />];
const GATE_3D = [null, <AiGate />, <SensorLocation />, <SensorConfirm />, <SensorHelp />];
const HUB = [null, <Hub />, <SensorHubConnection />, <SensorLocation />, <SensorConfirm />, <SensorHelp />];

const SensorRegiterSteps = observer(() => {
  const { stepper } = useSensorRegisterStore();
  const navigate = useNavigate();

  useEffect(() => {
    !stepper.type && navigate("/sensor-register");
  }, []);

  const getComponent = () => {
    switch (stepper.type) {
      case "SPOT":
        return SPOT[stepper.step];
      case "GATE":
        return GATE[stepper.step];
      case "GATE_3D":
        return GATE_3D[stepper.step];

      default:
        return HUB[stepper.step];
    }
  };
  return (
    <Grid item flex={1}>
      {getComponent()}
    </Grid>
  );
});

export default SensorRegiterSteps;
