import Grid from "@mui/material/Grid";
import { observer } from "mobx-react-lite";
import GateSectionOne from "./GateSectionOne";
import GateSectionTwo from "./GateSectionTwo";
import { useDetailedDataStore } from "../../../../../contexts/DetailedDataContext";
import { SensorDetailProps } from "../DoorMoreDetailed/DoorMoreDetailed";
import "../more-detailed.css";
import { useEffect } from "react";

const GateMoreDetailed = observer(({ main, sensorId, refresh }: SensorDetailProps) => {
  const { master, slave } = useDetailedDataStore();
  const gate = main ? master.gate : slave.gate;

  useEffect(() => {
    gate.refresh(sensorId);
  }, [refresh]);

  return (
    <Grid container className="more-detail-box" paddingX={"0px"}>
      <GateSectionOne sectionOne={gate.sectionOne} sensorId={sensorId} />
      <GateSectionTwo sectionTwo={gate.sectionTwo} sensorId={sensorId} />
    </Grid>
  );
});

export default GateMoreDetailed;
