import Grid from "@mui/material/Grid";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { StyleSheet } from "./SpotDataInDetail";
import { memo, useEffect, useState } from "react";
import moment from "moment";
import api from "../../../../api/api";
import { SensorOverviewProps } from "./InAndOutDataInDetail";
import { FireDetectionOverview } from "../../../../../types/sensor";
import CountUp from "react-countup";

const styles = StyleSheet.create({
  box: {
    alignItems: "center",
    height: "28px",
    justifyContent: "space-between",
    marginTop: "10px",
  },
  key: {
    color: "#565656",
    fontSize: "12px",
  },
  value: {
    color: "#333333",
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "end",
    lineHeight: 0.7,
  },
  subValue: {
    color: "#ABAABA",
    fontSize: "10px",
    fontWeight: "normal",
  },
});

type PieChart = { labels: Array<any>; series: Array<any> };

const FireDetectionDataInDetail = memo(({ sensorId, setRssi, setBattery }: SensorOverviewProps) => {
  const [data, setData] = useState<FireDetectionOverview | undefined>();
  const [pieData, setPieData] = useState<PieChart>({ labels: [], series: [1, 1] });

  const fetchData = async () => {
    const res = await api.get<FireDetectionOverview>(`/v1/sensor/fire-detection/${sensorId}/overview`);
    if (!res.ok || !res.data) return;
    setRssi && setRssi(res.data.rssi);
    setBattery && setBattery(res.data.battery);
    setData(res.data);
  };
  const fetchPieData = async () => {
    const res = await api.get<{ data: Array<any> }>(`/v1/sensor/fire-detection/${sensorId}/summary`, {
      start_date: moment().format("YYYY-MM-DD"),
    });
    if (!res.ok || !res.data) return;
    let d: typeof pieData = { labels: [], series: [] };
    res.data.data.forEach((obj: { id: string; value: number }) => {
      d.labels.push(obj.id);
      d.series.push(obj.value);
    });
    setPieData(d);
  };

  useEffect(() => {
    fetchData();
    fetchPieData();
  }, []);

  const chartOptions: ApexOptions = {
    labels: pieData.labels,
    legend: {
      show: false,
    },
    colors: ["#FF8080", "#80C2FF"],
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -35,
        },
      },
    },
  };

  return (
    <Grid container marginTop={"7px"}>
      <Grid item container flex={1} flexDirection="column">
        <Grid item container style={styles.box}>
          <span style={styles.key}>최근 화재 감지</span>
          <span style={styles.value}>{moment(data?.recent_emergency_detection).format("MM/DD hh:mm")}</span>
        </Grid>
        <Grid item container style={styles.box}>
          <span style={styles.key}>최근 비정상 신호</span>
          <span style={styles.value}>
            {moment(data?.recent_abnormal_detection).format("MM/DD hh:mm")} <br />
          </span>
        </Grid>
        <Grid item container style={styles.box}>
          <span style={styles.key}>최근 정상 신호 </span>
          <span style={styles.value}>{moment(data?.recent_normal_detection).format("dd hh:mm")}</span>
        </Grid>
      </Grid>
      <Grid item container flex={1} paddingLeft="45px">
        <Grid item container style={styles.box}>
          <span style={styles.key}>주간 누적 화재 감지</span>
          <span style={styles.value}>
            <CountUp end={data ? data?.weekly_detect_count : 0} duration={1} />회
          </span>
        </Grid>
        <Grid item container style={styles.box}>
          <span style={styles.key}>주간 비정상 신호</span>
          <span style={styles.value}>
            <CountUp end={data ? data?.weekly_abnormal_count : 0} duration={1} />회
          </span>
        </Grid>
        <Grid item container style={styles.box}>
          <span style={styles.key}>주간 정상 신호율</span>
          <span style={styles.value}>
            <CountUp end={data ? data?.weekly_normal_rate : 0} duration={1} />%
          </span>
        </Grid>
      </Grid>
      <Grid item container width={"318px"} paddingLeft="25px">
        <ReactApexChart options={chartOptions} series={pieData.series} type="pie" width={200} />
      </Grid>
    </Grid>
  );
});

export default FireDetectionDataInDetail;
