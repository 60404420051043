import { GridColDef } from "@mui/x-data-grid";
import { ApexOptions } from "apexcharts";
import moment from "moment";
export const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "No",
    width: 90,
    align: "center",
    headerAlign: "center",
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
  },
  {
    field: "created_at",
    headerName: "날짜(시간)",
    width: 150,
    sortable: true,
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    flex: 3,
  },
  {
    field: "value",
    headerName: "감지횟수",
    width: 150,
    sortable: false,
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    valueFormatter: (obj) => `${obj.value} 회`,
    flex: 2,
  },
  {
    field: "alive",
    headerName: "신호상태",
    width: 110,
    sortable: false,
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    valueFormatter: (obj) => (obj.value ? "정상" : "비정상"),
    flex: 3,
  },
  {
    field: "battery",
    headerName: "배터리",
    sortable: false,
    width: 160,
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    valueFormatter: (obj) => `${obj.value}%`,
    flex: 1,
  },
  {
    field: "rssi",
    headerName: "신호세기",
    sortable: false,
    width: 160,
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    flex: 2,
  },
];

export const DOOR_SECTION_TWO_COLUMN_OPTIONS: ApexOptions = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "55%",
    },
  },
  dataLabels: {
    enabled: false,
  },
  colors: ["#352D59", "#6AE1E3", "#6852CF", "#5B8FF9"],
  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"],
  },

  fill: {
    opacity: 1,
  },
  tooltip: {
    y: {
      formatter: function (val) {
        return "$ " + val + " thousands";
      },
    },
  },
};

export const EMPTY_CHART = [
  {
    data: [],
  },
];

export const LineOptions: ApexOptions = {
  chart: {
    id: "door-line-chart",
    offsetX: 0,
    parentHeightOffset: 0,
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  colors: ["#1C1344", "#50CDD0"],
  grid: {
    strokeDashArray: 2,
  },
  stroke: {
    width: 2,
    curve: "straight",
    colors: ["#1C1344", "#50CDD0"],
    dashArray: [0, 5],
  },
  markers: {
    size: 0,
    strokeColors: ["#1C1344", "#50CDD0"],
    hover: {
      size: 3,
    },
  },

  xaxis: {
    type: "category",
    labels: {
      rotate: 0,
      hideOverlappingLabels: true,
      offsetY: -5,
      maxHeight: 20,
      formatter: (value) => {
        return moment(value).format("h A");
      },

      style: {
        fontSize: "12px",
        colors: "#9F9FB4",
      },
    },

    axisTicks: {
      show: false,
    },
    tickAmount: 8,
  },
  yaxis: {
    labels: {
      style: {
        fontSize: "12px",
        colors: "#9F9FB4",
      },
    },
  },
  legend: {
    show: false,
  },
};
