import { Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import { PlaceListType } from "../../models/main/PlaceModel";
import { UserSensorModelType } from "../../models/main/UserSensorsModel";
import { getSensorInfo } from "../../utils/SensorUtils";
import Button from "../common/Button";
import MssIcon, { IconKey } from "../common/MssIcon";
import { StyleSheet } from "../dashboard/sensor/overview/SpotDataInDetail";

type Props = {
  sensor: UserSensorModelType;
  open: boolean;
  onClose: (confirm: boolean) => void;
};

const SensorDeleteModal = ({ open, onClose, sensor }: Props) => {
  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose(false);
      }}
      maxWidth="md"
    >
      <DialogContent>
        <Grid container justifyContent={"center"} mt="30px">
          <span style={{ color: "#333", fontWeight: "bold", fontSize: "20px" }}>선택한 센서를 제거하시겠습니까?</span>
        </Grid>
        <Grid container marginTop={"10px"}>
          <Grid item md={6}>
            <Grid container justifyContent={"center"}>
              <MssIcon name={sensor.type as IconKey} height={350} width={200} />
            </Grid>
          </Grid>
          <Grid item container md={6} alignItems="center" flexDirection={"column"} justifyContent="center">
            <Grid container mt={1} alignItems={"center"}>
              <span style={styles.title}>센서 유형</span>
              <span style={styles.subTitle}>{getSensorInfo("", sensor.type).kr}</span>
            </Grid>
            <Grid container mt={1} alignItems={"center"}>
              <span style={styles.title}>센서 아이디</span>
              <span style={styles.subTitle}>{sensor.sensor_id}</span>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent={"center"} marginBottom="30px" columnSpacing={5}>
          <Grid item>
            <Button btnType="disabled" onClick={() => onClose(false)}>
              취소
            </Button>
          </Grid>

          <Grid item>
            <Button btnType="danger" onClick={() => onClose(true)}>
              확인
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const styles = StyleSheet.create({
  title: {
    fontSize: "16px",
    // fontWeight: "bold",
    color: "#4b4b4b",
    marginBottom: "2px",
    minWidth: "150px",
  },
  input: {
    height: "35px",
    borderRadius: "5px",
    outline: "none",
    backgroundColor: "#fafaff",
    padding: "0 20px",
    minWidth: "200px",
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    color: "#848484",
    border: "1px solid #abaaba",
    marginTop: "1px",
  },
  subTitle: {
    height: "35px",
    borderRadius: "5px",
    outline: "none",
    backgroundColor: "#fafaff",
    padding: "0 20px",
    minWidth: "200px",
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    color: "#848484",
    // border: "1px solid #abaaba",
    marginTop: "1px",
  },
});

export default SensorDeleteModal;
