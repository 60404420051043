import { createContext, ReactNode, useContext } from "react";
import UserSensorListModel, { UserSensorListModelType } from "../models/main/UserSensorsModel";

const UserSensorContext = createContext<UserSensorListModelType>({} as UserSensorListModelType);

const userSensorListStore = UserSensorListModel.create();

type Props = {
  children: ReactNode;
};

export const UserSensorListStoreProvider = ({ children }: Props) => {
  return <UserSensorContext.Provider value={userSensorListStore}>{children}</UserSensorContext.Provider>;
};
export const useUserSensorListStore = () => useContext(UserSensorContext);
