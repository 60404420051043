import { Instance, types } from "mobx-state-tree";

const SignupModel = types
  .model({
    agree_to_terms_and_conditions: types.optional(types.boolean, false),
    agree_to_collect_and_use_personal_info: types.optional(types.boolean, false),
    user_type: types.optional(types.string, "individual", ["individual", "organization", "company", undefined]),
    organization_uuid: types.optional(types.string, ""),
    company_uuid: types.optional(types.string, ""),
    email: types.optional(types.string, ""),
    password: types.optional(types.string, ""),
    phone_number: types.optional(types.string, ""),
    request_key: types.optional(types.string, ""),
    auth_code: types.optional(types.string, ""),
  })
  .actions((self) => ({
    setAgreeToTermsAndConditions(agree_to_terms_and_conditions: boolean) {
      self.agree_to_terms_and_conditions = agree_to_terms_and_conditions;
    },
    setAgreeToCollectAndUsePersonalInfo(agree_to_collect_and_use_personal_info: boolean) {
      self.agree_to_collect_and_use_personal_info = agree_to_collect_and_use_personal_info;
    },
    setUserType(user_type: "individual" | "organization" | "company") {
      self.user_type = user_type;
    },
    setOrganizationUuid(organization_uuid: string) {
      self.organization_uuid = organization_uuid;
    },
    setCompanyUuis(company_uuid: string) {
      self.company_uuid = company_uuid;
    },
    setEmail(email: string) {
      self.email = email;
    },
    setPhoneNumber(phone_number: string) {
      self.phone_number = phone_number;
    },
    setRequestKey(request_key: string) {
      self.request_key = request_key;
    },
    setAuthCode(auth_code: string) {
      self.auth_code = auth_code;
    },
  }));

export type SignupType = Instance<typeof SignupModel>;

export default SignupModel;
