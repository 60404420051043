import {
  SENSOR_TYPE_DOOR,
  SENSOR_TYPE_TEMP_HUMIDITY,
  SENSOR_TYPE_FIRE,
  SENSOR_TYPE_SOS,
} from "./../../constants/EmbeddedSensors";
import { cast, Instance, types } from "mobx-state-tree";
import api from "../../api/api";

const HubSensorModel = types.model({
  serial_number: types.string,
  child_sensor_id: types.string,
  child_sensor_type: types.number,
  active: types.boolean,
  created_at: types.string,
});

const SensorHubModel = types
  .model({
    uuid: types.optional(types.string, "2107000144"),
    sensors: types.array(HubSensorModel),
    selectedSensor: types.optional(types.string, ""),
  })
  .views((self) => ({
    get sensorCount() {
      return self.sensors.length;
    },
    get sensorTypeAndCount() {
      const data = {
        문열림: 0,
        온습도: 0,
        화재: 0,
        비상SOS벨: 0,
      };
      self.sensors.forEach((sensor) => {
        switch (String(sensor.child_sensor_type)) {
          case SENSOR_TYPE_DOOR:
            data["문열림"] += 1;
            break;
          case SENSOR_TYPE_TEMP_HUMIDITY:
            data["온습도"] += 1;
            break;
          case SENSOR_TYPE_FIRE:
            data["화재"] += 1;
            break;
          case SENSOR_TYPE_SOS:
            data["비상SOS벨"] += 1;
            break;
        }
      });

      return data;
    },
  }))
  .actions((self) => ({
    setUUID(uuid: string) {
      self.uuid = uuid;
    },
    setSensors(sensors: Array<HubSensorType>) {
      self.sensors = cast(sensors);
    },
    setSelected(selectedSensor: string, callback?: VoidFunction) {
      self.selectedSensor = selectedSensor;
      callback && callback();
    },
  }))
  .actions((self) => ({
    fetchSensors: async () => {
      const res = await api.get<HubSensorResType>(`/v1/hub/${self.uuid}/sensor`);
      if (!res.ok) return;
      res.data && self.setSensors(res.data.sensors);
    },
  }));

type HubSensorResType = { ts: number; hub_id: string; sensor_count: number; sensors: Array<HubSensorType> };
type HubSensorType = Instance<typeof HubSensorModel>;
export default SensorHubModel;
