import { useState } from "react";
import { RequiredMark } from "../RequiredMark";
import { TextField, Button, Grid, Dialog, DialogTitle, DialogContent, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useRootStore } from "../../../contexts/RootContext";
import { observer } from "mobx-react-lite";

export const ValidateId = observer(() => {
  const [openModal, setOpenModal] = useState(false);

  const { signupStore } = useRootStore();
  const { email, setEmail, request_key, setRequestKey } = signupStore;

  const idCheckHandler = () => {};

  return (
    <Container>
      <IdValidationModal open={openModal} setOpen={setOpenModal} msg={"Some message"} />
      <Grid container alignItems="self-start" columnSpacing={1} marginTop="20px">
        <Grid item xs={12} md={2}>
          <span style={{ fontSize: "18px", fontWeight: "bold" }}>
            <RequiredMark /> 이메일
          </span>
        </Grid>

        <Grid item xs={8} md={4}>
          <TextField
            fullWidth
            size="small"
            helperText={"*올바른 이메일"}
            FormHelperTextProps={{ style: { fontSize: "10px", color: "black" } }}
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant={"outlined"}
          />
        </Grid>

        <Grid item xs={4} md={2}>
          <Button fullWidth onClick={idCheckHandler} variant={"contained"}>
            중복확인
          </Button>
        </Grid>
      </Grid>
      <Grid container alignItems="self-start" columnSpacing={1} marginTop="20px">
        <Grid item xs={12} md={2}>
          <span style={{ fontSize: "18px", fontWeight: "bold" }}>
            <RequiredMark /> 인증번호 입력
          </span>
        </Grid>
        <Grid item xs={8} md={4}>
          <TextField
            fullWidth
            size="small"
            helperText={"*(인증번호 받기)를 클릭하시면 입력하신 이메일로 인증번호를 발송합니다."}
            FormHelperTextProps={{ style: { fontSize: "10px", color: "black" } }}
            type="text"
            value={request_key}
            onChange={(e) => setRequestKey(e.target.value)}
            variant={"outlined"}
          />
        </Grid>
      </Grid>
    </Container>
  );
});

const useStyles: Function = makeStyles(() => ({
  userIcon: {
    marginTop: "40px",
    width: "150px",
  },
  modalSubmitBtn: {
    borderRadius: "20px",
    height: "43px",
    backgroundColor: "#c4c4c4",
    "&:hover": {
      backgroundColor: "#000000",
      color: "#ffffff",
    },
  },
}));

const messages = {
  empty: "아이디를 확인해주세요",
  taken: "이미 등록된 아이디입니다.\n 확인 후 다시 입력해주세요.",
  available: "사용 가능한 아이디입니다.",
};

export const IdValidationModal = (props: any) => {
  const open = props.open;
  const setOpen = props.setOpen;
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="id-validation-dialog">
      <DialogTitle></DialogTitle>
      <DialogContent>
        <Container maxWidth="sm">
          <Grid container alignItems={"center"} justifyContent={"center"} spacing={3} padding={"2rem"}>
            <Grid item xs={12} container justifyContent={"center"}>
              <img
                // src={userIcon}
                alt="user-icon"
                className={classes.userIcon}
              />
            </Grid>
            <Grid item xs={12} container justifyContent={"center"}>
              <h3>{messages.empty}</h3>
            </Grid>
            <Grid item xs={12} md={4}>
              <Button fullWidth autoFocus onClick={handleClose} className={classes.modalSubmitBtn}>
                Submit
              </Button>
            </Grid>
          </Grid>
        </Container>
      </DialogContent>
    </Dialog>
  );
};
