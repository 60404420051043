import { Box } from "@mui/material";
import moment from "moment";
import { forwardRef, memo, Ref, useEffect } from "react";
import DatePicker from "react-datepicker";
import MssIcon from "../../../../common/MssIcon";

type Props = {
  startDate: Date | null | string;
  endDate: Date | null | string;
  setEndDate: Function;
  setStartDate: Function;
};

const DATE_FORMAT = "YYYY-MM-DD";

export const DateInput = forwardRef(({ value, onClick }: any, ref: Ref<any>) => (
  <Box>
    <div
      onClick={onClick}
      ref={ref}
      style={{
        color: "#7d88b0",
        display: "flex",
        height: "38px",
        width: "287px",
        justifyContent: "flex-end",
      }}
    >
      <Box
        style={{
          marginLeft: "7px",
          border: "1px solid #7d88b0",
          display: "flex",
          minWidth: "125px",
          justifyContent: "space-between",
          justifySelf: "flex-end",
          alignItems: "center",
          borderRadius: "3px",
          padding: "0 10px",
        }}
      >
        <MssIcon name="calendar" /> {value.split("-")[0]}
      </Box>
      {value.split("-")[1] && (
        <>
          <Box
            style={{
              margin: "0 7px",
              display: "flex",
              alignItems: "center",
            }}
          >
            ~
          </Box>
          <Box
            style={{
              border: "1px solid #7d88b0",
              display: "flex",
              minWidth: "125px",
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: "3px",
              padding: "0 10px",
            }}
          >
            <MssIcon name="calendar" /> {value.split("-")[1]}
          </Box>
        </>
      )}
    </div>
  </Box>
));

export const MssDateRangePicker = memo(({ startDate, endDate, setEndDate, setStartDate }: Props) => {
  const onChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start ? moment(start).format(DATE_FORMAT) : null);
    setEndDate(end ? moment(end).format(DATE_FORMAT) : null);
  };

  startDate = typeof startDate === "string" ? new Date(startDate) : startDate;
  endDate = typeof endDate === "string" ? new Date(endDate) : endDate;

  useEffect(() => {
    if (!startDate && !endDate) {
      onChange([new Date(), new Date()]);
    }
  }, []);
  return (
    <Box>
      <DatePicker
        selected={startDate}
        onChange={(dates) => {
          onChange(dates);
        }}
        maxDate={new Date()}
        selectsRange
        startDate={startDate}
        endDate={endDate}
        dateFormat={"yyyy/MM/dd"}
        customInput={<DateInput />}
      />
    </Box>
  );
});

type DatePickerProps = {
  startDate: Date | null | string;
  setStartDate: Function;
};

export const MssDatePicker = memo(({ startDate, setStartDate }: DatePickerProps) => {
  const onChange = (date: Date | null) => {
    setStartDate(date ? moment(date).format(DATE_FORMAT) : null);
  };

  startDate = typeof startDate === "string" ? new Date(startDate) : startDate;

  useEffect(() => {
    if (!startDate) {
      onChange(new Date());
    }
  }, []);
  return (
    <Box>
      <DatePicker
        selected={startDate}
        maxDate={new Date()}
        onChange={onChange}
        startDate={startDate}
        dateFormat={"yyyy/MM/dd"}
        customInput={<DateInput />}
      />
    </Box>
  );
});
