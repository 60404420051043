import { Box, Grid, IconButton, MenuItem, Select, Tooltip, Zoom } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useDetailedDataStore } from "../../../contexts/DetailedDataContext";
import { useUserSensorListStore } from "../../../contexts/UserSensorContext";
import { UserSensorModelType } from "../../../models/main/UserSensorsModel";
import { getSensorInfo } from "../../../utils/SensorUtils";
import Button from "../../common/Button";
import MssSensor from "../../common/MssSensor";
import MuiIcon from "../../common/MuiIcon";
import SensorDeleteModal from "../../modals/SensorDeleteModal";
import SensorBatteryIcon from "./SensorBatteryIcon";
import SensorNetworkIcon from "./SensorNetworkIcon";

const SensorSelect = ({
  list,
  selected,
  onSelect,
}: {
  list: Array<UserSensorModelType>;
  selected: string;
  onSelect: (sensorId: string) => void;
}) => {
  return (
    <Select size="small" value={selected} sx={{ minWidth: "140px" }}>
      {list.slice().map((sensor, i) => (
        <MenuItem key={sensor.uuid + sensor.name} value={sensor.sensor_id} onClick={() => onSelect(sensor.sensor_id)}>
          <span style={{ fontSize: "18px" }}>
            {getSensorInfo("", sensor.type).kr}-{sensor.name}{" "}
          </span>
        </MenuItem>
      ))}
    </Select>
  );
};

type Props = {
  sensor: UserSensorModelType;

  //grouped props
  groupList?: Array<UserSensorModelType>;
  setSelected?: (sensorId: string) => void;
};

const SensorOverview = observer(({ sensor, groupList, setSelected = () => {} }: Props) => {
  const [battery, setBattery] = useState(0);
  const [rssi, setRssi] = useState(0);
  const [nameEdit, setNameEdit] = useState(false);
  const [name, setName] = useState<string>(sensor.name ? sensor.name : "");
  const [deleteModalShow, setDeleteModalShow] = useState(false);

  const { setSelectedMaster, setShowMaster } = useDetailedDataStore();
  const { modifySensorName, deleteSensor } = useUserSensorListStore();

  const onSaveName = () => {
    modifySensorName(sensor.uuid, name);
    setNameEdit(false);
    sensor.setName(name);
  };

  const handleDeleteModalClose = (confirm: boolean) => {
    setDeleteModalShow(false);
    confirm && deleteSensor(sensor.uuid);
  };

  return (
    <Grid
      className={`animate__animated animate__zoomIn animate__faster`}
      item
      sx={{
        border: "0.5px solid #D7D7D7",
        backgroundColor: "#FFFFFF",
        borderRadius: "10px",
        padding: "18px",
        marginTop: "10px",
      }}
      flexDirection="column"
    >
      <Grid item container alignItems={"center"} height={"40px"}>
        {nameEdit ? (
          <>
            <input
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              autoFocus
              style={{
                outline: "none",
                height: "40px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                padding: "0 10px",
              }}
            />
            <Box marginLeft={"10px"}>
              <Tooltip
                title={nameEdit ? "취소" : `센서이름 변경`}
                placement="top"
                TransitionComponent={Zoom}
                arrow
              >
                <IconButton onClick={() => setNameEdit(false)}>
                  <MuiIcon name="close" />
                </IconButton>
              </Tooltip>
            </Box>
          </>
        ) : (
          <>
            {groupList ? (
              <SensorSelect selected={sensor.sensor_id} list={groupList} onSelect={setSelected} />
            ) : (
              <span className="title" style={{ minWidth: "140px" }}>
                {getSensorInfo(sensor.sensor_id, sensor.type).kr}: {name}
              </span>
            )}
          </>
        )}
        <Box marginLeft={"10px"}>
          <Tooltip
            title={nameEdit ? "센서이름 저장" : `센서이름 변경`}
            placement="top"
            TransitionComponent={Zoom}
            arrow
          >
            <IconButton onClick={() => (nameEdit ? onSaveName() : setNameEdit(true))}>
              {nameEdit ? <MuiIcon name="check" /> : <MuiIcon name="edit" />}
            </IconButton>
          </Tooltip>
        </Box>
        <Box marginLeft={"10px"}>
          <Tooltip title={`Rssi ${rssi || 0}`} placement="top" TransitionComponent={Zoom} arrow>
            <span>
              <SensorNetworkIcon percentage={rssi} />
            </span>
          </Tooltip>
        </Box>
        <Box marginLeft={"10px"} flex={1}>
          <Tooltip title={`배터리 ${battery || 0}%`} placement="top" TransitionComponent={Zoom} arrow>
            <span>
              <SensorBatteryIcon percentage={battery} />
            </span>
          </Tooltip>
        </Box>
        {nameEdit ? (
          <Button btnType="danger" onClick={() => setDeleteModalShow(true)}>
            <MuiIcon name="delete" fontSize="small" sx={{ marginRight: "10px" }} />
            삭제
          </Button>
        ) : (
          <Button
            btnType="text"
            onClick={() => {
              setShowMaster(true);
              setSelectedMaster(sensor.sensor_id);
            }}
          >
            <span style={{ color: "#ABAABA", fontSize: "12px" }}>데이터 보기</span>
          </Button>
        )}
      </Grid>
      <MssSensor
        type={"in-detail"}
        sensorId={sensor.sensor_id}
        name={sensor.type}
        props={null}
        key={sensor.uuid}
        setRssi={setRssi}
        setBattery={setBattery}
      />
      <SensorDeleteModal open={deleteModalShow} onClose={handleDeleteModalClose} sensor={sensor} />
    </Grid>
  );
});

export default SensorOverview;
