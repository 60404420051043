import { Box, Grid, IconButton } from "@mui/material";
import { observer } from "mobx-react-lite";
import { usePlaceStore } from "../../contexts/PlaceContext";
import html2pdf from "html2pdf.js";
import { getSensorInfo } from "../../utils/SensorUtils";
import { useDetailedDataStore } from "../../contexts/DetailedDataContext";
import { PlaceImage } from "./Places/PlaceImage";
import { useRef, useState } from "react";
import SensorSelectModal from "../modals/SensorSelectModal/SensorSelectModal";
import { useUserSensorListStore } from "../../contexts/UserSensorContext";
import { useReactToPrint } from "react-to-print";
import MssIcon from "../common/MssIcon";
import MuiIcon from "../common/MuiIcon";
import MssSensor from "../common/MssSensor";
import "./sensor/more-detailed/more-detailed.css";
import Button from "../common/Button";

type Props = {
  master: boolean;
};

const SensorDetail = observer(({ master }: Props) => {
  const [showSensorSelectModal, setShowSensorSelectModal] = useState(false);
  const { showSlave, setShowMaster, setShowSlave, selectedMaster, selectedSlave } = useDetailedDataStore();
  const { getSensor } = useUserSensorListStore();
  const { selectedPlace } = usePlaceStore();
  const [refresh, setRefresh] = useState(false);

  const onSensorSelectModalClose = () => setShowSensorSelectModal(false);
  const toggleSensorSelectModal = () => setShowSensorSelectModal(!showSensorSelectModal);

  const sensorId = master ? selectedMaster : selectedSlave;
  const sensorItem = getSensor(sensorId ? sensorId : "", selectedPlace ? selectedPlace.uuid : "");
  const sensorInfo = getSensorInfo("", sensorItem ? sensorItem.type : "SPOT");

  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    copyStyles: true,
    print: async (printIframe: HTMLIFrameElement) => {
      const document = printIframe.contentDocument;
      if (document) {
        const html = document.getElementsByTagName("html")[0];
        await html2pdf()
          .set({
            jsPDF: { format: "a4" },
            html2canvas: {
              width: 900,
              x: -50,
              y: -50,
              windowHeight: 2000,
            },
          })
          .from(html)
          .save();
      }
    },
  });

  return (
    <Box
      className={`animate__animated ${master ? "animate__slideInRight" : "animate__slideInLeft"} animate__faster`}
      sx={{
        padding: "33px",
        paddingLeft: master ? "33px" : "103px",
        paddingTop: "70px",

        borderRight: master ? "none" : "2px solid #E5E5E5",
        flex: 1,
      }}
    >
      <Box
        ref={componentRef}
        sx={{
          overflowX: "hidden",
          zIndex: 0,
          height: "100vh",
          overflowY: "scroll",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          "::-webkit-scrollbar": { display: "none" },
        }}
      >
        <SensorSelectModal open={showSensorSelectModal} onClose={onSensorSelectModalClose} />
        <Grid
          container
          sx={{
            position: "sticky",
            top: 0,
            flexDirection: "column",
            backgroundColor: "#fff",
            paddingBottom: "13px",
            zIndex: 20,
          }}
        >
          <Grid item justifyContent={"flex-end"} container height={"47px"} paddingTop={"10px"}>
            {master && (
              <IconButton
                onClick={() => {
                  setShowMaster(false);
                  setShowSlave(false);
                }}
              >
                <MuiIcon name="close" style={{ fontSize: "24px" }} />
              </IconButton>
            )}
          </Grid>
          <Grid item sx={{ borderBottom: "1px solid #ABAABA", paddingBottom: "10px", display: "flex" }}>
            <span style={{ fontSize: "24px", fontWeight: "bold", flex: 1 }}>데이터 분석</span>
            <IconButton onClick={() => setRefresh(!refresh)}>
              <MssIcon name="refresh" />
            </IconButton>
            <IconButton
              onClick={() => {
                setTimeout(() => handlePrint(), 1000);
              }}
            >
              <MssIcon name="pdf" />
            </IconButton>
            {master ? (
              <Button
                btnType="text"
                onClick={() => {
                  toggleSensorSelectModal();
                }}
                sx={{ color: "#9C9C9C" }}
              >
                {showSlave ? <MssIcon name="split-screen-filled" /> : <MssIcon name="split-screen-outline" />}

                <span style={{ fontSize: "16px", marginLeft: "5px", color: showSlave ? "#333" : "#9C9C9C" }}>
                  {" "}
                  분할보기
                </span>
              </Button>
            ) : (
              <IconButton
                onClick={() => {
                  setShowSlave(false);
                }}
              >
                <MuiIcon name="close" style={{ fontSize: "24px" }} />
              </IconButton>
            )}
          </Grid>
          <Grid item marginTop="17px">
            <span style={{ fontWeight: "bold", fontSize: "16px", color: "#333" }}>{selectedPlace?.name}</span>
            <span style={{ fontSize: "16px", color: "#ABAABA", marginLeft: "8px" }}>
              {selectedPlace?.address} {selectedPlace?.address_detail}
            </span>
          </Grid>
        </Grid>
        <Grid container height={"234px"} overflow="hidden" sx={{ backgroundColor: "#F2F2F2" }}>
          {selectedPlace && <PlaceImage place={selectedPlace} />}
        </Grid>
        <Grid
          container
          sx={{
            position: "sticky",
            top: 140,
            borderBottom: "1px solid #1C1344",
            marginBottom: "20px",
            backgroundColor: "#fff",
            zIndex: 20,
          }}
        >
          <Button
            sx={{
              borderRadius: 0,
              marginRight: "5px",
              marginTop: "24px",
              backgroundColor: "#352D59",
              color: "#fff",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "#32295A",
              },
            }}
          >
            {sensorInfo.kr}
          </Button>
        </Grid>
        <MssSensor
          type="detailed"
          name={sensorItem ? sensorItem.type : "SPOT"}
          sensorId={sensorItem ? sensorItem.sensor_id : "SPOT"}
          key={sensorItem ? sensorItem.id : "sensorIndetail"}
          refresh={refresh}
          props={null}
        />
      </Box>
    </Box>
  );
});

export default SensorDetail;
