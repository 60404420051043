import { Button, Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import {
  SENSOR_TYPE_DOOR,
  SENSOR_TYPE_FIRE,
  SENSOR_TYPE_GATE,
  SENSOR_TYPE_GATE_3D,
  SENSOR_TYPE_SOS,
  SENSOR_TYPE_SPOT,
  SENSOR_TYPE_TEMP_HUMIDITY,
} from "../../constants/EmbeddedSensors";
import { usePlaceStore } from "../../contexts/PlaceContext";
import { useSensorRegisterStore } from "../../contexts/SensorRegisterContext";
import AiGateHelp from "./help/AiGateHelp";
import EmergencyHelp from "./help/EmergencyHelp";
import FireDetectionHelp from "./help/FireDetectionHelp";
import GateHelp from "./help/GateHelp";
import InAndOutHelp from "./help/InAndOutHelp";
import SpotHelp from "./help/SpotHelp";
import TempAndHumidityHelp from "./help/TempAndHumidityHelp";

const SensorHelp = observer(() => {
  const { sensor, stepper, registerSensor } = useSensorRegisterStore();
  const { selected } = usePlaceStore();
  const navigate = useNavigate();
  const getSensorScreenType = () => {
    switch (sensor.selectedType) {
      case String(SENSOR_TYPE_SPOT):
        return <SpotHelp />;
      case String(SENSOR_TYPE_TEMP_HUMIDITY):
        return <TempAndHumidityHelp />;
      case String(SENSOR_TYPE_FIRE):
        return <FireDetectionHelp />;
      case String(SENSOR_TYPE_GATE_3D):
        return <AiGateHelp />;
      case String(SENSOR_TYPE_GATE):
        return <GateHelp />;
      case String(SENSOR_TYPE_DOOR):
        return <InAndOutHelp />;
      case String(SENSOR_TYPE_SOS):
        return <EmergencyHelp />;
      default:
        return <SpotHelp />;
    }
  };

  return (
    <Grid container>
      {getSensorScreenType()}
      <Grid container marginTop={"50px"} justifyContent={"center"}>
        <Button
          variant="contained"
          onClick={() => navigate("/")}
          sx={{
            borderRadius: "23px",
            backgroundColor: "#50CDD0",
            color: "#fff",
            width: "160px",
            height: "42px",
            fontWeight: "bold",
            fontSize: "18px",
            "&:hover": {
              backgroundColor: "#4DC8CB",
            },
          }}
        >
          서비스 시작하기
        </Button>
      </Grid>
      <Button onClick={() => stepper.previousStep()}>돌아가기</Button>
    </Grid>
  );
});

export default SensorHelp;
