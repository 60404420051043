import Grid from "@mui/material/Grid";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useDetailedDataStore } from "../../../../../contexts/DetailedDataContext";
import { SensorDetailProps } from "../DoorMoreDetailed/DoorMoreDetailed";
import TempAndHumiditySectionOne from "./TempAndHumiditySectionOne";
import TempAndHumiditySectionTwo from "./TempAndHumiditySectionTwo";

const TempAndHumidityMoreDetailed = observer(({ main, sensorId, refresh }: SensorDetailProps) => {
  const { master, slave } = useDetailedDataStore();
  const tempAndHumidity = main ? master.tempAndHumidity : slave.tempAndHumidity;

  useEffect(() => {
    tempAndHumidity.refresh(sensorId);
  }, [refresh]);

  return (
    <Grid container className="more-detail-box">
      <TempAndHumiditySectionOne sectionOne={tempAndHumidity.sectionOne} sensorId={sensorId} />
      <TempAndHumiditySectionTwo sectionTwo={tempAndHumidity.sectionTwo} sensorId={sensorId} />
    </Grid>
  );
});

export default TempAndHumidityMoreDetailed;
