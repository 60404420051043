import { Instance, types } from "mobx-state-tree";
import { toast } from "react-toastify";
import api from "../api/api";

const MemberModel = types
  .model({
    uuid: types.optional(types.string, ""),
    id: types.optional(types.number, 0),
    created_at: types.optional(types.number, 0),
    updated_at: types.optional(types.number, 0),
    deleted_at: types.maybeNull(types.number),
    type: types.optional(types.string, ""),
    username: types.optional(types.string, ""),
    should_change_password: types.optional(types.boolean, false),
    push_notification_agrement: types.optional(types.boolean, true),
  })
  .actions((self) => ({
    changePassword: async (password: string, new_password: string, callback: () => void) => {
      const res = await api.put("/v1/auth/password", { password, new_password });
      if (!res.ok && res.status === 500) return toast.error("서버 오류, 다시 시도해주세요");
      if (!res.ok) return toast.error("유효하지 않은 비밀번호입니다");
      toast.success("비밀번호가 변경되었습니다");
      callback();
    },
  }));

const OrganizationModel = types
  .model({
    id: types.optional(types.number, 0),
    created_at: types.optional(types.number, 0),
    updated_at: types.optional(types.number, 0),
    user_uuid: types.optional(types.string, ""),
    name: types.optional(types.string, ""),
    department_name: types.optional(types.string, ""),
    charger_name: types.optional(types.string, ""),
    charger_contact_number: types.optional(types.string, ""),
    partOne: types.optional(types.string, ""),
    partTwo: types.optional(types.string, ""),
    partThree: types.optional(types.string, ""),
  })
  .views((self) => ({
    contactNumberPart(part: number) {
      return self.charger_contact_number.split("-")[part];
    },
  }))
  .actions((self) => ({
    setName(name: string) {
      self.name = name;
    },
    setDepartmentName(departmentName: string) {
      self.department_name = departmentName;
    },
    setChargerName(chargerName: string) {
      self.charger_name = chargerName;
    },
    sumChargerContactNumber() {
      self.charger_contact_number = self.partOne + "-" + self.partTwo + "-" + self.partThree;
    },
    setPart(partValue: string, part: number) {
      switch (part) {
        case 0:
          self.partOne = partValue;
          break;
        case 1:
          self.partTwo = partValue;
          break;
        case 2:
          self.partThree = partValue;
          break;
      }
      this.sumChargerContactNumber();
    },
  }))
  .actions((self) => ({
    updateOrganization: async (callback: Function) => {
      callback(true);
      const toasId = "organization-update";
      toast.loading("소속기관 정보 수정 중", { toastId: toasId });
      const res = await api.put("/v1/user/me/organization", {
        name: self.name,
        department_name: self.department_name,
        charger_name: self.charger_name,
        charger_contact_number: self.charger_contact_number,
      });

      if (!res.ok)
        return toast.update(toasId, {
          isLoading: false,
          render: res.status,
          hideProgressBar: true,
          type: "error",
        });
      else {
        toast.update(toasId, {
          isLoading: false,
          render: "소속기관 정보가 수정되었습니다",
          hideProgressBar: true,
          type: "success",
        });
      }

      setTimeout(() => {
        toast.dismiss(toasId);
        callback(false);
      }, 1000);
    },
  }));

const MypageModel = types
  .model({
    member: types.optional(MemberModel, {} as MemberType),
    organization: types.optional(OrganizationModel, {} as OrganizationType),
  })
  .actions((self) => ({
    setMember(member: MemberType) {
      self.member = member;
    },
    setOrganizations(organization: OrganizationType) {
      self.organization = organization;
    },
  }))
  .actions((self) => ({
    fetchMember: async () => {
      const res = await api.get<{ user: MemberType }>("/v1/user/me");
      if (!res.ok || !res.data) return;
      self.setMember(res.data.user);
    },
    fetchOrganization: async () => {
      const res = await api.get<OrganizationType>("/v1/user/me/organization");
      if (!res.ok || !res.data) return;
      self.setOrganizations(res.data);
      res.data.charger_contact_number.split("-").map((v, i) => self.organization.setPart(v, i));
    },
    init() {
      this.fetchMember();
      this.fetchOrganization();
    },
  }));

type MemberType = Instance<typeof MemberModel>;
type OrganizationType = Instance<typeof OrganizationModel>;

export default MypageModel;
