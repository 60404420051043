import { Button, Grid } from "@mui/material";
import { DataGrid, GridRowParams } from "@mui/x-data-grid";
import { observer } from "mobx-react-lite";
import ReactApexChart from "react-apexcharts";
import { MssDatePicker, MssDateRangePicker } from "../common/MssDatePicker";
import { columns, COLUMN_OPTIONS } from "./TempAndHumidityConstants";
import { TempAndHumiditySectionOneType } from "../../../../../models/detailed-data/temp-humidity/TempAndHumiditySectionOne";
import { TableStyle } from "../GateMoreDetailed/GateSectionOne";
import CountUp from "react-countup";
import { CSVLink } from "react-csv";
import moment from "moment";
import { useEffect } from "react";

type Props = {
  sectionOne: TempAndHumiditySectionOneType;
  sensorId: string;
};

const TempAndHumiditySectionOne = observer(({ sectionOne, sensorId }: Props) => {
  const { startDate, setStartDate, init, temperature, humidity, data, endDate, setEndDate } = sectionOne;
  useEffect(() => {
    if (startDate && endDate) {
      init(sensorId);
    }
    if (!startDate && !endDate) {
      setStartDate(moment().format("YYYY-MM-DD"));
    }
  }, [startDate, endDate]);
  return (
    <Grid item container>
      <Grid container>
        <Grid item flex={1}>
          <span className="title">전체 온습도 데이터 그래프</span>
          <span className="sub-title">각 실내 공간별 설정 기간에 따른 온습도 변화 추이를 나타냅니다</span>
        </Grid>
        <Grid item display="flex" justifyContent={"flex-end"} alignItems="center">
          <MssDateRangePicker
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </Grid>
      </Grid>
      <Grid container height={"197px"} columnGap={2} marginTop="34px">
        <Grid
          item
          container
          sx={{
            backgroundColor: "#F7F7FC",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            paddingX: "38px",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <Grid
            container
            borderBottom={"1px solid #D7D7D7"}
            sx={{ flex: 5, justifyContent: "center", alignItems: "center", flexDirection: "column" }}
          >
            <span style={{ fontSize: "30px", fontWeight: "bold" }}>
              <CountUp end={temperature.current_temp} duration={1} />℃
            </span>
            <span style={{ fontSize: "18px", marginTop: "5px" }}>현재 실내 온도</span>
          </Grid>
          <Grid container sx={{ flex: 4, justifyContent: "space-around", alignItems: "center" }}>
            <Grid item sx={{ display: "flex", flexDirection: "column", justifyContent: "center", color: "#7D88B0" }}>
              <span>최고온도</span>
              <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                <CountUp end={temperature.highest_temp} duration={1} />℃
              </span>
            </Grid>
            <Grid item sx={{ display: "flex", flexDirection: "column", justifyContent: "center", color: "#7D88B0" }}>
              <span>평균온도</span>
              <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                <CountUp end={temperature.lowest_temp} duration={1} />℃
              </span>
            </Grid>
            <Grid item sx={{ display: "flex", flexDirection: "column", justifyContent: "center", color: "#7D88B0" }}>
              <span>최저온도</span>
              <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                <CountUp end={temperature.avg_temp} duration={1} />℃
              </span>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          sx={{
            backgroundColor: "#F7F7FC",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            paddingX: "38px",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <Grid
            container
            borderBottom={"1px solid #D7D7D7"}
            sx={{ flex: 5, justifyContent: "center", alignItems: "center", flexDirection: "column" }}
          >
            <span style={{ fontSize: "30px", fontWeight: "bold" }}>
              <CountUp end={humidity.current_humid} duration={1} />%
            </span>
            <span style={{ fontSize: "18px", marginTop: "5px" }}>현재 실내 습도</span>
          </Grid>
          <Grid container sx={{ flex: 4, justifyContent: "space-around", alignItems: "center" }}>
            <Grid item sx={{ display: "flex", flexDirection: "column", justifyContent: "center", color: "#7D88B0" }}>
              <span>최고습도</span>
              <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                <CountUp end={humidity.highest_humid} duration={1} />%
              </span>
            </Grid>
            <Grid item sx={{ display: "flex", flexDirection: "column", justifyContent: "center", color: "#7D88B0" }}>
              <span>최고습도</span>
              <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                <CountUp end={humidity.avg_humid} duration={1} />%
              </span>
            </Grid>
            <Grid item sx={{ display: "flex", flexDirection: "column", justifyContent: "center", color: "#7D88B0" }}>
              <span>최고습도</span>
              <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                <CountUp end={humidity.lowest_humid} duration={1} />%
              </span>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container columnSpacing={2} marginTop={"45px"}>
        <Grid item md={12}>
          <span style={{ fontWeight: "bold", color: "#7D88B0" }}>실내 온습도 (누적 히스토리)</span>
          <ReactApexChart
            options={COLUMN_OPTIONS}
            series={
              data.line
                ? [
                    { data: data.line, type: "line", name: "온도" },

                    { data: data.bar, type: "column", name: "습도" },
                  ]
                : [
                    {
                      name: "온도",
                      type: "line",
                      data: [],
                    },
                    {
                      name: "습도",
                      type: "column",
                      data: [],
                    },
                  ]
            }
            type="line"
            height={200}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent={"flex-end"} marginTop={"20px"}>
        <CSVLink data={data.table ? data.table : []} filename={`Temperatur-and-Humidity-sensor-data-for-${startDate}`}>
          <Button
            sx={{
              color: "#333",
              borderRadius: "30px",
              border: "1px solid #333",
              minWidth: "125px",
              fontWeight: "bold",
            }}
          >
            데이터 추출하기
          </Button>
        </CSVLink>
      </Grid>
      <Grid container marginTop={"20px"}>
        <Grid item flex={1} height={"300px"}>
          <DataGrid
            rows={data.table ? data.table : []}
            columns={columns}
            //   disableSelectionOnClick
            sx={TableStyle}
            hideFooter
            // onRowClick={(params: GridRowParams) => {
            //   console.log(params);
            // }}
            isRowSelectable={(params: GridRowParams) => {
              return true;
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
});

export default TempAndHumiditySectionOne;
