import Grid from "@mui/material/Grid";
import { observer } from "mobx-react-lite";
import { MssDateRangePicker } from "../common/MssDatePicker";
import { HEATMAP_CHART_OPTIONS } from "./TempAndHumidityConstants";
import { TempAndHumiditySectionTwoType } from "../../../../../models/detailed-data/temp-humidity/TempAndHumiditySectionTwo";
import ReactApexChart from "react-apexcharts";
import { EMPTY_CHART } from "../DoorMoreDetailed/DoorConsts";
import { useEffect } from "react";
import moment from "moment";

type Props = {
  sectionTwo: TempAndHumiditySectionTwoType;
  sensorId: string;
};

const TempAndHumiditySectionTwo = observer(({ sectionTwo, sensorId }: Props) => {
  const { startDate, endDate, setStartDate, setEndDate, data, init } = sectionTwo;
  useEffect(() => {
    if (startDate && endDate) {
      init(sensorId);
    }
    if (!startDate && !endDate) {
      setStartDate(moment().add(-7, "days").format("YYYY-MM-DD"));
    }
  }, [startDate, endDate]);
  return (
    <Grid item container marginTop={"70px"}>
      <Grid container>
        <Grid item flex={1}>
          <span className="title">실내 온습도 누적 히스토리</span>
          <span className="sub-title">최근 1주일간의 온습도 정보를 히트맵으로 나타냅니다.</span>
        </Grid>
        <Grid item display="flex" justifyContent={"flex-end"} alignItems="center">
          <MssDateRangePicker
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
        </Grid>
      </Grid>
      <Grid container marginTop={"40px"}>
        <Grid item flex={1} height={"300px"}>
          <ReactApexChart
            options={HEATMAP_CHART_OPTIONS}
            series={data ? data : EMPTY_CHART}
            type="heatmap"
            height={300}
          />
        </Grid>
      </Grid>
    </Grid>
  );
});

export default TempAndHumiditySectionTwo;
