import { Instance, types } from "mobx-state-tree";
import api from "../../../api/api";

const WeeklyModel = types.model({
  bar_categories: types.frozen<Array<string>>(),
  bar_data: types.frozen(),
  pie: types.frozen(),
});

const SpotSectionTwo = types
  .model({
    startDate: types.maybeNull(types.string),
    weekly: types.optional(WeeklyModel, {} as WeeklyModelType),
  })
  .actions((self) => ({
    setStartDate(startDate: string | null) {
      self.startDate = startDate;
    },
    setWeekly(weekly: WeeklyModelType) {
      self.weekly = weekly;
    },
  }))
  .actions((self) => ({
    fetchWeekly: async (sensorId: string) => {
      const res = await api.get<WeeklyModelType>(`/v1/sensor/spot/${sensorId}/weekly-monthly-floating`, {
        start_date: self.startDate,
      });

      if (!res.ok || !res.data) return;
      self.setWeekly(res.data);
    },

    init(sensorId: string) {
      this.fetchWeekly(sensorId);
    },
  }));
type WeeklyModelType = Instance<typeof WeeklyModel>;
export type SpotSectionTwoType = Instance<typeof SpotSectionTwo>;
export default SpotSectionTwo;
