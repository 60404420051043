import Grid from "@mui/material/Grid";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useSensorRegisterStore } from "../../contexts/SensorRegisterContext";
import HubEmptyList from "./HubEmptyList";
import { getSensorInfo } from "../../utils/SensorUtils";
import MssIcon, { IconKey } from "../common/MssIcon";
import Button from "../common/Button";

const SensorHubConnection = observer(() => {
  const { hub, sensor, stepper } = useSensorRegisterStore();

  const renderSensorCountList = () => {
    let l = [];
    for (const [key, value] of Object.entries(hub.sensorTypeAndCount)) {
      l.push(
        <Grid
          key={key}
          item
          flex={1}
          container
          flexDirection={"column"}
          justifyContent="space-between"
          alignItems={"center"}
        >
          <span style={{ fontWeight: "bold", color: "#7D88B0" }}>[{key}]</span>
          <span style={{ fontSize: "12px", color: "#7D88B0" }}>{value}</span>
        </Grid>
      );
    }
    return l;
  };

  const renderSensors = () => {
    const sensors = hub.sensors.map((s) => {
      const isActive = hub.selectedSensor === s.child_sensor_id ? true : false;
      const { en, kr, number } = getSensorInfo(s.child_sensor_id, String(s.child_sensor_type));
      return (
        <Grid
          item
          container
          key={s.child_sensor_id}
          sx={{
            marginTop: "10px",
            backgroundColor: "#fafafa",
            borderRadius: "8px",
            outlineWidth: isActive ? "2px" : "1px",
            outlineColor: isActive ? "#6049cc" : "#dddddd",
            outlineStyle: "solid",
            height: "100px",
          }}
        >
          <Button
            fullWidth
            btnType="text"
            onClick={() => {
              hub.setSelected(s.child_sensor_id, () => {
                sensor.setUniqueId(s.child_sensor_id);
                sensor.setSelectedType(String(s.child_sensor_type));
              });
            }}
            disableRipple
          >
            <Grid container alignItems={"center"}>
              <Grid item overflow={"hidden"}>
                <MssIcon name={String(s.child_sensor_type) as IconKey} height="60px" width="80px" />
              </Grid>
              <Grid item container flex={1} flexDirection="column" alignItems={"flex-start"}>
                <span style={{ fontWeight: "bold", fontSize: "20px", color: "#352d59" }}>
                  {en}-{number} <span style={{ fontSize: "14px" }}>{kr} 센서</span>
                </span>
                <span style={{ fontWeight: "bold", color: "#888888" }}>{s.child_sensor_id}</span>
              </Grid>
            </Grid>
          </Button>
        </Grid>
      );
    });
    return sensors;
  };

  useEffect(() => {
    hub.fetchSensors();
  }, []);
  return (
    <Grid container>
      <Grid item>
        <span className="title">입력하신 HUB의 연결 정보를 확인해주세요</span>
        <span className="sub-title">
          해당 HUB에 센서를 추가적으로 연결 하시려면 ‘신규 연결’를 눌러주세요 <br />
          센서의 설치 장소를 등록하지 않으면 데이터를 확인하실 수 없습니다.
        </span>
      </Grid>
      <Grid
        item
        container
        sx={{ backgroundColor: "#ECECEC", px: "26px", py: "15px", borderRadius: "10px", mt: "30px" }}
      >
        <Grid item>
          <MssIcon name="hub" />
        </Grid>
        <Grid item flex={1} paddingLeft="27px">
          <Grid item borderBottom={"1px solid #352D59"} paddingBottom="6px">
            <span style={{ color: "#352D59", fontWeight: "bold", fontSize: "15px" }}>센서 연결 현황</span>
          </Grid>
          <Grid item container justifyContent={"space-around"} height="60px" paddingTop={"18px"}>
            {renderSensorCountList()}
          </Grid>
        </Grid>
      </Grid>

      {hub.sensorCount ? (
        <>
          <Grid
            container
            sx={{
              scrollbarWidth: "none",
              msOverflowStyle: "none",
              "::-webkit-scrollbar": { display: "none" },
              overflow: "scroll",
              maxHeight: "356px",
              height: "332px",
              paddingX: "2px",
              paddingBottom: "2px",
              marginTop: "20px",
            }}
          >
            {renderSensors()}
          </Grid>
        </>
      ) : (
        <HubEmptyList />
      )}
      <Grid container marginTop={"20px"} justifyContent={"space-between"}>
        <Button onClick={() => stepper.previousStep()} btnType="text">
          {"< 이전"}
        </Button>
        {hub.sensorCount > 0 && (
          <Button
            tooltipLabel={hub.selectedSensor ? "다음" : "센서를 선택해주세요"}
            btnType={hub.selectedSensor ? "default" : "disabled"}
            onClick={() => hub.selectedSensor && stepper.nextStep()}
          >
            다음 단계
          </Button>
        )}
      </Grid>
    </Grid>
  );
});

export default SensorHubConnection;
