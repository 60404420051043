import { CircularProgress, Dialog, DialogContent, DialogTitle, Grid, IconButton } from "@mui/material";
import PlaceIcon from "../../../resources/common/place.svg";
import { usePlaceStore } from "../../../contexts/PlaceContext";
import { useDropzone } from "react-dropzone";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { observer } from "mobx-react-lite";
import MuiIcon from "../../common/MuiIcon";
import Button from "../../common/Button";
import { PlaceListType } from "../../../models/main/PlaceModel";

type Props = {
  open: boolean;
  onClose: (value?: string) => any;
  place: PlaceListType;
};

const PlaceThumbnailModal = observer(({ open, onClose, place }: Props) => {
  const { modifyPlaceThumbnail } = usePlaceStore();
  const [files, setFiles] = useState<Array<any>>([]);
  const [pending, setPending] = useState(false);
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const handleClose = () => {
    onClose();
    clear();
  };

  const acceptedFileItems = acceptedFiles.map((file, i) => (
    <Grid container key={file.name + String(i)} sx={{ alignItems: "center" }}>
      {files.length > 0 && (
        <img src={files[0].preview} alt="uploaded-thimbnail" height={50} style={{ marginRight: "10px" }} />
      )}
      {file.name} - {file.size} bytes
      <IconButton
        sx={{ marginLeft: "10px" }}
        onClick={() => {
          setFiles([]);
        }}
      >
        <MuiIcon name="close" fontSize="small" />
      </IconButton>
    </Grid>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }, i) => (
    <li key={file.name + String(i)}>
      {file.name} - {file.size} bytes
      <ul>
        {errors.map((e) => (
          <li key={e.code}> {e.message}</li>
        ))}
      </ul>
    </li>
  ));

  const clear = () => {
    files.forEach((file) => URL.revokeObjectURL(file.preview));
    setFiles([]);
  };

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => clear();
  }, []);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      PaperProps={{ style: { borderRadius: "20px", padding: "20px 20px" } }}
    >
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
        이미지 변경
        <IconButton onClick={handleClose}>
          <MuiIcon name="close" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container alignItems={"center"} flexDirection={"column"}>
          <Grid container justifyContent={"center"} height={"270px"} width="480px" overflow={"hidden"}>
            <img
              src={place.thumbnail_url}
              alt="uploaded"
              height={"100%"}
              onError={(event: React.SyntheticEvent<HTMLImageElement, Event>) => {
                event.currentTarget.src = PlaceIcon;
              }}
            />
          </Grid>
          <Grid
            container
            {...getRootProps()}
            sx={{
              borderRadius: "15px",
              border: "1px solid #ccc",
              justifyContent: "center",
              mt: "20px",
              paddingY: "40px",
              minWidth: "600px",
            }}
            style={{ borderStyle: "dashed" }}
          >
            <input {...getInputProps()} />
            <p style={{ color: "#ccc" }}>이미지 파일을 여기에 드래그하거나 클릭해서 이미지 파일을 선택해 주세요</p>
          </Grid>

          {files.length > 0 && (
            <>
              <h4>로드된 이미지</h4>
              <ul>{acceptedFileItems}</ul>
            </>
          )}
          {fileRejections.length > 0 && (
            <>
              <h4>Rejected files</h4>
              <ul>{fileRejectionItems}</ul>
            </>
          )}

          <Button
            disabled={pending}
            onClick={() => {
              setPending(true);
              toast.loading("이미지 업로드 중입니다", {
                toastId: "imageUpload",
              });
              if (files.length === 0) {
                toast.update("imageUpload", {
                  render: "이미지를 업로드 해주세요",
                  isLoading: false,
                  type: "error",
                  hideProgressBar: true,
                });
              } else {
                modifyPlaceThumbnail(place.uuid, files[0]);
              }
              setTimeout(() => {
                toast.dismiss("imageUpload");
                setPending(false);
              }, 1000);
            }}
            sx={{
              marginTop: "20px",
            }}
          >
            {pending ? (
              <CircularProgress size={"20px"} sx={{ color: "#fff" }} />
            ) : (
              <>
                <MuiIcon name="upload" sx={{ marginRight: "10px" }} />
                이미지 업로드
              </>
            )}
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
});

export default PlaceThumbnailModal;
