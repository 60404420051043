import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Grid, IconButton } from "@mui/material";
import { KakaoLoginButton, NaverLoginButton } from "../../../components/SocialLoginButtons/SocialLoginButton";
import { useRootStore } from "../../../contexts/RootContext";
import { observer } from "mobx-react-lite";
import { isEnterPressed } from "../../../utils/KeyboarEvents";
import CircularProgress from "@mui/material/CircularProgress";
import MuiIcon from "../../../components/common/MuiIcon";
import "./index.css";
import MssIcon from "../../../components/common/MssIcon";
import Button from "../../../components/common/Button";

const SignIn = observer(() => {
  const [showPwd, setShowPwd] = useState(true);
  const [pending, setPending] = useState(false);
  const { signinStore, authStore } = useRootStore();
  const { id, password, setId, setPassword } = signinStore;
  const navigate = useNavigate();
  const from = "/";

  const onLogin = () => {
    setPending(true);
    setTimeout(() => {
      authStore.signIn(
        id,
        password,
        () => setPending(false),
        () => navigate(from, { replace: true })
      );
    }, 500);
  };

  return (
    <Container maxWidth={false} style={{ padding: 0, backgroundColor: "#081035" }}>
      <Grid
        container
        style={{ height: "100vh", padding: "0 1.5rem" }}
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        columnSpacing={{ xs: 0, md: 6 }}
      >
        <Grid item justifyContent="center">
          <MssIcon name="logo" height={"93px"} width={"124px"} />
        </Grid>

        <Grid item className="field-width" marginTop={"106px"}>
          <input type="text" value={id} onChange={setId} placeholder={"아이디 입력"} className={"signin-input"} />
        </Grid>
        <Grid item display={"flex"} alignItems={"center"} className="field-width" position={"relative"}>
          <input
            type={showPwd ? "password" : "text"}
            value={password}
            onChange={setPassword}
            placeholder={"비밀번호 입력"}
            onKeyPress={(e) => {
              isEnterPressed(e) && onLogin();
            }}
            className={"signin-input"}
          />
          <IconButton style={{ position: "absolute", right: "10px", top: "17px" }} onClick={() => setShowPwd(!showPwd)}>
            {showPwd ? (
              <MuiIcon name="visibile-on" sx={{ fontSize: "25" }} />
            ) : (
              <MuiIcon name="visibility-off" sx={{ fontSize: "25" }} />
            )}
          </IconButton>
        </Grid>

        <Grid item className="field-width" marginTop="10px">
          <Button
            disabled={pending}
            style={{ height: "50px", fontWeight: "bold", fontSize: "24px", borderRadius: "5px" }}
            fullWidth
            onClick={onLogin}
          >
            {pending ? <CircularProgress sx={{ color: "#fff", height: "25px" }} size={20} /> : "로그인"}
          </Button>
        </Grid>
        <Grid item className="field-width">
          <hr style={{ width: "-webkit-fill-available", backgroundColor: "#3a4a61", margin: "10px 0" }} />
          <NaverLoginButton />
        </Grid>
        <Grid item className="field-width">
          <KakaoLoginButton />
        </Grid>

        <Grid item justifyContent="center">
          <p style={{ fontSize: "28px", color: "#403A59", marginTop: "78px", fontFamily: '"Poppins"  sans-serif' }}>
            Realstep in your step
          </p>
        </Grid>
      </Grid>
    </Container>
  );
});

export default SignIn;
