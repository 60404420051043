import { Instance, types } from "mobx-state-tree";
import moment from "moment";
import api from "../../../api/api";

const DoorDashboardModel = types.model({
  open_count: types.optional(types.number, 0),
  first_open: types.optional(types.string, moment().format()),
  recent_open: types.optional(types.string, moment().format()),
});

const DoorTableModel = types.model({
  id: types.number,
  created_at: types.string,
  serial_number: types.string,
  value: types.number,
  alive: types.number,
  battery: types.number,
  rssi: types.number,
});

const DoorSectionOne = types
  .model({
    startDate: types.maybeNull(types.string),
    endDate: types.maybeNull(types.string),
    dashboard: types.optional(DoorDashboardModel, {} as DoorDashboardModlType),
    tableData: types.frozen<Array<DoorTableModelType>>(),
    chartData: types.frozen(),
  })
  .actions((self) => ({
    setStartDate(startDate: string | null) {
      self.startDate = startDate;
    },
    setEndDate(endDate: string) {
      self.endDate = endDate;
    },
    setDashboard(dashboard: DoorDashboardModlType) {
      self.dashboard = dashboard;
    },
    setTable(tableData: Array<DoorTableModelType>) {
      self.tableData = tableData;
    },
    setChart(chartData: any) {
      self.chartData = [{ data: chartData, name: "문열림 횟수" }];
    },
  }))
  .actions((self) => ({
    fetchDashboard: async (sensorId: string) => {
      const res = await api.get<DoorDashboardModlType>(`/v1/sensor/door/${sensorId}/dashboard`, {
        start_date: self.startDate,
        end_date: self.endDate,
      });

      if (!res.ok || !res.data) return;
      self.setDashboard(res.data);
    },
    fetchTable: async (sensorId: string) => {
      const res = await api.get<{ table: Array<DoorTableModelType>; chart: any }>(`/v1/sensor/door/${sensorId}/table`, {
        start_date: self.startDate,
        end_date: self.endDate,
      });

      if (!res.ok || !res.data) return;
      self.setTable(res.data.table);
      self.setChart(res.data.chart);
    },

    init(sensorId: string) {
      this.fetchDashboard(sensorId);
      this.fetchTable(sensorId);
    },
  }));

type DoorTableModelType = Instance<typeof DoorTableModel>;
type DoorDashboardModlType = Instance<typeof DoorDashboardModel>;
export type DoorSectionOneType = Instance<typeof DoorSectionOne>;
export default DoorSectionOne;
