import { Dialog, DialogContent, Divider, Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useRootStore } from "../../contexts/RootContext";
import Button from "../common/Button";
import { StyleSheet } from "../dashboard/sensor/overview/SpotDataInDetail";

type Props = {
  open: boolean;
  onClose: () => void;
};

const PasswordModifyModal = observer(({ open, onClose }: Props) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [valid, setValid] = useState(false);
  const [equal, setEqual] = useState<boolean | undefined>(false);
  const { myPageStore } = useRootStore();

  useEffect(() => {
    if (currentPassword && equal) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [currentPassword, equal]);

  useEffect(() => {
    if (newPassword && confirmNewPassword) {
      newPassword === confirmNewPassword ? setEqual(true) : setEqual(false);
    } else setEqual(undefined);
  }, [newPassword, confirmNewPassword]);

  useEffect(() => {
    setCurrentPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent sx={{ p: "40px" }}>
        <Grid container>
          <span style={styles.title}>비밀빈호 변경</span>
        </Grid>

        <Grid container mt={2}>
          <span>안전한 비밀번호로 내 정보를 보호하세요</span>
          <ul>
            <li>다른 아이디/사이트에서 사용한 적 없는 비밀번호</li>
            <li>이전에 사용한 적 없는 비밀번호가 안전합니다</li>
          </ul>
        </Grid>
        <Divider />
        <Grid container mt={5} alignItems={"center"}>
          <Grid item md={3} xs={12}>
            <span className="input-label">현재 비밀번호</span>
          </Grid>
          <Grid item md={9} xs={12}>
            <input
              value={currentPassword}
              type="password"
              onChange={(e) => setCurrentPassword(e.target.value)}
              className={currentPassword ? "input valid" : "input"}
            />
          </Grid>
        </Grid>
        <Grid container mt={1} alignItems={"center"}>
          <Grid item md={3} xs={12}>
            <span className="input-label">비밀번호</span>
          </Grid>
          <Grid item md={9} xs={12}>
            <input
              value={newPassword}
              type="password"
              onChange={(e) => setNewPassword(e.target.value)}
              className={newPassword ? "input valid" : "input"}
            />
          </Grid>
        </Grid>
        <Grid container mt={1} alignItems={"center"}>
          <Grid item md={3} xs={12}>
            <span className="input-label">확인 비밀번호</span>
          </Grid>
          <Grid item md={9} xs={12}>
            <input
              value={confirmNewPassword}
              type="password"
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              className={equal === undefined ? "input" : equal ? "input valid" : "input invalid"}
            />
          </Grid>
        </Grid>

        <Grid container justifyContent={"center"} mt={5} columnSpacing={2}>
          <Grid item>
            <Button btnType="secondary" onClick={onClose}>
              취소
            </Button>
          </Grid>
          <Grid item>
            <Button
              tooltipLabel={valid ? "비밀번호를 변경해주세요" : "필수 항목들을 적어주세요"}
              btnType={valid ? "default" : "disabled"}
              onClick={() => valid && myPageStore.member.changePassword(currentPassword, newPassword, onClose)}
            >
              수정
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
});

const styles = StyleSheet.create({
  title: {
    fontSize: "25px",
    color: "#333",
  },
});

export default PasswordModifyModal;
