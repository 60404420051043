import { Instance, types } from "mobx-state-tree";
import AiGateModel, { AigateModelType } from "./ai-gate/AiGateModel";
import SensorDoorModel, { SensorDoorModelType } from "./door/SensorDoorModel";
import SensorEmergencyModel, { SensorEmergencyModelType } from "./emergency/SensorEmergencyModel";
import SensorFireDetectionModel, { SensorFireDetectionModelType } from "./fire-detection/SensorFireDetectionModel";
import GateModel, { GateModelType } from "./gate/GateModel";
import SensorSpotModel, { SensorSpotModelType } from "./spot/SensorSpotModel";
import SensorTempAndHumidityModel, { SensorTempAndHumidityModelType } from "./temp-humidity/SensorTempAndHumidityModel";

const DetailedBaseModel = types.model({
  gate: types.optional(GateModel, {} as GateModelType),
  aiGate: types.optional(AiGateModel, {} as AigateModelType),
  spot: types.optional(SensorSpotModel, {} as SensorSpotModelType),
  door: types.optional(SensorDoorModel, {} as SensorDoorModelType),
  fireDetection: types.optional(SensorFireDetectionModel, {} as SensorFireDetectionModelType),
  tempAndHumidity: types.optional(SensorTempAndHumidityModel, {} as SensorTempAndHumidityModelType),
  emergency: types.optional(SensorEmergencyModel, {} as SensorEmergencyModelType),
});

export const DetailedDataMasterModel = types.compose(DetailedBaseModel, types.model({}));
export const DetailedDataSlaveModel = types.compose(DetailedBaseModel, types.model({}));

const DetailedDataModel = types
  .model({
    master: types.optional(DetailedDataMasterModel, {} as DetailedDataMasterModelType),
    slave: types.optional(DetailedDataSlaveModel, {} as DetailedDataSlaceModelType),
    showMaster: types.optional(types.boolean, false),
    showSlave: types.optional(types.boolean, false),
    selectedMaster: types.optional(types.string, ""),
    selectedSlave: types.optional(types.string, ""),
  })
  .actions((self) => ({
    setShowMaster(showMaster: boolean) {
      self.showMaster = showMaster;
    },
    setShowSlave(showSlave: boolean) {
      self.showSlave = showSlave;
    },
    setSelectedMaster(selectedMaster: string) {
      self.selectedMaster = selectedMaster;
    },
    setSelectedSlave(selectedSlave: string) {
      self.selectedSlave = selectedSlave;
    },
  }));
type DetailedDataMasterModelType = Instance<typeof DetailedDataMasterModel>;
type DetailedDataSlaceModelType = Instance<typeof DetailedDataSlaveModel>;
export type DetailedDataModelType = Instance<typeof DetailedDataModel>;
export default DetailedDataModel;
