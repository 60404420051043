import { createContext, ReactNode, useContext } from "react";
import PlaceModel, { PlaceModelType } from "../models/main/PlaceModel";

const PlaceContext = createContext<PlaceModelType>({} as PlaceModelType);

const placeStore = PlaceModel.create();

type Props = {
  children: ReactNode;
};

export const PlaceStoreProvider = ({ children }: Props) => {
  return <PlaceContext.Provider value={placeStore}>{children}</PlaceContext.Provider>;
};
export const usePlaceStore = () => useContext(PlaceContext);
