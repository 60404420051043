import { Box, Collapse, Dialog, Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useSensorRegisterStore } from "../../../contexts/SensorRegisterContext";
import CreatableSelect from "react-select/creatable";
import { SensorLocationModelType } from "../../../models/sensor-register/SensorLocationModel";
import { Document } from "../../../../types/kakao";
import { SingleValue, StylesConfig } from "react-select";
import LocationMap from "../../sensor-register/LocationMap";
import { usePlaceStore } from "../../../contexts/PlaceContext";
import Button from "../../common/Button";

const customStyles: StylesConfig<Document, false> = {
  control: () => ({
    border: "none",
    display: "flex",
    alignItems: "center",
    padding: "0 5px",
    outline: "1px solid #d7d7d7",
    height: "40px",
    borderRadius: "4px",
    "&:hover": {
      outlineColor: "#352d59",
    },
  }),

  container: (container) => ({
    ...container,
    zIndex: 15,
  }),

  indicatorsContainer: () => ({
    display: "none",
  }),
};

const NewSelect = observer(({ location }: { location: SensorLocationModelType }) => {
  return (
    <CreatableSelect
      styles={customStyles}
      onInputChange={(query) => {
        if (query !== undefined || query !== null) {
          location.setQuery(query);
          location.fetchAddresses();
        }
      }}
      inputValue={location.query}
      onChange={(e: SingleValue<Document>) => {
        e !== null && e.x && location.setSelected(e);
      }}
      value={location.selected}
      isSearchable
      className="location-select"
      placeholder="도로명 주소"
      options={location.addressList}
      getOptionValue={(option: Document) => option.address_name}
      getOptionLabel={(option: Document) => option.address_name}
    />
  );
});

const PlaceRegisterModal = observer(() => {
  const { location, registerPlace, reset } = useSensorRegisterStore();
  const { fetchPlaces, setSelected } = usePlaceStore();

  return (
    <Dialog
      open={location.registerModal}
      maxWidth={false}
      onClose={() => {
        location.setRegisterModal(false);
      }}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          padding: "50px",
          width: "550px",
        }}
      >
        <Grid container flexDirection={"column"}>
          <span className="title">장소를 등록해 주세요</span>
          <span className="sub-title">설치할 장소 또는 설치된 장소의 주소를 입력해주세요</span>
        </Grid>
        <Grid container display={"flex"} marginTop={"30px"}>
          <Grid container>
            <Grid item container>
              <input
                className="location-input"
                placeholder="장소명"
                value={location.buildingName}
                onChange={(e) => {
                  location.setBuildingName(e.target.value);
                }}
              />
            </Grid>
            <Grid item md={12} mt={1}>
              <NewSelect location={location} />
            </Grid>
          </Grid>

          <Grid container marginTop={"7px"}>
            <LocationMap tip height="100%" width="300px" />
          </Grid>
        </Grid>

        <Collapse in={location.selected ? true : false}>
          <Grid item container alignItems={"center"} mt={1}>
            <Grid item container>
              <input
                className="location-input"
                placeholder="상세 주소 입력"
                value={location.placeName}
                onChange={(e) => {
                  location.setPlaceName(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </Collapse>

        <Grid container justifyContent={"center"} mt={3}>
          <Button
            tooltipLabel={
              location.selected && location.placeName && location.buildingName ? "다음" : "모든 항목을 입력해주세요"
            }
            btnType={location.selected && location.placeName && location.buildingName ? "default" : "disabled"}
            onClick={() =>
              location.selected &&
              location.placeName &&
              location.buildingName &&
              registerPlace((uuid) => {
                fetchPlaces();
                setSelected(uuid);
                reset("location");
              })
            }
          >
            장소를 등록하기
          </Button>
        </Grid>
      </Box>
    </Dialog>
  );
});

export default PlaceRegisterModal;
