import { cast, Instance, types } from "mobx-state-tree";
import { GATE_COLUMN_SERIES } from "../../../components/dashboard/sensor/more-detailed/GateMoreDetailed/GateConstants";

export const PeriodModel = types.model({
  label: types.string,
  value: types.number,
});

const GateSectionTwo = types
  .model({
    heatmapData: types.array(types.frozen()),
    startDate: types.maybeNull(types.string),
    endDate: types.maybeNull(types.string),
  })
  .actions((self) => ({
    setLineData(heatmapData: Array<any>) {
      self.heatmapData = cast(heatmapData);
    },
    setStartDate(startDate: string | null) {
      self.startDate = startDate;
    },
    setEndDate(endDate: string | null) {
      self.endDate = endDate;
    },
  }))
  .actions((self) => ({
    fetchLineData: async () => {
      self.setLineData(GATE_COLUMN_SERIES);
    },
  }))
  .actions((self) => ({
    init(sensorId: string) {
      self.fetchLineData();
    },
  }));

export type PeriodModelType = Instance<typeof PeriodModel>;
export type GateSectionTwoType = Instance<typeof GateSectionTwo>;
export default GateSectionTwo;
