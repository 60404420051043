import { Avatar, Box, Button, Grid, ListItemIcon, Menu, MenuItem } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRootStore } from "../../contexts/RootContext";
import News from "./News";
import MuiIcon from "./MuiIcon";
import MssIcon from "./MssIcon";

const Header = observer(() => {
  const { authStore } = useRootStore();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  return (
    <Grid
      container
      justifyContent={{ xs: "center", md: "space-between" }}
      height="70px"
      alignItems="center"
      borderBottom="0.5px solid #D7D7D7"
      sx={{
        position: "fixed",
        backgroundColor: { xs: "#160a4c", md: "#fff" },
        top: 0,
        paddingLeft: { xs: "0", md: "70px" },
        zIndex: 21,
      }}
    >
      <Grid item alignItems="center" paddingX={"38px"} display={{ xs: "none", md: "flex" }}>
        <span style={{ color: "#333333", fontWeight: "bold", fontSize: "26px" }}>대시보드</span>
      </Grid>
      <Grid item display={{ xs: "flex", md: "none" }}>
        <MssIcon name="logo" />
      </Grid>
      <Grid
        item
        flex={1}
        display={{ md: "flex", xs: "none" }}
        sx={{ alignItems: "center", justifyContent: "flex-end", overflowY: "hidden" }}
      >
        <News />
        <Button
          onClick={handleClick}
          size="small"
          sx={{ ml: 2, justifyContent: "flex-start", borderRadius: "40px" }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>{" "}
          <span
            style={{
              color: "#333",
              fontWeight: "bold",
              marginLeft: "9px",
              fontSize: "12px",
              marginRight: "20px",
              minWidth: "120px",
              textAlign: "start",
            }}
          >
            {authStore.user.username}
          </span>
        </Button>
      </Grid>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            borderRadius: "10px",
            paddingX: "12px",
            paddingBottom: "16px",

            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => {
            navigate("my-page");
          }}
          sx={{ backgroundColor: "#F7F7FC", padding: "10px 13px", borderRadius: "5px", marginTop: "16px" }}
        >
          <ListItemIcon>
            <MuiIcon name="account-circle-outlined" fontSize="small" />
          </ListItemIcon>
          마이 페이지
        </MenuItem>

        <MenuItem
          sx={{ backgroundColor: "#F7F7FC", padding: "10px 13px", borderRadius: "5px", marginTop: "16px" }}
          onClick={() => {
            authStore.signOut(() => navigate(""));
          }}
        >
          <ListItemIcon>
            <MuiIcon name="logout" fontSize="small" />
          </ListItemIcon>
          로그아웃
        </MenuItem>
      </Menu>
    </Grid>
  );
});

export default Header;
