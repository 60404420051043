import { Instance, types } from "mobx-state-tree";
import api from "../../../api/api";

const TempAndHumiditySectionTwo = types
  .model({
    startDate: types.maybeNull(types.string),
    endDate: types.maybeNull(types.string),
    data: types.frozen(),
  })
  .actions((self) => ({
    setStartDate(startDate: string | null) {
      self.startDate = startDate;
    },
    setEndDate(endDate: string | null) {
      self.endDate = endDate;
    },
    setData(data: typeof self.data) {
      self.data = data;
    },
  }))
  .actions((self) => ({
    fetchData: async (sensorId: string) => {
      const res = await api.get(`/v1/sensor/temp-humidity/${sensorId}/heatmap`, {
        start_date: self.startDate,
        end_date: self.endDate,
      });
      if (!res.ok || !res.data) return;
      self.setData(res.data);
    },
    init(sensorId: string) {
      this.fetchData(sensorId);
    },
  }));

export type TempAndHumiditySectionTwoType = Instance<typeof TempAndHumiditySectionTwo>;

export default TempAndHumiditySectionTwo;
