import { cast, Instance, types } from "mobx-state-tree";
import {
  GATE_COLUMN_SERIES,
  GATE_LINE_PERIOD,
} from "../../../components/dashboard/sensor/more-detailed/GateMoreDetailed/GateConstants";

export const PeriodModel = types.model({
  label: types.string,
  value: types.number,
});

const GateSectionOne = types
  .model({
    lineData: types.array(types.frozen()),
    periods: types.array(PeriodModel),
    selectedPeriod: types.optional(types.number, 1),
  })
  .actions((self) => ({
    setLineData(lineData: Array<any>) {
      self.lineData = cast(lineData);
    },
    setPeriods(periods: Array<PeriodModelType>) {
      self.periods = cast(periods);
    },
    setSelectedPeriod(selectedPeriod: number) {
      self.selectedPeriod = selectedPeriod;
    },
  }))
  .actions((self) => ({
    fetchLineData: async () => {
      self.setLineData(GATE_COLUMN_SERIES);
    },
    fetchPeriods: async () => {
      self.setPeriods(GATE_LINE_PERIOD);
    },
  }))
  .actions((self) => ({
    init(sensorId: string) {
      self.fetchLineData();
      self.fetchPeriods();
    },
  }));

export type PeriodModelType = Instance<typeof PeriodModel>;
export type GateSectionOneType = Instance<typeof GateSectionOne>;
export default GateSectionOne;
