import MssIcon from "../../common/MssIcon";

const SensorNetworkIcon = ({ percentage }: { percentage: number }) => {
  switch (true) {
    case percentage > 76:
      return <MssIcon name="network-1" />;
    case percentage > 51 && percentage <= 76:
      return <MssIcon name="network-2" />;
    case percentage > 25 && percentage <= 51:
      return <MssIcon name="network-3" />;
    case percentage > 0 && percentage <= 25:
      return <MssIcon name="network-4" />;
    default:
      return <MssIcon name="network-5" />;
  }
};

export default SensorNetworkIcon;
