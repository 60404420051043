import { Box, Grid } from "@mui/material";

type Props = {
  step: number;
  steps: Array<{ label: string }>;
};

const Stepper = ({ step, steps }: Props) => {
  return (
    <Box display="flex" flexDirection={"column"} marginTop="30px" width={"250px"}>
      {step > 0 &&
        steps.map((s, i) => (
          <Grid key={i} item display={"flex"} alignItems="center" height={"50px"} position="relative">
            {i < steps.length - 1 && (
              <div
                style={{
                  position: "absolute",
                  height: "100%",
                  top: "50%",
                  borderLeft: "1px solid #ABAABA",
                  left: "10px",
                }}
              ></div>
            )}
            <div
              style={{
                zIndex: 1,
                width: "20px",
                height: "20px",
                borderRadius: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: `inset 0px 0px 0 ${step - 1 < i ? "1px" : "5px"} ${step - 1 < i ? "#ABAABA" : "#50cdd0"}`,
                fontSize: "12px",
                fontWeight: "bold",
                color: "#ABAABA",
                backgroundColor: "#fff",
              }}
            >
              {step - 1 < i && i}
            </div>
            <span
              style={{
                fontSize: "18px",
                color: step - 1 < i ? "#ABAABA" : "#50CDD0",
                fontWeight: "bold",
                marginLeft: "8px",
              }}
            >
              {s.label}
            </span>
          </Grid>
        ))}
    </Box>
  );
};

export default Stepper;
