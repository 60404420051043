import { observer } from "mobx-react-lite";
import { BrowserRouter, Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import { cssTransition, ToastContainer } from "react-toastify";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import CompanyEdit from "../components/my-page/CompanyEdit";
import ProfileEdit from "../components/my-page/ProfileEdit";
import { DetailedDataStoreProvider } from "../contexts/DetailedDataContext";
import { PlaceStoreProvider } from "../contexts/PlaceContext";
import { useRootStore } from "../contexts/RootContext";
import { SensorRegisterStoreProvider } from "../contexts/SensorRegisterContext";
import { UserSensorListStoreProvider } from "../contexts/UserSensorContext";
import SignIn from "../screens/authorization/SignIn/SignIn";
import SignUp from "../screens/authorization/Signup/Signup";
import NotFoundScreen from "../screens/common/NotFoundScreen/NotFoundScreen";
import Playground from "../screens/common/NotFoundScreen/playground/Playground";
import DashboardScreen from "../screens/main/DashboardScreen/DashboardScreen";
import MyPageScreen from "../screens/main/MyPageScreen/MyPageScreen";
import SensorRegisterScreen from "../screens/main/sensor-register/SensorRegisterScreen";
import SensorRegisterSelect from "../screens/main/sensor-register/SensorRegisterSelect";
import SensorRegiterSteps from "../screens/main/sensor-register/SensorRegisterSteps";
import { JWTUtils } from "../utils/JWTUtils";

const REDIRECT_SIGN_IN = "/signin";

const RootRouter = observer(() => {
  const { authStore } = useRootStore();
  authStore.init();

  return (
    <BrowserRouter>
      <ToastContainer
        autoClose={1000}
        position="top-center"
        transition={cssTransition({
          enter: "animate__animated animate__fadeIn",
          exit: " animate__animated animate__fadeOut",
          collapse: true,
          collapseDuration: 300,
        })}
      />
      <Routes>
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route
          path=""
          element={
            <RequireAuth>
              <PlaceStoreProvider>
                <DetailedDataStoreProvider>
                  <SensorRegisterStoreProvider>
                    <UserSensorListStoreProvider>
                      <Main />
                    </UserSensorListStoreProvider>
                  </SensorRegisterStoreProvider>
                </DetailedDataStoreProvider>
              </PlaceStoreProvider>
            </RequireAuth>
          }
        >
          <Route index element={<DashboardScreen />} />
          <Route path="sensor-register" element={<SensorRegisterScreen />}>
            <Route index element={<SensorRegisterSelect />} />
            <Route path="steps" element={<SensorRegiterSteps />} />
          </Route>
          <Route path="my-page" element={<MyPageScreen />}>
            <Route index element={<ProfileEdit />} />
            <Route path="company-edit" element={<CompanyEdit />} />
          </Route>
        </Route>
        <Route path="/playground" element={<Playground />} />
        <Route path="*" element={<NotFoundScreen />} />
      </Routes>
    </BrowserRouter>
  );
});

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const authenticated = JWTUtils.isAuthenticated();

  if (!authenticated) {
    return <Navigate to={REDIRECT_SIGN_IN} state={{ from: location }} replace />;
  }

  return children;
};

const Main = () => {
  return (
    <>
      <Header />
      <SideBar />
      <Outlet />
    </>
  );
};

export default RootRouter;
