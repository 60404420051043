import Grid from "@mui/material/Grid";
import { StyleSheet } from "./SpotDataInDetail";
import { Bar, BarDatum, BarSvgProps, ComputedBarDatum } from "@nivo/bar";
import { memo, useEffect, useState } from "react";
import api from "../../../../api/api";
import { SensorOverviewProps } from "./InAndOutDataInDetail";
import { TempAndHumidityOverview } from "../../../../../types/sensor";
import CountUp from "react-countup";

const styles = StyleSheet.create({
  box: {
    alignItems: "center",
    height: "28px",
    justifyContent: "space-between",
    marginTop: "10px",
  },
  key: {
    color: "#565656",
    fontSize: "12px",
  },
  value: {
    color: "#333333",
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "end",
    lineHeight: 0.7,
  },
  subValue: {
    color: "#ABAABA",
    fontSize: "10px",
    fontWeight: "normal",
  },
});

const CustomBarComponent = ({ bar: { x, y, width, height, color } }: { bar: ComputedBarDatum<BarDatum> }) =>
  width > 0 && height > 0 ? (
    <rect x={x} y={y} rx="5" ry="5" width={width / 4} height={height} fill={color} />
  ) : (
    <rect x={x} y={y} rx="5" ry="5" width={0} height={0} fill={color} />
  );

const TempAndHumidityInDetail = memo(({ sensorId, setRssi, setBattery }: SensorOverviewProps) => {
  const [data, setData] = useState<TempAndHumidityOverview | undefined>();

  const fetchData = async () => {
    const res = await api.get<TempAndHumidityOverview>(`/v1/sensor/temp-humidity/${sensorId}/overview`);
    if (!res.ok || !res.data) return;
    setData(res.data);
    setRssi && setRssi(res.data.data.rssi);
    setBattery && setBattery(res.data.data.battery);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const commonProps: BarSvgProps<BarDatum> = {
    width: 270,
    height: 120,
    margin: { left: 30 },
    data: data ? data.chart : [],
    indexBy: "time",
    enableGridY: true,
    enableLabel: true,
    axisLeft: null,
    isInteractive: true,
    keys: ["temp", "humid"],
    // axisBottom: false,
    colors: ["#7D8EE7", "#6AE1E3"],
    tooltipLabel: "A",
  };
  return (
    <Grid container marginTop={"7px"}>
      <Grid item container flex={1} flexDirection="column">
        <Grid item container style={styles.box}>
          <span style={styles.key}>현재 실내 온습도</span>
          <span style={styles.value}>
            <CountUp end={data ? data.data.current_temp : 0} />℃
          </span>
          <span style={styles.value}>
            <CountUp end={data ? data.data.current_humid : 0} />%{" "}
          </span>
        </Grid>
        <Grid item container style={styles.box}>
          <span style={styles.key}>금일 최고 온습도</span>
          <span style={styles.value}>
            <CountUp end={data ? data.data.highest_temp : 0} />℃
          </span>
          <span style={styles.value}>
            <CountUp end={data ? data.data.highest_humid : 0} />%
          </span>
        </Grid>
        <Grid item container style={styles.box}>
          <span style={styles.key}>금일 최저 온습도 </span>
          <span style={styles.value}>
            <CountUp end={data ? data.data.lowest_temp : 0} />℃
          </span>
          <span style={styles.value}>
            <CountUp end={data ? data.data.lowest_humid : 0} />%
          </span>
        </Grid>
      </Grid>

      <Grid item container width={"318px"} paddingLeft="25px">
        <Bar {...commonProps} innerPadding={4} barComponent={CustomBarComponent} />
      </Grid>
    </Grid>
  );
});

export default TempAndHumidityInDetail;
