import { cast, Instance, types } from "mobx-state-tree";
import { toast } from "react-toastify";
import api from "../../api/api";

const PlaceListModel = types
  .model({
    uuid: types.string,
    created_at: types.number,
    updated_at: types.number,
    user_uuid: types.string,
    id: types.number,
    name: types.string,
    latitude: types.number,
    longitude: types.number,
    address: types.string,
    address_detail: types.string,
    extra_1: types.string,
    extra_2: types.string,
    extra_3: types.string,
    extra_4: types.string,
    thumbnail_url: types.optional(types.string, ""),
  })
  .actions((self) => ({
    setThumbnailUrl(thumbnail_url: string) {
      self.thumbnail_url = thumbnail_url;
    },
  }));

const PlaceModel = types
  .model({
    list: types.array(PlaceListModel),
    selected: types.optional(types.string, ""),
  })
  .views((self) => ({
    get selectedPlace() {
      return self.list.find((place) => place.uuid === self.selected);
    },
    getPlace(uuid: string) {
      return self.list.find((place) => place.uuid === uuid);
    },
  }))
  .actions((self) => ({
    setPlaceList(places: Array<PlaceListType>) {
      self.list = cast(places);
    },
    setSelected(selected: string) {
      self.selected = selected;
    },
  }))
  .actions((self) => ({
    fetchPlaces: async () => {
      const res = await api.get<{ cursor: any; places: Array<PlaceListType> }>("/v1/place");
      if (!res.ok || !res.data) return res.originalError;
      const places = res.data.places;
      self.setPlaceList(places);
      self.setSelected(places[0].uuid);
    },
    modifyPlaceThumbnail: async (uuid: string, file: File) => {
      const place = self.getPlace(uuid);

      const formData = new FormData();
      formData.append("media", file);
      api.put<{ result: { Location: string } }>(`/v1/place/${uuid}/thumbnail`, formData).then((r) => {
        if (!r.ok || !r.data)
          return toast.update("imageUpload", {
            render: "서버 문제, 다시 시도 해주세요",
            isLoading: false,
            type: "error",
            hideProgressBar: true,
          });
        place?.setThumbnailUrl(r.data.result.Location);
        toast.update("imageUpload", {
          render: "설치 장소 이미지가 수정되었습니다",
          isLoading: false,
          type: "success",
          hideProgressBar: true,
        });
      });
    },
  }));

export type PlaceListType = Instance<typeof PlaceListModel>;

export type PlaceModelType = Instance<typeof PlaceModel>;
export default PlaceModel;
