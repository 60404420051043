import { Instance, types } from "mobx-state-tree";
import SpotSectionOne, { SpotSectionOneType } from "./SpotSectionOne";
import SpotSectionTwo, { SpotSectionTwoType } from "./SpotSectionTwo";

const SensorSpotModel = types
  .model({
    sectionOne: types.optional(SpotSectionOne, {} as SpotSectionOneType),
    sectionTwo: types.optional(SpotSectionTwo, {} as SpotSectionTwoType),
  })
  .actions((self) => ({
    refresh(sensorId: string) {
      self.sectionOne.init(sensorId);
      self.sectionTwo.init(sensorId);
    },
  }));

export type SensorSpotModelType = Instance<typeof SensorSpotModel>;
export default SensorSpotModel;
