import { GridColDef } from "@mui/x-data-grid";
import { ApexOptions } from "apexcharts";

export const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "No",
    align: "center",
    headerAlign: "center",
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
  },
  {
    field: "created_at",
    headerName: "날짜(시간)",
    sortable: true,
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    flex: 2,
  },
  {
    field: "temper",
    headerName: "실내",
    sortable: false,
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    valueGetter: (obj) => `${obj.row.temper}℃/${obj.row.humid}%`,
    flex: 2,
  },
  {
    field: "battery",
    headerName: "배터리",
    sortable: false,
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    valueFormatter: (obj) => `${obj.value}%`,
    flex: 1,
  },
  {
    field: "value",
    headerName: "신호세기",
    sortable: false,
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    flex: 1,
  },
];

export const DOOR_TABLE_DATA = [
  {
    id: 2,
    created_at: "2022-04-05 12:00:00",
    place: "큰회의실",
    outside: "23℃/33%",
    inside: "23℃/33%",
    battery: "77%",
    signal_strength: 123,
    daily_avarage: "23℃/33%",
  },
  {
    id: 3,
    created_at: "2022-04-05 12:00:00",
    place: "큰회의실",
    outside: "23℃/33%",
    inside: "23℃/33%",
    battery: "77%",
    signal_strength: 123,
    daily_avarage: "23℃/33%",
  },
  {
    id: 4,
    created_at: "2022-04-05 12:00:00",
    place: "큰회의실",
    outside: "23℃/33%",
    inside: "23℃/33%",
    battery: "77%",
    signal_strength: 123,
    daily_avarage: "23℃/33%",
  },
  {
    id: 5,
    created_at: "2022-04-05 12:00:00",
    place: "큰회의실",
    outside: "23℃/33%",
    inside: "23℃/33%",
    battery: "77%",
    signal_strength: 123,
    daily_avarage: "23℃/33%",
  },
  {
    id: 6,
    created_at: "2022-04-05 12:00:00",
    place: "큰회의실",
    outside: "23℃/33%",
    inside: "23℃/33%",
    battery: "77%",
    signal_strength: 123,
    daily_avarage: "23℃/33%",
  },
  {
    id: 7,
    created_at: "2022-04-05 12:00:00",
    place: "큰회의실",
    outside: "23℃/33%",
    inside: "23℃/33%",
    battery: "77%",
    signal_strength: 123,
    daily_avarage: "23℃/33%",
  },
];

export const DOOR_SECTION_TWO_COLUMN_OPTIONS: ApexOptions = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "55%",
    },
  },
  dataLabels: {
    enabled: false,
  },
  colors: ["#352D59", "#6AE1E3", "#6852CF", "#5B8FF9"],
  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"],
  },

  fill: {
    opacity: 1,
  },
  tooltip: {
    y: {
      formatter: function (val) {
        return "$ " + val + " thousands";
      },
    },
  },
};

export const HEATMAP_CHART_OPTIONS: ApexOptions = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    heatmap: {
      shadeIntensity: 1,
      enableShades: false,
      radius: 5,
      useFillColorAsStroke: false,
      colorScale: {
        ranges: [
          {
            from: 0,
            to: 9,
            name: "cold",
            color: "#DBEBF1",
          },
          {
            from: 9,
            to: 16,
            name: "cool",
            color: "#B8F1F2",
          },
          {
            from: 16,
            to: 26,
            name: "normal",
            color: "#6AE1E3",
          },
          {
            from: 26,
            to: 32,
            name: "hot",
            color: "#352D59",
          },
        ],
      },
    },
  },
  legend: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: 1,
  },
  xaxis: {
    labels: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
  },
};

export const COLUMN_OPTIONS: ApexOptions = {
  chart: {
    height: 350,
    type: "bar",
    zoom: {
      enabled: false,
    },
    stacked: false,
    toolbar: {
      show: false,
    },
  },
  dataLabels: { enabled: false },
  plotOptions: {
    bar: {
      columnWidth: "90%",
    },
  },
  stroke: { width: [1, 0], curve: "smooth" },
  grid: {
    yaxis: {
      lines: {
        show: false,
      },
    },
  },
  colors: ["#0085FF", "#D9D3F3"],
  xaxis: {
    labels: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    labels: {
      show: false,
    },
  },
};

export const BAR_KEYS = ["A", "B"];

export const BAR_DATA = [
  {
    country: "AD",
    A: 300,
    B: -200,
  },
  {
    country: "AE",
    A: 250,
    B: -118,
  },
  {
    country: "AF",
    A: 200,
    B: -50,
  },
  {
    country: "AG",
    A: 140,
    B: -185,
  },
  {
    country: "AI",
    A: 165,
    B: -109,
  },
  {
    country: "AL",
    A: 210,
    B: -290,
  },
  {
    country: "AD1",
    A: 300,
    B: -200,
  },
  {
    country: "AE1",
    A: 250,
    B: -118,
  },
  {
    country: "AF1",
    A: 200,
    B: -50,
  },
  {
    country: "AG1",
    A: 140,
    B: -185,
  },
  {
    country: "AI1",
    A: 165,
    B: -109,
  },
  {
    country: "AL1",
    A: 210,
    B: -290,
  },
  {
    country: "AD2",
    A: 300,
    B: -200,
  },
  {
    country: "AE2",
    A: 250,
    B: -118,
  },
  {
    country: "AF2",
    A: 200,
    B: -50,
  },
  {
    country: "AG2",
    A: 140,
    B: -185,
  },
  {
    country: "AI2",
    A: 165,
    B: -109,
  },
  {
    country: "AL2",
    A: 210,
    B: -290,
  },
];
