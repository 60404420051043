import jwt_decode from "jwt-decode";
import moment from "moment";
import { UserType } from "../models/AuthenticationModel";

import { StorageUtils } from "./StorageUtils";

export class JWTUtils {
  static getPayload = (token: string | null = StorageUtils.getAuthorization()) => {
    if (!token) return null;
    try {
      return jwt_decode(token, {});
    } catch (e) {
      console.error(e);
    }
    return null;
  };

  static checkExp(payload: UserType) {
    return payload.exp > moment().unix();
  }

  static checkNbf(payload: UserType) {
    return payload.nbf <= moment().unix();
  }

  static isAuthenticated() {
    const jwt = JWTUtils.getPayload() as UserType;
    return jwt && JWTUtils.checkNbf(jwt) && JWTUtils.checkExp(jwt);
  }
}
