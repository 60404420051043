import { Instance, types } from "mobx-state-tree";
import AuthenticationModel from "./AuthenticationModel";
import SigninModel from "./authorization/SigninModel";
import SignupModel from "./authorization/SignupModel";
import MypageModel from "./MyPageModel";

const RootModel = types.model({
  authStore: AuthenticationModel,
  signinStore: SigninModel,
  signupStore: SignupModel,
  myPageStore: MypageModel,
});

export type RootModelType = Instance<typeof RootModel>;
export default RootModel;
