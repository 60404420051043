import Grid from "@mui/material/Grid";
import { observer } from "mobx-react-lite";
import { SensorDetailProps } from "../DoorMoreDetailed/DoorMoreDetailed";
import { useDetailedDataStore } from "../../../../../contexts/DetailedDataContext";
import SpotSectionOne from "./SpotSectionOne";
import SpotSectionTwo from "./SpotSectionTwo";
import { useEffect } from "react";

const SpotMoreDetailed = observer(({ main, sensorId, refresh }: SensorDetailProps) => {
  const { master, slave } = useDetailedDataStore();
  const spot = main ? master.spot : slave.spot;

  useEffect(() => {
    spot.refresh(sensorId);
  }, [refresh]);

  return (
    <Grid container className="more-detail-box">
      <SpotSectionOne sectionOne={spot.sectionOne} sensorId={sensorId} />
      <SpotSectionTwo sectionTwo={spot.sectionTwo} sensorId={sensorId} />
    </Grid>
  );
});

export default SpotMoreDetailed;
