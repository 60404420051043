type Props = {
  selected: number | string;
  list: Array<{ value: number | string; label: string }>;
  onSelect: Function;
};

const CustomSelect = ({ selected, list, onSelect }: Props) => {
  return (
    <select
      style={{
        height: "40px",
        minWidth: "162px",
        border: "1px solid #7D88B0",
        borderRadius: "3px",
        padding: "0 12px",
        color: "#7D88B0",
        WebkitAppearance: "none",
      }}
      value={selected}
      className={"gate-select"}
      onChange={(e) => onSelect(Number(e.target.value))}
    >
      {list.map((obj) => (
        <option key={obj.value} value={obj.value}>
          {obj.label}
        </option>
      ))}
    </select>
  );
};

export default CustomSelect;
