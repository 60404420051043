import { Instance, types } from "mobx-state-tree";
import TempAndHumiditySectionOne, { TempAndHumiditySectionOneType } from "./TempAndHumiditySectionOne";
import TempAndHumiditySectionTwo, { TempAndHumiditySectionTwoType } from "./TempAndHumiditySectionTwo";

const SensorTempAndHumidityModel = types
  .model({
    sectionOne: types.optional(TempAndHumiditySectionOne, {} as TempAndHumiditySectionOneType),
    sectionTwo: types.optional(TempAndHumiditySectionTwo, {} as TempAndHumiditySectionTwoType),
  })
  .actions((self) => ({
    refresh(sensorId: string) {
      self.sectionOne.init(sensorId);
      self.sectionTwo.init(sensorId);
    },
  }));

export type SensorTempAndHumidityModelType = Instance<typeof SensorTempAndHumidityModel>;
export default SensorTempAndHumidityModel;
