import { Button, Grid, SxProps } from "@mui/material";
import { DataGrid, GridRowParams } from "@mui/x-data-grid";
import { ApexOptions } from "apexcharts";
import { observer } from "mobx-react-lite";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import { GateSectionOneType } from "../../../../../models/detailed-data/gate/GateSectionOne";
import { gateColumns, GATE_TABLE_DATA } from "./GateConstants";

export const TableStyle: SxProps = {
  marginTop: "10px",
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeaders": {
    borderTop: "1px solid #333",
    borderBottom: "1px solid #333",
    borderRadius: 0,
    backgroundColor: "#F7F7FC",
    fontWeight: "bold",
    zIndex: 1,
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: "bold",
    fontSize: "14px",
    color: "#333",
  },
  "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
    outline: "none",
  },
  border: "none",
  borderBottom: "1px solid #D7D7D7",
  borderRadius: 0,
  zIndex: 0,
};

type Props = {
  sectionOne: GateSectionOneType;
  sensorId: string;
};

const GateSectionOne = observer(({ sectionOne, sensorId }: Props) => {
  const { lineData, periods, selectedPeriod, setSelectedPeriod } = sectionOne;
  const chartOptions: ApexOptions = {
    chart: {
      type: "area",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },

    colors: ["#449496", "#352D59", "#6049CC"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      colors: ["#449496", "#352D59", "#6049CC"],
      width: 1,
    },
    markers: {
      size: 0,
      strokeWidth: 0,
      colors: ["#449496", "#352D59", "#6049CC"],
    },
    xaxis: {
      categories: "datetime",
      labels: {
        formatter: (value, timestamp) => {
          let date = "";
          switch (selectedPeriod) {
            case 1:
              date = moment(value).format("YYYY-MM-DD");
              break;
            case 2:
              date = moment(value).format("YYYY-MM-DD");
              break;
            case 3:
              date = moment(value).format("YYYY-MM");
              break;
          }
          return date;
        },
      },
    },
    yaxis: {},
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
  };

  return (
    <Grid container marginTop={"76px"} justifyContent="space-between">
      <Grid item>
        <span className="title">개별 입출입 데이터 그래프</span>
        <span className="sub-title">각 실내 공간별 설정 기간에 따른 인구수 변화 추이를 나타냅니다</span>
      </Grid>
      <Grid item>
        <Grid container>
          <Grid item>
            {periods.map((obj) => (
              <Button
                onClick={() => setSelectedPeriod(obj.value)}
                key={obj.value}
                sx={{
                  marginLeft: "5px",

                  backgroundColor: selectedPeriod === obj.value ? "#7D88B0" : "transparent",
                  border: "1px solid #7D88B0",
                  borderRadius: "4px",
                  color: selectedPeriod === obj.value ? "#fff" : "#7D88B0",
                  "&:hover": {
                    backgroundColor: "#7D88B0",
                    color: "#fff",
                  },
                }}
              >
                {obj.label}
              </Button>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item flex={1} marginTop="80px">
          <ReactApexChart options={chartOptions} series={lineData.slice()} height={210} type="area" />
        </Grid>
      </Grid>
      <Grid container justifyContent={"flex-end"} marginTop="50px">
        <Button
          variant="outlined"
          sx={{
            borderRadius: "100px",
            paddingX: "20px",
            color: "#333333",
            borderColor: "#333333",
          }}
        >
          데이터 추출하기
        </Button>
      </Grid>
      <Grid item height={"300px"}>
        <DataGrid
          rows={GATE_TABLE_DATA.slice()}
          columns={gateColumns}
          //   disableSelectionOnClick
          sx={TableStyle}
          hideFooter
          //   onRowClick={(params: GridRowParams) => {
          //     console.log(params);
          //   }}
          isRowSelectable={(params: GridRowParams) => {
            return true;
          }}
        />
      </Grid>
    </Grid>
  );
});

export default GateSectionOne;
