import { Box, Container } from "@mui/material";
import { observer } from "mobx-react-lite";
import PlacesList from "../../../components/dashboard/Places/PlacesList";
import SensorDetail from "../../../components/dashboard/SensorDetail";
import Sensors from "../../../components/dashboard/Sensors";
import { useDetailedDataStore } from "../../../contexts/DetailedDataContext";

const PlacesAndMap = () => {
  return (
    <Box
      sx={{
        width: "50%",
        height: "100vh",
        paddingLeft: "103px",
        paddingTop: "70px",
        paddingRight: "33px",
        overflowY: "scroll",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        "::-webkit-scrollbar": { display: "none" },
        flex: 1,
        borderRight: "0.5px solid #D7D7D7",
      }}
    >
      <PlacesList title="설치 장소" />
    </Box>
  );
};

const DashboardScreen = observer(() => {
  const { showMaster, showSlave } = useDetailedDataStore();

  return (
    <Container
      maxWidth={false}
      sx={{ padding: "0px !important", display: "flex", maxHeight: "100vh", overflowY: "hidden" }}
    >
      {showSlave ? <SensorDetail master={false} /> : <PlacesAndMap />}
      {showMaster ? <SensorDetail master={true} /> : <Sensors />}
    </Container>
  );
});

export default DashboardScreen;
