import { Instance, types } from "mobx-state-tree";
import GateSectionOne, { GateSectionOneType } from "./GateSectionOne";
import GateSectionTwo, { GateSectionTwoType } from "./GateSectionTwo";

const GateModel = types
  .model({
    sectionOne: types.optional(GateSectionOne, {} as GateSectionOneType),
    sectionTwo: types.optional(GateSectionTwo, {} as GateSectionTwoType),
  })
  .actions((self) => ({
    refresh(sensorId: string) {
      self.sectionOne.init(sensorId);
      self.sectionTwo.init(sensorId);
    },
  }));
export type GateModelType = Instance<typeof GateModel>;
export default GateModel;
