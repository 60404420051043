import { types } from "mobx-state-tree";

const SensorModel = types
  .model({
    listScreen: types.optional(types.boolean, true),
    list: types.array(types.model({ id: types.number, name: types.string, type: types.string })),
    selected: types.maybeNull(types.number),
    uniqueId: types.optional(types.string, ""),
    address: types.optional(types.string, ""),
    buildingInfo: types.optional(types.string, ""),
    install_location: types.optional(types.string, ""),
    isExistingInstallLocation: types.optional(types.boolean, false),
    distance: types.optional(types.number, 0),
    selectedType: types.optional(types.string, ""),
    name: types.optional(types.string, ""),
  })
  .views((self) => ({
    get type() {
      return self.list.find((sensor) => self.selected === sensor.id)?.name;
    },
  }))
  .actions((self) => ({
    setSelectedType(type: string) {
      self.selectedType = type;
    },
    setSelected(id: number) {
      self.selected = id;
      self.uniqueId = "";
      this.setSelectedType(self.type ? self.type : "");
    },
    setListScreen(bool: boolean) {
      self.listScreen = bool;
    },
    setUniqueId(uniqueId: string) {
      self.uniqueId = uniqueId.toUpperCase();
    },
    setDistance(distance: number) {
      self.distance = distance;
    },
    setName(name: string) {
      self.name = name;
    },
  }));

export default SensorModel;
