import moment, { DurationInputArg2 } from "moment";

export const OPTIONS = [
  {
    value: 1,
    label: "[전체]",
  },
  {
    value: 2,
    label: "TYPE_2",
  },
  {
    value: 3,
    label: "TYPE_3",
  },
];

export const lineDataGenerator = (sensorCount: number) => {
  let series = [];
  for (let i = 0; i < sensorCount; i++) {
    let data = [];
    let addPeriod = 24;
    let addPeriodType: DurationInputArg2 = "hour";
    let start = moment();

    for (let j = 0; j <= addPeriod; j++) {
      data.push({
        x: moment(start).add(j, addPeriodType).format("hh:mm"),
        y: Math.floor(Math.random() * 100),
      });
    }
    series.push({
      name: "Sensor " + String(i),
      data,
    });
  }
  return series;
};

export const AI_GATE_TABLE_DATA = [
  {
    id: 1,
    created_at: "2022-04-05 12:00:00",
    location: "사무실",
    camera_ip: "192.168.1.88",
    count: 10,
  },
  {
    id: 2,
    created_at: "2022-04-05 12:00:00",
    location: "사무실",
    camera_ip: "192.168.1.88",
    count: 10,
  },
  {
    id: 3,
    created_at: "2022-04-05 12:00:00",
    location: "사무실",
    camera_ip: "192.168.1.88",
    count: 10,
  },
  {
    id: 4,
    created_at: "2022-04-05 12:00:00",
    location: "대표님실",
    camera_ip: "192.168.1.88",
    count: 10,
  },
  {
    id: 5,
    created_at: "2022-04-05 12:00:00",
    location: "큰회의실",
    camera_ip: "192.168.1.88",
    count: 10,
  },
  {
    id: 6,
    created_at: "2022-04-05 12:00:00",
    location: "작은회의실",
    camera_ip: "192.168.1.88",
    count: 10,
  },
  {
    id: 7,
    created_at: "2022-04-05 12:00:00",
    location: "복도",
    camera_ip: "192.168.1.88",
    count: 10,
  },
];
