import { useState } from "react";
import { UserSensorModelType } from "../../../models/main/UserSensorsModel";
import SensorOverview from "./SensorOverview";

type Props = {
  sensors: Array<UserSensorModelType>;
};

const GroupedSensorList = ({ sensors }: Props) => {
  const [selected, setSelected] = useState(sensors[0].sensor_id);
  const sensor = sensors.find((sensor) => sensor.sensor_id === selected);
  if (sensor) {
    return <SensorOverview sensor={sensor} groupList={sensors} setSelected={setSelected} />;
  } else return null;
};

export default GroupedSensorList;
