import Grid from "@mui/material/Grid";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { StyleSheet } from "./SpotDataInDetail";
import MuiIcon from "../../../common/MuiIcon";
import { memo } from "react";

const styles = StyleSheet.create({
  box: {
    alignItems: "center",
    height: "28px",
    justifyContent: "space-between",
    marginTop: "10px",
  },
  key: {
    color: "#565656",
    fontSize: "12px",
  },
  value: {
    color: "#333333",
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "end",
    lineHeight: 0.7,
  },
  subValue: {
    color: "#ABAABA",
    fontSize: "10px",
    fontWeight: "normal",
  },
});

const chartOptions: ApexOptions = {
  chart: {
    id: "basic-bar",
    // offsetY: -10,
    offsetX: 0,
    parentHeightOffset: 0,
    toolbar: {
      show: false,
    },
  },
  colors: ["#1C1344", "#50CDD0"],
  grid: {
    strokeDashArray: 2,
  },
  stroke: {
    width: 1,
    curve: "straight",
    colors: ["#1C1344", "#50CDD0"],
    dashArray: [0, 5],
  },
  markers: {
    size: 1,
    strokeColors: ["#1C1344", "#50CDD0"],
    hover: {
      size: 2,
    },
  },
  xaxis: {
    categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999],
    labels: {
      rotate: 0,
      hideOverlappingLabels: true,
      offsetY: -5,
      maxHeight: 20,
      style: {
        fontSize: "5px",
        colors: "#9F9FB4",
      },
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      style: {
        fontSize: "5px",
        colors: "#9F9FB4",
      },
    },
  },
  legend: {
    show: false,
  },

  series: [
    {
      name: "series-1",
      data: [10, 40, 15, 50, 49, 20, 70, 41],
    },
    {
      name: "series-2",
      data: [30, 40, 45, 50, 49, 60, 70, 91],
    },
  ],
};

const AiGateDataInDetail = memo(() => {
  return (
    <Grid container marginTop={"7px"}>
      <Grid item container flex={1} flexDirection="column">
        <Grid item container style={styles.box}>
          <span style={styles.key}>현재 유동인구 수</span>
          <span style={styles.value}>15명</span>
        </Grid>
        <Grid item container style={styles.box}>
          <span style={styles.key}>누적 IN/OUT</span>
          <span style={styles.value}>+36 / -21</span>
        </Grid>
        <Grid item container style={styles.box}>
          <span style={styles.key}>누적 IN/OUT</span>
          <span style={styles.value}>
            111명 <br />
            <span style={styles.subValue}>(04/18~04/25)</span>
          </span>
        </Grid>
      </Grid>
      <Grid item container flex={1} paddingLeft="45px">
        <Grid item container style={styles.box}>
          <span style={styles.key}>동시간 평균 방문객</span>
          <span style={styles.value}>
            {" "}
            24명 <br />
            <span style={styles.subValue}>(+9)</span>
          </span>
        </Grid>
        <Grid item container style={styles.box}>
          <span style={styles.key}>동시간 평균 IN/OUT</span>
          <span style={styles.value}>
            +33 / -15 <br />
            <span style={styles.subValue}>(-3) / (+6)</span>
          </span>
        </Grid>
        <Grid item container style={styles.box}>
          <span style={styles.key}>월간 평균 방문객 수</span>
          <span style={styles.value}>
            472명 <br />
            <span style={styles.subValue}>(04/18~04/25)</span>
          </span>
        </Grid>
      </Grid>
      <Grid item container width={"318px"} paddingLeft="25px">
        <Grid item flex={1}>
          <ReactApexChart options={chartOptions} series={chartOptions.series} type="line" height={"120px"} />
        </Grid>
        <Grid item flex={1} display="flex" alignItems={"center"} justifyContent="center">
          <MuiIcon name="landscape" sx={{ fontSize: "50px", color: "grey" }} />
        </Grid>
      </Grid>
    </Grid>
  );
});

export default AiGateDataInDetail;
