import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { CustomLink } from "./CustomLink";
import MssIcon, { IconKey } from "./MssIcon";
import "./index.css";
import "../../css/side-bar.css";
import MuiIcon from "./MuiIcon";
import { Avatar, Drawer, IconButton } from "@mui/material";
import { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../../contexts/RootContext";
import { Link, useNavigate } from "react-router-dom";

const BtnList = [
  {
    id: 1,
    icon: "main",
    name: "메인",
    navigate: "/",
    matchEnd: true,
  },
  {
    id: 2,
    icon: "sensor-register",
    name: "센서 등록",
    navigate: "sensor-register",
    matchEnd: false,
  },
  {
    id: 3,
    icon: "my-page",
    name: "마이 페이지",
    navigate: "my-page",
    matchEnd: false,
  },
];

const SideBar = observer(() => {
  const [open, setOpen] = useState(false);
  const { authStore } = useRootStore();
  const navigate = useNavigate();

  return (
    <Box className="side-bar">
      <Box height={70} width={70} display={{ xs: "none", md: "flex" }} alignItems={"center"} justifyContent={"center"}>
        <Link to="">
          <MssIcon name="logo" />
        </Link>
      </Box>
      <Box height={70} width={70} display={{ xs: "flex", md: "none" }} alignItems={"center"} justifyContent={"center"}>
        <IconButton onClick={() => setOpen(true)}>
          <MenuIcon sx={{ color: "#fff" }} />
        </IconButton>
      </Box>
      <Grid item display={{ xs: "none", md: "flex" }} flexDirection={"column"}>
        {BtnList.map((obj) => (
          <CustomLink key={obj.id} to={obj.navigate} class="side-link" matchEnd={obj.matchEnd}>
            <MssIcon name={obj.icon as IconKey} />
            {obj.name.split(" ").map((str) => (
              <span key={str} style={{ fontSize: "12px" }}>
                {str}
              </span>
            ))}
          </CustomLink>
        ))}
      </Grid>
      <Drawer open={open} onClose={() => setOpen(false)}>
        <Grid
          container
          flexDirection="column"
          paddingTop="30px"
          paddingLeft="20px"
          paddingBottom="30px"
          borderBottom={"1px solid #ccc"}
        >
          <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>{" "}
          <span
            style={{
              color: "#333",
              fontWeight: "bold",
              marginTop: "9px",
              fontSize: "12px",
              marginRight: "20px",
              minWidth: "120px",
              textAlign: "start",
              textTransform: "capitalize",
            }}
          >
            {authStore.user.username}
          </span>
        </Grid>
        <Grid item minWidth="200px" flexDirection={"column"} marginTop="20px">
          {BtnList.map((obj) => (
            <CustomLink
              key={obj.id}
              to={obj.navigate}
              class="side-link"
              matchEnd={obj.matchEnd}
              onClick={() => setOpen(false)}
            >
              <MssIcon name={obj.icon as IconKey} style={{ marginRight: "10px" }} />
              {obj.name.split(" ").map((str) => (
                <span key={str} style={{ fontSize: "14px" }}>
                  {str}
                </span>
              ))}
            </CustomLink>
          ))}
        </Grid>
        <Grid item minWidth="200px" marginTop="20px">
          <CustomLink
            to={"/"}
            class="side-link-neutral"
            matchEnd={true}
            onClick={() => {
              authStore.signOut(() => navigate(""));
              setOpen(false);
            }}
          >
            <MuiIcon name={"logout"} style={{ marginRight: "10px" }} />
            로그아웃
          </CustomLink>
        </Grid>
      </Drawer>
    </Box>
  );
});

export default SideBar;
