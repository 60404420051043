import Grid from "@mui/material/Grid";
import { observer } from "mobx-react-lite";
import FireDetectionSectionOne from "./FireDetectionSectionOne";
import FireDetectionSectionTwo from "./FireDetectionSectionTwo";
import { SensorDetailProps } from "../DoorMoreDetailed/DoorMoreDetailed";
import { useDetailedDataStore } from "../../../../../contexts/DetailedDataContext";
import { useEffect } from "react";

const FireDetectionMoreDetailed = observer(({ main, sensorId, refresh }: SensorDetailProps) => {
  const { master, slave } = useDetailedDataStore();
  const fireDetection = main ? master.fireDetection : slave.fireDetection;

  useEffect(() => {
    fireDetection.refresh(sensorId);
  }, [refresh]);

  return (
    <Grid container className="more-detail-box">
      <FireDetectionSectionOne sectionOne={fireDetection.sectionOne} sensorId={sensorId} />
      <FireDetectionSectionTwo sectionTwo={fireDetection.sectionTwo} sensorId={sensorId} />
    </Grid>
  );
});

export default FireDetectionMoreDetailed;
