import React from "react";
import Grid from "@mui/material/Grid";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

type CSSProperties = {
  [key: string]: React.CSSProperties;
};

export class StyleSheet {
  static create<Styles extends CSSProperties>(styles: Styles): Styles {
    return styles;
  }
}

const styles = StyleSheet.create({
  box: {
    alignItems: "center",
    height: "28px",
    justifyContent: "space-between",
    marginTop: "10px",
  },
  key: {
    color: "#565656",
    fontSize: "12px",
  },
  value: {
    color: "#333333",
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "end",
    lineHeight: 0.7,
  },
  subValue: {
    color: "#ABAABA",
    fontSize: "10px",
    fontWeight: "normal",
  },
});

const chartOptions: ApexOptions = {
  chart: {
    id: "basic-bar",
    // offsetY: -10,
    offsetX: 0,
    parentHeightOffset: 0,
    toolbar: {
      show: false,
    },
  },
  colors: ["#1C1344", "#50CDD0"],
  grid: {
    strokeDashArray: 2,
  },
  stroke: {
    width: 2,
    curve: "straight",
    colors: ["#1C1344", "#50CDD0"],
    dashArray: [0, 5],
  },
  markers: {
    size: 1,
    strokeColors: ["#1C1344", "#50CDD0"],
    hover: {
      size: 2,
    },
  },
  xaxis: {
    categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999],
    labels: {
      rotate: 0,
      hideOverlappingLabels: true,
      offsetY: -5,
      maxHeight: 20,
      style: {
        fontSize: "5px",
        colors: "#9F9FB4",
      },
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      style: {
        fontSize: "5px",
        colors: "#9F9FB4",
      },
    },
  },
  legend: {
    show: false,
  },

  series: [
    {
      name: "series-1",
      data: [10, 40, 15, 50, 49, 20, 70, 41],
    },
    {
      name: "series-2",
      data: [30, 40, 45, 50, 49, 60, 70, 91],
    },
  ],
};

const SpotDataInDetail = () => {
  return (
    <Grid container marginTop={"7px"}>
      <Grid item container flex={1} flexDirection="column">
        <Grid item container style={styles.box}>
          <span style={styles.key}>현재 유동인구 수</span>
          <span style={styles.value}>436명</span>
        </Grid>
        <Grid item container style={styles.box}>
          <span style={styles.key}>주간 평균 유동인구</span>
          <span style={styles.value}>
            472명 <br />
            <span style={styles.subValue}>(04/18~04/25)</span>
          </span>
        </Grid>
        <Grid item container style={styles.box}>
          <span style={styles.key}>월간 평균 유동인구 </span>
          <span style={styles.value}>
            384명 <br />
            <span style={styles.subValue}>(04/18~04/25)</span>
          </span>
        </Grid>
      </Grid>
      <Grid item container flex={1} paddingLeft="45px">
        <Grid item container style={styles.box}>
          <span style={styles.key}>어제 대비</span>
          <span style={styles.value}>
            {" "}
            -20명 <br />
            <span style={styles.subValue}>(456명)</span>
          </span>
        </Grid>
        <Grid item container style={styles.box}>
          <span style={styles.key}>주간 최고 수치</span>
          <span style={styles.value}>
            572명 <br />
            <span style={styles.subValue}>(04/25 금요일 13:23)</span>
          </span>
        </Grid>
        <Grid item container style={styles.box}>
          <span style={styles.key}>월간 최고 수치</span>
          <span style={styles.value}>
            583명 <br />
            <span style={styles.subValue}>(04/15 수요일 18:06)</span>
          </span>
        </Grid>
      </Grid>
      <Grid item container width={"318px"} paddingLeft="25px">
        <ReactApexChart options={chartOptions} series={chartOptions.series} type="line" height={"120px"} />
      </Grid>
    </Grid>
  );
};

export default SpotDataInDetail;
