import { ReactElement } from "react";
import AiGateDataInDetail from "../dashboard/sensor/overview/AiGateDataInDetail";
import EmergencyDataInDetail from "../dashboard/sensor/overview/EmergencyDataInDetail";
import FireDetectionDataInDetail from "../dashboard/sensor/overview/FireDetectionDataInDetail";
import GateDataInDetail from "../dashboard/sensor/overview/GateDataInDetail";
import InAndOutDataInDetail from "../dashboard/sensor/overview/InAndOutDataInDetail";
import SpotDataInDetail from "../dashboard/sensor/overview/SpotDataInDetail";
import TempAndHumidityInDetail from "../dashboard/sensor/overview/TempAndHumidityInDetail";

//detailed
import SpotMoreDetailed from "../dashboard/sensor/more-detailed/SpotMoreDetailed/SpotMoreDetailed";
import GateMoreDetailed from "../dashboard/sensor/more-detailed/GateMoreDetailed/GateMoreDetailed";
import TempAndHumidityMoreDetailed from "../dashboard/sensor/more-detailed/TempAndHumidityMoreDetailed/TempAndHumidityMoreDetailed";
import FireDetectionMoreDetailed from "../dashboard/sensor/more-detailed/FireDetectionMoreDetailed/FireDetectionMoreDetailed";
import DoorMoreDetailed from "../dashboard/sensor/more-detailed/DoorMoreDetailed/DoorMoreDetailed";
import EmergencyMoreDetailed from "../dashboard/sensor/more-detailed/EmergencyMoreDetailed/EmergencyMoreDetailed";
import AiGateMoreDetailed from "../dashboard/sensor/more-detailed/AiGateMoreDetailed/AiGateMoreDetailed";

import {
  SENSOR_TYPE_DOOR,
  SENSOR_TYPE_FIRE,
  SENSOR_TYPE_GATE,
  SENSOR_TYPE_GATE_3D,
  SENSOR_TYPE_SOS,
  SENSOR_TYPE_SPOT,
  SENSOR_TYPE_TEMP_HUMIDITY,
} from "../../constants/EmbeddedSensors";

const inDetail: any = {};
inDetail[SENSOR_TYPE_DOOR] = InAndOutDataInDetail;
inDetail[SENSOR_TYPE_FIRE] = FireDetectionDataInDetail;
inDetail[SENSOR_TYPE_GATE] = GateDataInDetail;
inDetail[SENSOR_TYPE_GATE_3D] = AiGateDataInDetail;
inDetail[SENSOR_TYPE_SOS] = EmergencyDataInDetail;
inDetail[SENSOR_TYPE_SPOT] = SpotDataInDetail;
inDetail[SENSOR_TYPE_TEMP_HUMIDITY] = TempAndHumidityInDetail;

const detailed: any = {};
detailed[SENSOR_TYPE_DOOR] = DoorMoreDetailed;
detailed[SENSOR_TYPE_FIRE] = FireDetectionMoreDetailed;
detailed[SENSOR_TYPE_GATE] = GateMoreDetailed;
detailed[SENSOR_TYPE_GATE_3D] = AiGateMoreDetailed;
detailed[SENSOR_TYPE_SOS] = EmergencyMoreDetailed;
detailed[SENSOR_TYPE_SPOT] = SpotMoreDetailed;
detailed[SENSOR_TYPE_TEMP_HUMIDITY] = TempAndHumidityMoreDetailed;
const sensors = {
  "in-detail": inDetail,
  detailed: detailed,
};

export type SensorKeyType = keyof typeof sensors;
export type SensorComponentType = keyof typeof sensors[SensorKeyType];

type Props = ReactElement & {
  name: SensorComponentType;
  type: SensorKeyType;
  main?: boolean;
  sensorId?: string;
  refresh?: boolean;
  setRssi?: Function;
  setBattery?: Function;
};

const MssSensor = (props: Props) => {
  const Sensor = sensors[props.type as SensorKeyType][props.name as SensorComponentType];

  return <Sensor {...props} />;
};

export default MssSensor;
