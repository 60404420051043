import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import api from "../../api/api";
import { Item, NewsType } from "../../../types/News";
import Grid from "@mui/material/Grid";

const News = () => {
  const extractTextPattern = /(<([^>]+)>)/gi;
  const [news, setNews] = useState<Array<Item>>([]);
  const [current, setCurrent] = useState(-1);
  const [currentClass, setCurrentClass] = useState("");

  const fetchAndSet = () => {
    api.get<NewsType>("/v1/navernews").then((r) => {
      if (!r.ok || !r.data) return;
      setNews(r.data.items);
      setCurrent(0);
    });
  };

  useEffect(() => {
    fetchAndSet();
  }, []);

  useEffect(() => {
    if (current > -1) {
      setCurrentClass("animate__animated animate__fadeInUp");
      setTimeout(() => {
        setCurrentClass("animate__animated animate__fadeOutUp");
        setTimeout(() => {
          if (current < news.length - 1) {
            setCurrent(current + 1);
          } else {
            setCurrent(-1);
          }
        }, 500);
      }, 5000);
    } else if (current === -1) {
      fetchAndSet();
    }
  }, [current]);

  return (
    <>
      {current > -1 && (
        <Grid item key={news[current].link} className={currentClass}>
          <a href={news[current].link} target={"_blank"} style={{ color: "#7D88B0" }}>
            <ReactMarkdown>{news[current].title.replace(extractTextPattern, "")}</ReactMarkdown>
          </a>
        </Grid>
      )}
    </>
  );
};

export default News;
