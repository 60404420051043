import { useState } from "react";
import { rexNum } from "../constants/RegularEpressions";

const onlyEngNum = /^[a-z][a-z0-9]*$/i;
const regPassword = /^[a-zA-Z0-9!@#$%^&*??]*$/;

export const useFormInput = (initialValue: string, params: any) => {
  const [value, setValue] = useState(initialValue);
  const handleChange = (e: any) => {
    const value = e.target.value;
    if (params?.only_eng) {
      if (onlyEngNum.test(value) || /^\s*$/.test(value)) {
        setValue(value);
      }
    } else if (params?.password) {
      if (regPassword.test(value)) {
        setValue(value);
      }
    } else {
      setValue(value);
    }
  };
  const handleKeyEnter = (e: any) => {
    if (params?.onPressEnter && e.key === "Enter") {
      params.onPressEnter();
    }
  };
  return {
    value,
    onChange: handleChange,
    onKeyPress: handleKeyEnter,
  };
};

export const useFormNumberOnlyInput = (initialValue: string) => {
  const [value, setValue] = useState(initialValue);
  const handleChange = (e: any) => {
    const temp_value = e.target.value;
    if (!rexNum.test(temp_value)) {
      setValue(temp_value);
    }
  };
  return {
    value,
    onChange: handleChange,
  };
};

export const onEnterKeyPressed = (e: KeyboardEvent, callback: Function) => {
  if (e.key === "Enter") {
    callback();
  }
};
