import { Instance, types } from "mobx-state-tree";
import api from "../../../api/api";

const SpotSectionOne = types
  .model({
    startDate: types.maybeNull(types.string),
    endDate: types.maybeNull(types.string),
    lineData: types.frozen(),
    tableData: types.frozen<Array<any>>(),
  })
  .actions((self) => ({
    setStartDate(startDate: string | null) {
      self.startDate = startDate;
    },
    setEndDate(endDate: string | null) {
      self.endDate = endDate;
    },
    setLineData(lineData: any) {
      self.lineData = lineData;
    },
    setTableData(tableData: typeof self.tableData) {
      self.tableData = tableData;
    },
  }))
  .actions((self) => ({
    fetchLineData: async (sensorId: string) => {
      const res = await api.get<any>(`/v1/sensor/spot/${sensorId}/daily-detect-sensor`, {
        start_date: self.startDate,
        end_date: self.endDate,
      });

      if (!res.ok || !res.data) return;
      self.setLineData(res.data);
    },
    fetchTableData: async (sensorId: string) => {
      const res = await api.get<{ data: Array<any> }>(`/v1/sensor/spot/${sensorId}`, {
        start_date: self.startDate,
        end_date: self.endDate,
      });

      if (!res.ok || !res.data) return;
      self.setTableData(res.data.data);
    },
    init(sensorId: string) {
      this.fetchLineData(sensorId);
      this.fetchTableData(sensorId);
    },
  }));

export type SpotSectionOneType = Instance<typeof SpotSectionOne>;
export default SpotSectionOne;
