import {
  SENSOR_TYPE_DOOR,
  SENSOR_TYPE_FIRE,
  SENSOR_TYPE_GATE,
  SENSOR_TYPE_GATE_3D,
  SENSOR_TYPE_SOS,
  SENSOR_TYPE_SPOT,
  SENSOR_TYPE_TEMP_HUMIDITY,
} from "../constants/EmbeddedSensors";

export const PLACES = [
  {
    place_uuid: "1",
    place_name: "A. 부산시청 본관",
    address: "부산 해운대구 수영강변대로 140, 806",
    main_image: "",
  },
  {
    place_uuid: "2",
    place_name: "B. 제로웹 회의실",
    address: "부산 해운대구 수영강변대로 140, 806",
    main_image: "",
  },
];

export const SENSORS = [
  {
    type: {
      id: 5,
      name: "GATE",
    },
    sensors: [
      {
        uuid: "9",
        name: "GATE",
      },
      {
        uuid: "10",
        name: "GATE_2",
      },
    ],
  },
  {
    type: {
      id: 1,
      name: "SPOT",
    },
    sensors: [
      {
        uuid: "1",
        name: "SPOT",
      },
      {
        uuid: "2",
        name: "SPOT_2",
      },
    ],
  },
  {
    type: {
      id: 6,
      name: "IN_AND_OUT",
    },
    sensors: [
      {
        uuid: "11",
        name: "IN_AND_OUT",
      },
      {
        uuid: "12",
        name: "IN_AND_OUT_2",
      },
    ],
  },
  {
    type: {
      id: 3,
      name: "FIRE_DETECTION",
    },
    sensors: [
      {
        uuid: "5",
        name: "FIRE_DETECTION",
      },
      {
        uuid: "6",
        name: "FIRE_DETECTION_2",
      },
    ],
  },

  {
    type: {
      id: 2,
      name: "TEMP_AND_HUMIDITY",
    },
    sensors: [
      {
        uuid: "3",
        name: "TEMP_AND_HUMIDITY",
      },
      {
        uuid: "4",
        name: "TEMP_AND_HUMIDITY_2",
      },
    ],
  },

  {
    type: {
      id: 4,
      name: "AI_GATE",
    },
    sensors: [
      {
        uuid: "7",
        name: "AI_GATE",
      },
      {
        uuid: "8",
        name: "AI_GATE",
      },
    ],
  },

  {
    type: {
      id: 7,
      name: "EMERGENCY",
    },
    sensors: [
      {
        uuid: "13",
        name: "EMERGENCY",
      },
      {
        uuid: "14",
        name: "EMERGENCY_2",
      },
    ],
  },
];

export const SENSORS_LIST = [
  {
    id: 1,
    name: "SPOT",
    type: SENSOR_TYPE_SPOT,
  },
  {
    id: 2,
    name: "온습도",
    type: String(SENSOR_TYPE_TEMP_HUMIDITY),
  },
  {
    id: 3,
    name: "화재감지",
    type: String(SENSOR_TYPE_FIRE),
  },
  {
    id: 4,
    name: "3D GATE",
    type: SENSOR_TYPE_GATE_3D,
  },

  {
    id: 5,
    name: "GATE",
    type: SENSOR_TYPE_GATE,
  },

  {
    id: 6,
    name: "입출입",
    type: String(SENSOR_TYPE_DOOR),
  },

  {
    id: 7,
    name: "비상벨",
    type: String(SENSOR_TYPE_SOS),
  },
];
