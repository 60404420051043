import { createContext, ReactNode, useContext } from "react";
import RootModel, { RootModelType } from "../models/RootModel";
import AuthenticationModel from "../models/AuthenticationModel";
import SignupModel from "../models/authorization/SignupModel";
import SigninModel from "../models/authorization/SigninModel";
import MypageModel from "../models/MyPageModel";

const RootContext = createContext<RootModelType>({} as RootModelType);

const rootStore = RootModel.create({
  authStore: AuthenticationModel.create(),
  signinStore: SigninModel.create(),
  signupStore: SignupModel.create(),
  myPageStore: MypageModel.create(),
});

type Props = {
  children: ReactNode;
};

export const RootStoreProvider = ({ children }: Props) => {
  return <RootContext.Provider value={rootStore}>{children}</RootContext.Provider>;
};
export const useRootStore = () => useContext(RootContext);
