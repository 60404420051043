import { GridColDef } from "@mui/x-data-grid";
import { ApexOptions } from "apexcharts";
import moment, { DurationInputArg2 } from "moment";
// import { PlaceModelType } from "../../../../../models/detailed-data/gate/GateColumnModel";d

export const GATE_COLUMN_SERIES = [
  {
    name: "Count",
    data: [
      {
        x: "1:00",
        y: 100,
      },
      {
        x: "2:00",
        y: 100,
      },
      {
        x: "3:00",
        y: 100,
      },
      {
        x: "4:00",
        y: 100,
      },
      {
        x: "5:00",
        y: 100,
      },
      {
        x: "6:00",
        y: 100,
      },
      {
        x: "7:00",
        y: 100,
      },
      {
        x: "8:00",
        y: 100,
      },
    ],
  },
  {
    name: "Count 2",
    data: [
      {
        x: "1:00",
        y: 50,
      },
      {
        x: "2:00",
        y: 50,
      },
      {
        x: "3:00",
        y: 50,
      },
      {
        x: "4:00",
        y: 50,
      },
      {
        x: "5:00",
        y: 50,
      },
      {
        x: "6:00",
        y: 50,
      },
      {
        x: "7:00",
        y: 50,
      },
      {
        x: "8:00",
        y: 50,
      },
    ],
  },
];

export const GATE_PLACES_DATA: Array<any> = [
  {
    uuid: "1",
    sensor_count: 4,
    name: "[전체] 제로웹 사무실 정문",
    current_count: 23,
    last_counted_at: "2022-10-11 09:12:31",
  },
  {
    uuid: "2",
    sensor_count: 1,
    name: "회의실",
    current_count: 0,
    last_counted_at: "2022-10-11 09:12:31",
  },
  {
    uuid: "3",
    sensor_count: 6,
    name: "기업부설연구소",
    current_count: 23,
    last_counted_at: "2022-10-11 09:12:31",
  },
  {
    uuid: "4",
    sensor_count: 1,
    name: "대표님실",
    current_count: 23,
    last_counted_at: "2022-10-11 09:12:31",
  },
];

export const GATE_LINE_PLACES = [
  {
    label: "제로웹 사무실 정문",
    value: 1,
  },
  {
    label: "제로웹 사무실 2",
    value: 2,
  },

  {
    label: "제로웹 사무실 3",
    value: 3,
  },
];

export const GATE_LINE_PERIOD = [
  {
    label: "일간",
    value: 1,
  },
  {
    label: "주간",
    value: 2,
  },
  {
    label: "월간",
    value: 3,
  },
];

export const lineDataGenerate = (gateCount: number, type: string) => {
  let series = [];
  for (let i = 0; i < gateCount; i++) {
    let data = [];
    let addPeriod = 0;
    let addPeriodType: DurationInputArg2 = "day";
    let start = moment();
    switch (type) {
      case "1":
        addPeriod = 7;
        addPeriodType = "day";
        start = moment().add(-7, addPeriodType);
        break;
      case "2":
        addPeriod = 4;
        addPeriodType = "week";
        start = moment().add(-4, addPeriodType);
        break;
      case "3":
        addPeriod = 12;
        addPeriodType = "month";
        start = moment().add(-12, addPeriodType);
        break;
      case "4":
        addPeriod = 24;
        addPeriodType = "hour";
        start = moment().add(-24, addPeriodType);

        break;
    }

    for (let j = 0; j <= addPeriod; j++) {
      data.push({
        x: moment(start).add(j, addPeriodType).format("YYYY-MM-DD HH:00:00"),
        y: Math.floor(Math.random() * 100),
      });
    }
    series.push({
      name: "Sensor " + String(i),
      data,
    });
  }

  return series;
};

export const GATE_TABLE_DATA = [
  {
    id: 2,
    created_at: "2022-04-05 12:00:00",
    location: "사무실",
    mac_address: "2C3D6H2F",
    in: 10,
    out: 5,
  },
  {
    id: 3,
    created_at: "2022-04-05 12:00:00",
    location: "사무실",
    mac_address: "2C3D6H2F",
    in: 10,
    out: 5,
  },
  {
    id: 4,
    created_at: "2022-04-05 12:00:00",
    location: "대표님실",
    mac_address: "2C3D6H2F",
    in: 10,
    out: 5,
  },
  {
    id: 5,
    created_at: "2022-04-05 12:00:00",
    location: "큰회의실",
    mac_address: "2C3D6H2F",
    in: 10,
    out: 5,
  },
  {
    id: 6,
    created_at: "2022-04-05 12:00:00",
    location: "작은회의실",
    mac_address: "2C3D6H2F",
    in: 10,
    out: 5,
  },
  {
    id: 7,
    created_at: "2022-04-05 12:00:00",
    location: "복도",
    mac_address: "2C3D6H2F",
    in: 10,
    out: 5,
  },
];

export const GATE_PIE_DATA = [
  {
    id: "작은 회의실",
    value: 60,
  },
  {
    id: "대표님실",
    value: 100,
  },
  {
    id: "큰 회의실",
    value: 300,
  },
];

export const gateColumns: GridColDef[] = [
  {
    field: "id",
    headerName: "No",
    width: 90,
    align: "center",
    headerAlign: "center",
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
  },
  {
    field: "created_at",
    headerName: "날짜(시간)",
    width: 150,
    sortable: true,
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    flex: 3,
  },
  {
    field: "location",
    headerName: "설치장소",
    width: 150,
    sortable: false,
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    flex: 2,
  },
  {
    field: "mac_address",
    headerName: "Mac - Address",
    width: 110,
    sortable: false,
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    flex: 3,
  },
  {
    field: "in",
    headerName: "IN",
    sortable: false,
    width: 160,
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    flex: 1,
  },
  {
    field: "out",
    headerName: "OUT",
    sortable: false,
    width: 160,
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    flex: 2,
  },
];

export const heatmapOptions: ApexOptions = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    heatmap: {
      shadeIntensity: 1,
      radius: 0,
      useFillColorAsStroke: false,
      colorScale: {
        ranges: [
          {
            from: 0,
            to: 25,
            name: "low",
            color: "#128FD9",
          },
          {
            from: 26,
            to: 55,
            name: "extreme",
            color: "#FF0000",
          },
        ],
      },
    },
  },
  legend: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: 1,
  },
};
