import { Instance, types } from "mobx-state-tree";
import DoorSectionOne, { DoorSectionOneType } from "./DoorSectionOne";

const SensorDoorModel = types
  .model({
    sectionOne: types.optional(DoorSectionOne, {} as DoorSectionOneType),
  })
  .actions((self) => ({
    refresh(sensorId: string) {
      self.sectionOne.init(sensorId);
    },
  }));

export type SensorDoorModelType = Instance<typeof SensorDoorModel>;
export default SensorDoorModel;
