import Grid from "@mui/material/Grid";
import Lottie from "lottie-react";
import EmptyListAnim from "../../resources/lottie/empty-list.json";
import Button from "./Button";

type Props = {
  message?: string;
  label?: string;
  callback?: VoidFunction;
};

const EmptyList = ({ label, message, callback }: Props) => {
  return (
    <Grid container flexDirection={"column"} alignItems="center">
      <Grid item marginTop={"30px"}>
        <Lottie animationData={EmptyListAnim} loop style={{ height: 170 }} />
      </Grid>
      {message && <span style={{ marginBottom: "20px", fontWeight: "bold", fontSize: "20px" }}>{message}</span>}
      {label && <Button onClick={() => callback && callback()}>{label}</Button>}
    </Grid>
  );
};

export default EmptyList;
