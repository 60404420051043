import Grid from "@mui/material/Grid";
import { observer } from "mobx-react-lite";
import { usePlaceStore } from "../../contexts/PlaceContext";
import { useSensorRegisterStore } from "../../contexts/SensorRegisterContext";
import Button from "../common/Button";
import LocationMap from "./LocationMap";

const PURPOSES = [
  {
    id: 1,
    name: "은행, 카페, 음식점 등 건물 앞(실외) 유동인구 분석",
    areaRadius: 15,
  },
  {
    id: 2,
    name: "축제관광객 분석 단기간 사용",
    areaRadius: 50,
  },
  {
    id: 3,
    name: "도심 주요 상권 분석",
    areaRadius: 30,
  },
  {
    id: 4,
    name: "실내 공간 유동인구 분석",
    areaRadius: 30,
  },
  {
    id: 5,
    name: "기타",
    areaRadius: 35,
  },
];

const SelectPurpose = observer(() => {
  const { stepper, location, sensor } = useSensorRegisterStore();
  const { selected } = usePlaceStore();
  return (
    <Grid container>
      <Grid container flexDirection={"column"}>
        <span className="title">사용 목적을 선택해 주세요</span>
        <span className="sub-title">데이터의 분석목적에 따라 센서 수집 반경이 조절됩니다.</span>
      </Grid>
      <Grid container marginTop={"67px"}>
        <Grid item md={5}>
          <LocationMap
            height="254px"
            width="326px"
            tip={false}
            markerArea
            markerAreaRadius={PURPOSES.find((purpose) => purpose.id === location.purpose)?.areaRadius}
          />
        </Grid>
        <Grid item md={7} display={"flex"} flexDirection="column" paddingLeft={"20px"}>
          {PURPOSES.map((purpose) => (
            <Button
              key={purpose.id}
              fullWidth
              onClick={() => {
                location.setPurpose(purpose.id);
                sensor.setDistance(purpose.areaRadius);
              }}
              btnType="text"
              className={location.purpose === purpose.id ? "radio-btn-group active" : "radio-btn-group disabled"}
              style={{ marginBottom: "7px", height: "45px" }}
            >
              {purpose.name}
            </Button>
          ))}
        </Grid>
      </Grid>
      <Grid container marginTop={"50px"} justifyContent={"space-between"}>
        <Button btnType="text" onClick={() => stepper.previousStep()}>
          {"< 이전"}
        </Button>

        <Button
          tooltipLabel={sensor.distance ? "다음" : "Please select distance"}
          btnType={sensor.distance ? "default" : "disabled"}
          onClick={() => sensor.distance && stepper.nextStep()}
        >
          다음 단계
        </Button>
      </Grid>
    </Grid>
  );
});

export default SelectPurpose;
