import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import PlacesList from "../dashboard/Places/PlacesList";

const SensorLocation = () => {
  return (
    <Box
      sx={{
        height: "100vh",
        overflowY: "scroll",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        "::-webkit-scrollbar": { display: "none" },
        flex: 3,
      }}
    >
      <Grid
        container
        flexDirection={"column"}
        sx={{
          position: "sticky",
          top: "0",
          backgroundColor: "#fff",
          zIndex: 15,
          paddingBottom: "10px",
        }}
      >
        <span className="title">장소를 등록해 주세요</span>
        <span className="sub-title">설치할 장소 또는 설치된 장소의 주소를 입력해주세요</span>
      </Grid>

      <PlacesList mapIncluded={false} editButton={false} addButton={true} />
      <Box marginBottom={"200px"} />
    </Box>
  );
};

export default SensorLocation;
