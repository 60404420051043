import { cast, Instance, types } from "mobx-state-tree";
import { toast } from "react-toastify";
import api from "../../api/api";

type GroupType = "SENSOR_TYPE" | "PLACE" | "";

const UserSensorModel = types
  .model({
    uuid: types.string,
    created_at: types.number,
    updated_at: types.number,
    user_uuid: types.string,
    place_uuid: types.string,
    id: types.number,
    type: types.string,
    sensor_id: types.string,
    name: types.maybeNull(types.string),
    distance: types.maybeNull(types.number),
  })
  .actions((self) => ({
    setName(name: typeof self.name) {
      self.name = name;
    },
  }));

const UserSensorListModel = types
  .model({
    list: types.array(UserSensorModel),
    grouped: types.optional(types.boolean, true),
    groupType: types.optional(types.string, "PLACE"),
  })
  .views((self) => ({
    get listGroupedBySensorType() {
      const grouped = [];
      const uniques = self.list.map((item) => item.type).filter((value, index, self) => self.indexOf(value) === index);
      for (let i = 0; i < uniques.length; i++) {
        const sameSensorList = self.list.filter((sensor) => sensor.type === uniques[i]);
        grouped.push({
          type: uniques[i],
          sensors: sameSensorList,
        });
      }

      return grouped;
    },
    get listGroupedByPlace() {
      const grouped = [];
      const uniques = self.list
        .map((item) => item.place_uuid)
        .filter((value, index, self) => self.indexOf(value) === index);
      for (let i = 0; i < uniques.length; i++) {
        const samePlaceSensorList = self.list.filter((sensor) => sensor.place_uuid === uniques[i]);
        grouped.push({
          type: uniques[i],
          sensors: samePlaceSensorList,
        });
      }

      return grouped;
    },

    getGroupedBySensorType(place_uuid: string) {
      const grouped = [];
      const uniques = self.list.map((item) => item.type).filter((value, index, self) => self.indexOf(value) === index);
      for (let i = 0; i < uniques.length; i++) {
        const sameSensorList = self.list.filter(
          (sensor) => sensor.type === uniques[i] && sensor.place_uuid === place_uuid
        );

        sameSensorList.length > 0 &&
          grouped.push({
            type: uniques[i],
            sensors: sameSensorList,
          });
      }

      return grouped;
    },

    getSensor(sensor_id: string, place_uuid: string) {
      return self.list.find((sensor) => sensor.sensor_id === sensor_id && sensor.place_uuid === place_uuid);
    },
    getPlaceSensorList(place_uuid: string) {
      return self.list.filter((sensor) => sensor.place_uuid === place_uuid);
    },
  }))
  .actions((self) => ({
    setList(list: Array<UserSensorModelType>) {
      self.list = cast(list);
    },
    setGrouped(grouped: boolean) {
      self.grouped = grouped;
    },
    setGroupType(groupType: GroupType) {
      self.groupType = groupType;
    },
  }))
  .actions((self) => ({
    fetchSensorList: async () => {
      const res = await api.get<{ sensors: Array<UserSensorModelType> }>("/v1/sensor");
      if (!res.ok || !res.data) return;
      self.setList(res.data.sensors);
    },
    modifySensorName: async (uuid: string, name: string) => {
      const res = await api.put(`/v1/sensor/${uuid}`, { name });
      if (!res.ok) return toast.error("서버 문제");
      toast.success("센서 이름이 변경되었습니다");
    },
    deleteSensor: async (uuid: string) => {
      const res = await api.delete(`/v1/sensor/${uuid}`);
      if (!res.ok) return toast.error("서버 문제");
      toast.success("센서가 삭제되었습니다");
      self.setList(self.list.filter((sensor) => sensor.uuid !== uuid));
    },
    init() {
      this.fetchSensorList();
    },
  }));

export type UserSensorModelType = Instance<typeof UserSensorModel>;
export type UserSensorListModelType = Instance<typeof UserSensorListModel>;

export default UserSensorListModel;
