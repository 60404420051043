import { Instance, types } from "mobx-state-tree";
import api from "../../../api/api";

const SensorSummaryModel = types.model({
  latest_detected_time: types.optional(types.string, "최근 기록 없음"),
  detected_time: types.optional(types.number, 0),
});

const SummaryModel = types.model({
  data: types.frozen<Array<any>>(),
  sensorSummary: types.optional(SensorSummaryModel, {} as SensorSummaryModelType),
});

const FireDetectionSectionOne = types
  .model({
    startDate: types.maybeNull(types.string),
    summary: types.optional(SummaryModel, {} as SummaryModelType),
    tableData: types.frozen<Array<any>>(),
  })
  .actions((self) => ({
    setStartDate(startDate: string | null) {
      self.startDate = startDate;
    },
    setSummary(summary: SummaryModelType) {
      self.summary = summary;
    },
    setTableData(tableData: typeof self.tableData) {
      self.tableData = tableData;
    },
  }))
  .actions((self) => ({
    fetchSummary: async (sensorId: string) => {
      const res = await api.get<SummaryModelType>(`/v1/sensor/fire-detection/${sensorId}/summary`, {
        start_date: self.startDate,
      });

      if (!res.ok || !res.data) return;
      self.setSummary(res.data);
    },
    fetchTableData: async (sensorId: string) => {
      const res = await api.get<{ data: any }>(`/v1/sensor/fire-detection/${sensorId}`, {
        start_date: self.startDate,
      });

      if (!res.ok || !res.data) return;
      self.setTableData(res.data.data);
    },
    init(sensorId: string) {
      this.fetchSummary(sensorId);
      this.fetchTableData(sensorId);
    },
  }));

export type SummaryModelType = Instance<typeof SummaryModel>;
export type SensorSummaryModelType = Instance<typeof SensorSummaryModel>;

export type FireDetectionSectionOneType = Instance<typeof FireDetectionSectionOne>;
export default FireDetectionSectionOne;
