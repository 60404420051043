import { SVGProps } from "react";

// Common
import { ReactComponent as SplitScreenOutlineIcon } from "../../resources/sensor/split-screen-outline.svg";
import { ReactComponent as SplitScreenFilledIcon } from "../../resources/sensor/split-screen-filled.svg";
import { ReactComponent as RefreshIcon } from "../../resources/sensor/refresh.svg";
import { ReactComponent as PdfIcon } from "../../resources/sensor/pdf.svg";
import { ReactComponent as NoImage } from "../../resources/common/no-img.svg";
import { ReactComponent as MainIcon } from "../../resources/menu/main.svg";
import { ReactComponent as SensorRegisterIcon } from "../../resources/menu/sensor-register.svg";
import { ReactComponent as MyPage } from "../../resources/menu/my-page.svg";
import { ReactComponent as RealstepLogo } from "../../resources/common/realstep-logo.svg";
import { ReactComponent as CalendarIcon } from "../../resources/sensor/calendar.svg";
import { ReactComponent as UserIcon } from "../../resources/common/user-icon.svg";
import { ReactComponent as NaverIcon } from "../../resources/social/naver-icon.svg";
import { ReactComponent as EditIcon } from "../../resources/common/edit-icon.svg";
import { ReactComponent as _404Icon } from "../../resources/not-found/sorry.svg";

//Sensor icons
import { ReactComponent as HubModel } from "../../resources/sensor/icons/hub.svg";
import { ReactComponent as FireDetectionModel } from "../../resources/sensor/icons/fire-detection.svg";
import { ReactComponent as InAndOutModel } from "../../resources/sensor/icons/in-and-out.svg";
import { ReactComponent as TempAndHumidityModel } from "../../resources/sensor/icons/temp-and-humidity.svg";
import { ReactComponent as EmergencyModel } from "../../resources/sensor/icons/emergency.svg";
import { ReactComponent as SpotModel } from "../../resources/sensor/icons/spot.svg";
import { ReactComponent as GateModel } from "../../resources/sensor/icons/gate.svg";
import { ReactComponent as AiGateModel } from "../../resources/sensor/icons/3d-camera.svg";

//SensorHelp icons
import { ReactComponent as FireDetectionHelpModel } from "../../resources/sensor/icons/fire-detection-help.svg";
import { ReactComponent as InAndOutHelpModel } from "../../resources/sensor/icons/in-and-out-help.svg";
import { ReactComponent as TempAndHumidityHelpModel } from "../../resources/sensor/icons/temp-and-humidity-help.svg";
import { ReactComponent as EmergencyHelpModel } from "../../resources/sensor/icons/emergency-help.svg";
import { ReactComponent as SpotHelpModel } from "../../resources/sensor/icons/spot-help.svg";
import { ReactComponent as GateHelpModel } from "../../resources/sensor/icons/gate-help.svg";
import { ReactComponent as AiGateHelpModel } from "../../resources/sensor/icons/3d-camera-help.svg";

//Battery Icons
import { ReactComponent as Battery1 } from "../../resources/sensor/battery/battery-1.svg";
import { ReactComponent as Battery2 } from "../../resources/sensor/battery/battery-2.svg";
import { ReactComponent as Battery3 } from "../../resources/sensor/battery/battery-3.svg";
import { ReactComponent as Battery4 } from "../../resources/sensor/battery/battery-4.svg";
import { ReactComponent as Battery5 } from "../../resources/sensor/battery/battery-5.svg";

//NetworkIcon
import { ReactComponent as Network1 } from "../../resources/sensor/network/network-1.svg";
import { ReactComponent as Network2 } from "../../resources/sensor/network/network-2.svg";
import { ReactComponent as Network3 } from "../../resources/sensor/network/network-3.svg";
import { ReactComponent as Network4 } from "../../resources/sensor/network/network-4.svg";
import { ReactComponent as Network5 } from "../../resources/sensor/network/network-5.svg";

//React-icons
import { VscGroupByRefType, VscUngroupByRefType } from "react-icons/vsc";
import { RiImageEditLine, RiKakaoTalkFill } from "react-icons/ri";
import { CgSmartHomeWashMachine } from "react-icons/cg";
import { MdOutlineHomeWork } from "react-icons/md";

const icons = {
  //Common
  logo: RealstepLogo,
  main: MainIcon,
  "sensor-register": SensorRegisterIcon,
  "my-page": MyPage,
  "split-screen-outline": SplitScreenOutlineIcon,
  "split-screen-filled": SplitScreenFilledIcon,
  refresh: RefreshIcon,
  pdf: PdfIcon,
  "no-img": NoImage,
  "image-edit": RiImageEditLine,
  "home-work": MdOutlineHomeWork,
  "wash-machine": CgSmartHomeWashMachine,
  "kakao-talk": RiKakaoTalkFill,
  calendar: CalendarIcon,
  user: UserIcon,
  naver: NaverIcon,
  edit: EditIcon,
  "404": _404Icon,

  //Sensor
  hub: HubModel,
  "19": FireDetectionModel,
  "17": InAndOutModel,
  "18": TempAndHumidityModel,
  "34": EmergencyModel,
  SPOT: SpotModel,
  GATE: GateModel,
  GATE_3D: AiGateModel,
  // Help
  "19-help": FireDetectionHelpModel,
  "17-help": InAndOutHelpModel,
  "18-help": TempAndHumidityHelpModel,
  "34-help": EmergencyHelpModel,
  "SPOT-help": SpotHelpModel,
  "GATE-help": GateHelpModel,
  "GATE_3D-help": AiGateHelpModel,

  //Battery
  "battery-1": Battery1,
  "battery-2": Battery2,
  "battery-3": Battery3,
  "battery-4": Battery4,
  "battery-5": Battery5,

  //Network
  "network-1": Network1,
  "network-2": Network2,
  "network-3": Network3,
  "network-4": Network4,
  "network-5": Network5,

  //react-icons
  "group-by": VscGroupByRefType,
  "ungroup-by": VscUngroupByRefType,
};

export type IconKey = keyof typeof icons;

type Props = SVGProps<SVGSVGElement> & {
  name: IconKey;
};

const MssIcon = (props: Props) => {
  const Icon = icons[props.name as IconKey];

  return <Icon {...props} />;
};

export default MssIcon;
