import { Instance, types } from "mobx-state-tree";
import EmergencySectionOne, { EmergencySectionOneType } from "./EmergencySectionOne";
import EmergencySectionTwo, { EmergencySectionTwoType } from "./EmergencySectionTwo";

const SensorEmergencyModel = types
  .model({
    sectionOne: types.optional(EmergencySectionOne, {} as EmergencySectionOneType),
    sectionTwo: types.optional(EmergencySectionTwo, {} as EmergencySectionTwoType),
  })
  .actions((self) => ({
    refresh(sensorId: string) {
      self.sectionOne.init(sensorId);
      self.sectionTwo.init(sensorId);
    },
  }));

export type SensorEmergencyModelType = Instance<typeof SensorEmergencyModel>;
export default SensorEmergencyModel;
