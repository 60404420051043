import Grid from "@mui/material/Grid";
import { observer } from "mobx-react-lite";
import { useDetailedDataStore } from "../../../../../contexts/DetailedDataContext";
import AiGateLineAndTable from "./AiGateLineAndTable";
import "../more-detailed.css";
import { SensorDetailProps } from "../DoorMoreDetailed/DoorMoreDetailed";
import { useEffect } from "react";

const AiGateMoreDetailed = observer(({ main, sensorId, refresh }: SensorDetailProps) => {
  const { master, slave } = useDetailedDataStore();
  const aiGate = main ? master.aiGate : slave.aiGate;

  return (
    <Grid container className="more-detail-box" paddingX={"0px"}>
      <AiGateLineAndTable {...aiGate.line} />
    </Grid>
  );
});

export default AiGateMoreDetailed;
