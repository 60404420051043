import Grid from "@mui/material/Grid";
import Lottie from "lottie-react";
import EmptyAnim from "../../resources/lottie/404.json";
import Button from "../common/Button";

const HubEmptyList = () => {
  return (
    <Grid container flexDirection={"column"} alignItems="center">
      <Grid item marginTop={"30px"}>
        <Lottie animationData={EmptyAnim} loop style={{ height: 170 }} />
      </Grid>
      <span style={{ marginTop: "0px", fontWeight: "bold", fontSize: "20px" }}>연결된 센서가 없음</span>
      <span style={{ marginTop: "10px" }}>검색된 센서가 없어요. 재연결 후 새로고침 해주세요.</span>
      <Button sx={{ marginTop: "15px" }}>새로고침</Button>
      <Button btnType="text" sx={{ marginTop: "5px" }}>
        문제가 있나요?
      </Button>
    </Grid>
  );
};

export default HubEmptyList;
