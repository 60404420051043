import { Instance, types } from "mobx-state-tree";
import api from "../../../api/api";

const TemperatureModel = types.model({
  current_temp: types.optional(types.number, 0, ["--", undefined]),
  highest_temp: types.optional(types.number, 0, ["--", undefined]),
  avg_temp: types.optional(types.number, 0, ["--", undefined]),
  lowest_temp: types.optional(types.number, 0, ["--", undefined]),
});

const HumidityModel = types.model({
  current_humid: types.optional(types.number, 0, ["--", undefined]),
  highest_humid: types.optional(types.number, 0, ["--", undefined]),
  avg_humid: types.optional(types.number, 0, ["--", undefined]),
  lowest_humid: types.optional(types.number, 0, ["--", undefined]),
});

const TempAndHumidityDataModel = types.model({
  line: types.frozen(),
  bar: types.frozen(),
  table: types.frozen<TempAndHumidityTableType>(),
});

const TempAndHumiditySectionOne = types
  .model({
    startDate: types.maybeNull(types.string),
    endDate: types.maybeNull(types.string),
    temperature: types.optional(TemperatureModel, {} as TemperatureModelType),
    humidity: types.optional(HumidityModel, {} as HumidityModelType),
    data: types.optional(TempAndHumidityDataModel, {} as TempAndHumidityDataModelType),
  })
  .actions((self) => ({
    setStartDate(startDate: typeof self.startDate) {
      self.startDate = startDate;
    },
    setEndDate(endDate: typeof self.endDate) {
      self.endDate = endDate;
    },
    setTemperature(temperature: TemperatureModelType) {
      self.temperature = temperature;
    },
    setHumidity(humidity: HumidityModelType) {
      self.humidity = humidity;
    },
    setData(data: TempAndHumidityDataModelType) {
      self.data = data;
    },
  }))
  .views((self) => ({
    get dateRange() {
      return { start_date: self.startDate, end_date: self.endDate };
    },
  }))
  .actions((self) => ({
    fetchTemperature: async (sensorId: string) => {
      const res = await api.get<TemperatureModelType>(`/v1/sensor/temp-humidity/${sensorId}/temp`, self.dateRange);
      if (!res.ok || !res.data) return;
      self.setTemperature(res.data);
    },
    fetchHumidity: async (sensorId: string) => {
      const res = await api.get<HumidityModelType>(`/v1/sensor/temp-humidity/${sensorId}/humid`, self.dateRange);
      if (!res.ok || !res.data) return;

      self.setHumidity(res.data);
    },

    fetchTableAndLine: async (sensorId: string) => {
      const res = await api.get<TempAndHumidityDataModelType>(
        `/v1/sensor/temp-humidity/${sensorId}/table`,
        self.dateRange
      );
      if (!res.ok || !res.data) return;

      self.setData(res.data);
    },

    init(sensorId: string) {
      this.fetchTemperature(sensorId);
      this.fetchHumidity(sensorId);
      this.fetchTableAndLine(sensorId);
    },
  }));

type TempAndHumidityTableType = Array<{
  id: number;
  serial_number: string;
  value: number;
  alive: number;
  battery: number;
  temper: number;
  humid: number;
  rssi: number;
  created_at: string;
}>;
type TempAndHumidityDataModelType = Instance<typeof TempAndHumidityDataModel>;
type TemperatureModelType = Instance<typeof TemperatureModel>;
type HumidityModelType = Instance<typeof HumidityModel>;
export type TempAndHumiditySectionOneType = Instance<typeof TempAndHumiditySectionOne>;
export default TempAndHumiditySectionOne;
