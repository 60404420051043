import { Instance, types } from "mobx-state-tree";
import api from "../../../api/api";

const FireDetectionSectionTwo = types
  .model({
    startDate: types.maybeNull(types.string),
    endDate: types.maybeNull(types.string),
    lineData: types.frozen(),
  })
  .actions((self) => ({
    setStartDate(startDate: string | null) {
      self.startDate = startDate;
    },
    setEndDate(endDate: string | null) {
      self.endDate = endDate;
    },
    setLineData(lineData: any) {
      self.lineData = lineData;
    },
  }))
  .actions((self) => ({
    fetchLineData: async (sensorId: string) => {
      const res = await api.get<{ data: any }>(`/v1/sensor/fire-detection/${sensorId}/daily-detect-sensor`, {
        start_date: self.startDate,
        end_date: self.endDate,
      });

      if (!res.ok || !res.data) return;

      self.setLineData(res.data.data);
    },
    init(sensorId: string) {
      this.fetchLineData(sensorId);
    },
  }));

export type FireDetectionSectionTwoType = Instance<typeof FireDetectionSectionTwo>;

export default FireDetectionSectionTwo;
