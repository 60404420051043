import { Box, Grid, IconButton, Tooltip, Zoom } from "@mui/material";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePlaceStore } from "../../contexts/PlaceContext";
import { useUserSensorListStore } from "../../contexts/UserSensorContext";
import EmptyList from "../common/EmptyList";
import MssIcon from "../common/MssIcon";
import GroupedSensorList from "./sensor/GroupedSensorList";
import SensorOverview from "./sensor/SensorOverview";

const Sensors = observer(() => {
  const { selectedPlace } = usePlaceStore();
  const { getGroupedBySensorType, getPlaceSensorList, init, list } = useUserSensorListStore();
  const navigate = useNavigate();
  const [grouped, setGrouped] = useState(true);

  const sensors = getPlaceSensorList(selectedPlace ? selectedPlace.uuid : "");

  useEffect(() => {
    init();
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "#F7F7FC",
        padding: "33px",
        height: "100vh",
        paddingLeft: "33px",
        paddingTop: "70px",
        overflowY: "scroll",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        "::-webkit-scrollbar": { display: "none" },
        flex: 1,
      }}
    >
      <Grid
        container
        flexDirection={"column"}
        sx={{ position: "sticky", top: "0px", backgroundColor: "#F7F7FC", paddingTop: "33px", zIndex: 10 }}
      >
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems={"center"}
          sx={{ borderBottom: "1px solid #ABAABA", paddingBottom: "10px" }}
        >
          <span
            style={{
              color: "#333",
              fontSize: "24px",
              fontWeight: "bold",
              margin: "0px",
            }}
          >
            {selectedPlace?.name}
          </span>
          <Tooltip
            title={grouped ? "센서별 그룹해제" : "센서별 그룹"}
            placement="top"
            TransitionComponent={Zoom}
            arrow
          >
            <IconButton onClick={() => setGrouped(!grouped)}>
              {grouped ? (
                <MssIcon name="ungroup-by" fontSize={"20px"} />
              ) : (
                <MssIcon name="group-by" fontSize={"20px"} />
              )}
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item display={"flex"} justifyContent="space-between" paddingTop={"30px"} paddingBottom={"10px"}>
          <span style={{ color: "#7D88B0", fontSize: "16px", fontWeight: "bold" }}>설치된 센서</span>
          <span style={{ color: "#ABAABA", fontSize: "12px" }}>{moment().format("YYYY. MM. DD HH:mm ")} 기준</span>
        </Grid>
      </Grid>
      <Grid item flexDirection="column" sx={{ paddingBottom: "100px", paddingX: "2px" }}>
        {sensors.length > 0 ? (
          grouped ? (
            getGroupedBySensorType(selectedPlace ? selectedPlace.uuid : "").map((group) => (
              <GroupedSensorList key={group.type + selectedPlace?.uuid} sensors={group.sensors} />
            ))
          ) : (
            sensors.map((sensor) => <SensorOverview key={sensor.uuid} sensor={sensor} />)
          )
        ) : (
          <EmptyList
            message="등록된 센서가 없습니다"
            label="센서 등록하기"
            callback={() => {
              navigate("/sensor-register");
            }}
          />
        )}
      </Grid>
    </Box>
  );
});

export default Sensors;
