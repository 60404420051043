import {
  SENSOR_TYPE_DOOR,
  SENSOR_TYPE_FIRE,
  SENSOR_TYPE_GATE,
  SENSOR_TYPE_GATE_3D,
  SENSOR_TYPE_SOS,
  SENSOR_TYPE_SPOT,
  SENSOR_TYPE_TEMP_HUMIDITY,
} from "../constants/EmbeddedSensors";

export const getSensorInfo = (uniqueId: string, type: string) => {
  let id = uniqueId.split(".")[1];
  const n = id ? id.slice(3).slice(0, -1) : 0;
  const data = {
    en: "",
    kr: "",
    number: n,
  };

  switch (type) {
    case SENSOR_TYPE_SPOT:
      data.en = "SPOT";
      data.kr = "스팟";
      break;
    case SENSOR_TYPE_GATE:
      data.en = "GATE";
      data.kr = "GATE";
      break;
    case SENSOR_TYPE_GATE_3D:
      data.en = "AI-GATE";
      data.kr = "AI-GATE";
      break;
    case SENSOR_TYPE_DOOR:
      data.en = "DOOR";
      data.kr = "문열림";
      break;
    case SENSOR_TYPE_TEMP_HUMIDITY:
      data.en = "TEMP";
      data.kr = "온습도";
      break;
    case SENSOR_TYPE_FIRE:
      data.en = "FIRE";
      data.kr = "화재감지";
      break;
    case SENSOR_TYPE_SOS:
      data.en = "SOS";
      data.kr = "비상SOS벨";
      break;
  }
  return data;
};
