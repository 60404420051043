import Grid from "@mui/material/Grid";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { FireDetectionSectionTwoType } from "../../../../../models/detailed-data/fire-detection/FireDetectionSectionTwo";
import { MssDateRangePicker } from "../common/MssDatePicker";
import { EMPTY_CHART } from "../DoorMoreDetailed/DoorConsts";

import { EMERGENCY_SECTION_FOUR_COLUMN_OPTIONS } from "./FireDetectionConstants";

type Props = {
  sectionTwo: FireDetectionSectionTwoType;
  sensorId: string;
};

const FireDetectionSectionTwo = observer(({ sectionTwo, sensorId }: Props) => {
  const { startDate, endDate, setStartDate, setEndDate, lineData, init } = sectionTwo;

  useEffect(() => {
    if (startDate && endDate) {
      init(sensorId);
    }

    if (!startDate && !endDate) {
      setStartDate(moment().add(-7, "days").format("YYYY-MM-DD"));
    }
  }, [startDate, endDate]);

  return (
    <Grid item container marginTop={"70px"}>
      <Grid container>
        <Grid item flex={1}>
          <span className="title">실내 화재 감지 누적 히스토리</span>
          <span className="sub-title">최근 1주일간의 화재 센서 감지 정보를 분석합니다.</span>
        </Grid>
        <Grid item display="flex" justifyContent={"flex-end"} alignItems="center">
          <MssDateRangePicker
            startDate={startDate}
            endDate={endDate}
            setEndDate={setEndDate}
            setStartDate={setStartDate}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item flex={1}>
          <ReactApexChart
            options={EMERGENCY_SECTION_FOUR_COLUMN_OPTIONS}
            series={lineData ? lineData : EMPTY_CHART}
            type="bar"
            height={350}
          />
        </Grid>
      </Grid>
    </Grid>
  );
});

export default FireDetectionSectionTwo;
