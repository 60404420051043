import Grid from "@mui/material/Grid";
import { observer } from "mobx-react-lite";
import { useSensorRegisterStore } from "../../../contexts/SensorRegisterContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SensorIdInput from "../../common/SensorIdInput";
import Button from "../../common/Button";
import MssIcon from "../../common/MssIcon";

const MAX_LENGTH = 10;

const Hub = observer(() => {
  const { stepper, hub } = useSensorRegisterStore();
  const navigate = useNavigate();

  return (
    <>
      <Grid container flexDirection={"column"}>
        <span className="title">센서를 연결할 HUB의 일련번호를 입력해주세요</span>
        <span className="sub-title">
          센서의 데이터를 확인하기 위해서는 센서와 Realstep HUB를 연결하여야 합니다. <br />
          구매하신 HUB 뒷면에 적혀있는 10자리 일련번호를 입력해주세요!
        </span>
      </Grid>

      <Grid container display={"flex"} marginTop={"67px"}>
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <SensorIdInput
            length={MAX_LENGTH}
            onEnterPressed={() => {
              if (hub.uuid.length !== MAX_LENGTH) return toast.error("허브 시리얼 번호 전체를 입력해 입력해주세요");
              stepper.nextStep();
            }}
            onChange={(v) => hub.setUUID(v)}
          />

          <Grid container alignItems={"center"} justifyContent="center" mt={"60px"}>
            <Grid item>
              <MssIcon name="hub" className="sensor-model" />
            </Grid>
            <Grid item>
              <table>
                <tbody>
                  <tr>
                    <td className="start">센서 종류</td>
                    <td className="end">Realstep HUB</td>
                  </tr>
                  <tr>
                    <td className="start">센서 명칭</td>
                    <td className="end">특정소출력 무선기기</td>
                  </tr>
                  <tr>
                    <td className="start">모 델 명</td>
                    <td className="end">R-C-H52-RAY-HUB2_HS</td>
                  </tr>
                </tbody>
              </table>
            </Grid>
          </Grid>

          <Grid container marginTop={"50px"} justifyContent={"space-between"}>
            <Button btnType="text" onClick={() => navigate("/sensor-register")}>
              {"< 이전"}
            </Button>

            <Button
              btnType={hub.uuid.length === MAX_LENGTH ? "default" : "disabled"}
              onClick={() => hub.uuid.length === MAX_LENGTH && stepper.nextStep()}
              tooltipLabel={hub.uuid.length === MAX_LENGTH ? "다음" : "센서의 MAC 주소를 입력해주세요"}
            >
              다음 단계
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
});

export default Hub;
