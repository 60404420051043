import { Button as MuiButton, ButtonProps, CircularProgress, SxProps, Tooltip, Zoom } from "@mui/material";

const baseSx: SxProps = {
  borderRadius: "100px",
  textTransform: "none",
  paddingX: "30px",
  paddingY: "5px",
  fontWeight: "bold",
};

const defaultSx: SxProps = {
  backgroundColor: "#50CDD0",
  border: "1px solid #50CDD0",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#46B9BC",
  },
};

const secondarySx: SxProps = {
  backgroundColor: "transparent",
  color: "#333",
  border: "1px solid #333",
  "&:hover": {
    backgroundColor: "transparent",
  },
};

const disabledSx: SxProps = {
  backgroundColor: "#D7D7D7",
  color: "#fff",
  border: "1px solid #D7D7D7",
  "&:hover": {
    backgroundColor: "#D7D7D7",
  },
};

const textSx: SxProps = {
  backgroundColor: "transparent",
  color: "#ABAABA",
  border: "1px solid transparent",
  "&:hover": {
    backgroundColor: "transparent",
  },
};

const dangerSx: SxProps = {
  backgroundColor: "#f34541",
  color: "#fff",
  border: "1px solid #f34541",
  "&:hover": {
    backgroundColor: "#f34541",
  },
};

const styles: { secondary: SxProps; text: SxProps; disabled: SxProps; default: SxProps; danger: SxProps } = {
  secondary: secondarySx,
  text: textSx,
  disabled: disabledSx,
  default: defaultSx,
  danger: dangerSx,
};

type Props = {
  btnType?: keyof typeof styles;
  loading?: boolean;
  tooltipLabel?: string;
} & ButtonProps;

const Button = (props: Props) => {
  const sx = {
    ...baseSx,
    ...styles[props.btnType ? props.btnType : "default"],
    ...props.sx,
  };

  const newProps = { ...props };
  delete newProps.btnType;
  delete newProps.loading;
  delete newProps.tooltipLabel;

  return (
    <Tooltip title={props.tooltipLabel ? props.tooltipLabel : ""} placement="top" arrow TransitionComponent={Zoom}>
      <MuiButton disabled={props.loading} {...newProps} sx={sx}>
        {props.loading && <CircularProgress size={20} sx={{ marginRight: "10px" }} color="inherit" />} {props.children}
      </MuiButton>
    </Tooltip>
  );
};

export default Button;
