import Grid from "@mui/material/Grid";
import MssIcon from "../../common/MssIcon";

const EmergencyHelp = () => {
  return (
    <>
      <Grid container flexDirection={"column"} alignItems="center">
        <span className="title" style={{ textAlign: "center", marginTop: "84px" }}>
          등록이 완료되었습니다 <br />
          이제 피플카운트 시스템을 사용해볼까요?
        </span>
        <span style={{ marginTop: "13px" }}>
          화재 센서를 이용해 실내 공간의 화재 및 비상 상황을 손쉽게 파악할 수 있습니다.
        </span>
      </Grid>

      <Grid container display={"flex"} marginTop={"67px"}>
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <Grid container alignItems={"center"} justifyContent="center">
            <Grid item md={3}>
              <MssIcon name="34-help" className="sensor-model" />
            </Grid>
            <Grid item md={6} display={"flex"} flexDirection={"column"} sx={{ color: "#7D88B0" }}>
              <span style={{ fontWeight: "bold", fontSize: "16px" }}>[올바른 센서 설치 안내]</span>
              <ul style={{ marginTop: "13px" }}>
                <li>스마트 허브 후면의 등록버튼을 눌러 센서 등록 준비를 해주세요.</li>
                <li>
                  <b>SOS</b> 버튼 뒷면에 나사를 풀고 배터리 사이에 있는 종이(간지)를 빼내어 전원을 연결해주세요.
                </li>
                <li>
                  <b>10</b>초 이내에 스마트 허브에서 ‘삐리리’ 소리가 나면 등록이 완료됩니다.
                </li>
              </ul>
              <span style={{ marginTop: "13px" }}> ※ 가급적 (거주)공간 내 접근이 쉬운 위치에 설치하세요!</span>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default EmergencyHelp;
