import { create } from "apisauce";

// BASE URL
const BASE_URL = "https://api.mss.zeroweb.kr"; // Production server
// const BASE_URL = "https://api.mss.zeroweb.kr"; // Development server
// const BASE_URL = 'http://localhost:3567';   // Local server

const api = create({
  baseURL: BASE_URL,
  timeout: 30000,
});

export default api;
