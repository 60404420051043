import { createRef, forwardRef, memo, RefObject, useEffect, useState } from "react";
import { onEnterKeyPressed } from "../../utils/InputUtils";
import { StyleSheet } from "../dashboard/sensor/overview/SpotDataInDetail";

type InputBoxProps = {
  setFocused: Function;
  index: number;
  ref: RefObject<HTMLInputElement | null>;
};

const valueValidate = /^[a-zA-Z0-9]{0,1}$/;
const InputBox = forwardRef<HTMLInputElement | null, InputBoxProps>(({ setFocused, index }: InputBoxProps, ref) => {
  const [value, setValue] = useState<string>("");
  const onChange = (v: string) => {
    if (!valueValidate.test(v)) return;
    v.length < 2 && setValue(v);
  };

  useEffect(() => {
    if (value === "") {
      setFocused(index - 1);
    }
    if (value.length >= 1) setFocused(index + 1);
  }, [value]);

  return (
    <div style={styles.inputBox}>
      <input
        onKeyDown={(e) => {
          if (e.key === "8" || e.key === "Backspace") {
            value === "" && setFocused(index - 1);
          }
        }}
        ref={ref}
        style={styles.codeBox}
        onFocus={(e) => {
          e.target.select();
          setFocused(index);
        }}
        type="text"
        onPaste={(e) => console.log(e.clipboardData.getData("Text"))}
        onChange={(e) => onChange(e.target.value)}
        value={value}
      />
    </div>
  );
});

type Props = {
  length: number;
  onChange: (value: string) => void;
  onEnterPressed?: Function;
  containerClassName?: string;
};

const SensorIdInput = memo(({ length, onChange, onEnterPressed, containerClassName }: Props) => {
  const [focused, setFocused] = useState(0);
  const elRefs = Array.from({ length }, () => createRef<HTMLInputElement | null>());
  const inputRef = createRef<HTMLDivElement>();

  const setValidFocus = (focus: number) => {
    const l = elRefs.slice().map((i) => i.current?.value);
    onChange(l.toString().replaceAll(",", ""));
    if (focus < 0 || focus >= length) return;

    setFocused(focus);
  };

  useEffect(() => {
    elRefs[focused].current?.focus();
  }, [focused]);

  useEffect(() => {
    if (!inputRef.current || !onEnterPressed) return;
    inputRef.current.addEventListener("keydown", (e) => onEnterKeyPressed(e, onEnterPressed));
    return () => {
      if (!inputRef.current || !onEnterPressed) return;
      inputRef.current.removeEventListener("keydown", (e) => onEnterKeyPressed(e, onEnterPressed));
    };
  }, []);

  const renderInputBoxes = () => {
    const inputBoxArr = [];
    for (let i = 0; i < length; i++) {
      inputBoxArr.push(<InputBox key={`${"inputbox"}-${i}`} ref={elRefs[i]} setFocused={setValidFocus} index={i} />);
    }
    return inputBoxArr;
  };

  return (
    <div ref={inputRef} style={styles.input} className={containerClassName}>
      {renderInputBoxes()}
    </div>
  );
});

const styles = StyleSheet.create({
  input: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "100%",
    alignItems: "center",
  },
  inputBox: {
    minHeight: "42px",
    flex: 1,
    display: "flex",
    justifyContent: "center",
  },
  codeBox: {
    border: "none",
    borderBottom: "2px solid #abaaba",
    fontSize: "36px",
    color: "#abaaba",
    minWidth: "42px",
    outline: "none",
    width: "42px",
    textAlign: "center",
    backgroundColor: "transparent",
    textTransform: "capitalize",
  },
});

export default SensorIdInput;
