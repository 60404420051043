import { Button, Grid } from "@mui/material";
import { ApexOptions } from "apexcharts";
import { observer } from "mobx-react-lite";
import ReactApexChart from "react-apexcharts";
import { EmergencySectionOneType } from "../../../../../models/detailed-data/emergency/EmergencySectionOne";
import CountUp from "react-countup";
import { CSVLink } from "react-csv";
import { MssDateRangePicker } from "../common/MssDatePicker";
import { DataGrid, GridRowParams } from "@mui/x-data-grid";
import { columns } from "./EmergencyConstants";
import { TableStyle } from "../GateMoreDetailed/GateSectionOne";
import { useEffect } from "react";
import moment from "moment";

type Props = {
  sectionOne: EmergencySectionOneType;
  sensorId: string;
};

const EmergencySectionOne = observer(({ sectionOne, sensorId }: Props) => {
  const { startDate, endDate, setStartDate, setEndDate, summary, tableData, init, pieData } = sectionOne;

  const chartOptions: ApexOptions = {
    labels: pieData.labels,
    legend: {
      show: false,
    },
    noData: {
      text: "데이터 없습",
    },
    colors: ["#80C2FF", "#FF8080"],
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -35,
        },
      },
    },
  };

  useEffect(() => {
    if (startDate && endDate) {
      init(sensorId);
    }

    if (!startDate && !endDate) {
      setStartDate(moment().format("YYYY-MM-DD"));
    }
  }, [startDate, endDate]);

  return (
    <Grid item container>
      <Grid container>
        <Grid item flex={1}>
          <span className="title">전체 비상상황 알림 데이터 (최근 24시간)</span>
        </Grid>
        <Grid item>
          <MssDateRangePicker
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </Grid>
      </Grid>

      <Grid container sx={{ flex: 1, justifyContent: "space-around", alignItems: "center", marginTop: "50px" }}>
        <Grid
          item
          md={6}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#F7F7FC",
            padding: "45px 30px",
            borderRight: "9px solid #fff",
          }}
        >
          <span style={{ fontSize: "18px" }}>금일 감지 횟수</span>
          <span style={{ fontWeight: "bold", fontSize: "24px" }}>
            <CountUp end={summary.sensorSummary.detected_time} duration={1} /> 회
          </span>
        </Grid>
        <Grid
          item
          md={6}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#F7F7FC",
            padding: "45px 30px",
            borderLeft: "9px solid #fff",
          }}
        >
          <span style={{ fontSize: "18px" }}>최근 감지 시간</span>
          <span style={{ fontWeight: "bold", fontSize: "24px" }}>{summary.sensorSummary.latest_detected_time}</span>
        </Grid>
      </Grid>

      <Grid container marginTop={"40px"}>
        <Grid item md={6}>
          <ReactApexChart options={chartOptions} series={pieData.series} type="pie" height={200} width={"100%"} />
        </Grid>
        <Grid item md={6} sx={{ display: "flex", alignItems: "center" }}>
          <Grid item container alignItems={"center"} justifyContent="center">
            <Grid
              item
              sx={{ backgroundColor: "#F7F7FC", display: "flex", height: "88px", width: "270px", borderRadius: "5px" }}
            >
              <Grid
                item
                md={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-around",
                  fontWeight: "bold",
                }}
              >
                <span style={{ fontSize: "18px", color: "#80C2FF" }}>정상신호</span>
                <span style={{ fontSize: "20px" }}>
                  <CountUp end={summary.data ? summary.data.find((obj) => obj.id === "정상").value : 0} duration={1} />
                  회
                </span>
              </Grid>

              <Grid
                item
                md={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-around",
                  fontWeight: "bold",
                }}
              >
                <span style={{ fontSize: "18px", color: "#FF8080" }}>비정상신호</span>
                <span style={{ fontSize: "20px" }}>
                  <CountUp
                    end={summary.data ? summary.data.find((obj) => obj.id === "비정상").value : 0}
                    duration={1}
                  />
                  회
                </span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container justifyContent={"flex-end"}>
        <Grid item>
          <CSVLink data={tableData ? tableData : []} filename={`Fire-sensor-data-for-${startDate}`}>
            <Button sx={{ color: "#333", borderRadius: "30px", border: "1px solid #333", minWidth: "125px" }}>
              데이터 추출하기
            </Button>
          </CSVLink>
        </Grid>
      </Grid>

      <Grid container marginTop={"20px"}>
        <Grid item flex={1} height={"300px"}>
          <DataGrid
            rows={tableData ? tableData : []}
            columns={columns}
            //   disableSelectionOnClick
            sx={TableStyle}
            hideFooter
            // onRowClick={(params: GridRowParams) => {
            //   console.log(params);
            // }}
            isRowSelectable={(params: GridRowParams) => {
              return true;
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
});

export default EmergencySectionOne;
