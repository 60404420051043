import RootRouter from "./routes/RootRouter";
import { RootStoreProvider } from "./contexts/RootContext";
import { createTheme, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "animate.css";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
moment.updateLocale(moment.locale(), { invalidDate: "데이터 없음" });

function App() {
  const theme = createTheme({
    typography: {
      fontFamily: "Roboto",
      h3: {
        fontSize: "20px",
        fontWeight: "bold",
        color: "#333",
      },
    },
    palette: {
      mode: "light",
      background: {
        default: "#f9f9f9",
      },
    },
  });
  return (
    <RootStoreProvider>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <RootRouter />
        </LocalizationProvider>
      </ThemeProvider>
    </RootStoreProvider>
  );
}

export default App;
