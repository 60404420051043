import Grid from "@mui/material/Grid";
import { observer } from "mobx-react-lite";
import EmergencySectionOne from "./EmergencySectionOne";
import EmergencySectionTwo from "./EmergencySectionTwo";
import { SensorDetailProps } from "../DoorMoreDetailed/DoorMoreDetailed";
import { useDetailedDataStore } from "../../../../../contexts/DetailedDataContext";
import { useEffect } from "react";

const EmergencyMoreDetailed = observer(({ main, sensorId, refresh }: SensorDetailProps) => {
  const { master, slave } = useDetailedDataStore();
  const emergency = main ? master.emergency : slave.emergency;

  useEffect(() => {
    emergency.refresh(sensorId);
  }, [refresh]);

  return (
    <Grid container className="more-detail-box">
      <EmergencySectionOne sectionOne={emergency.sectionOne} sensorId={sensorId} />
      <EmergencySectionTwo sectionTwo={emergency.sectionTwo} sensorId={sensorId} />
    </Grid>
  );
});

export default EmergencyMoreDetailed;
