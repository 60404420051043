import { Link, LinkProps, useMatch, useResolvedPath } from "react-router-dom";

type CustomLinkProps = LinkProps & {
  class?: string;
  matchEnd?: boolean;
};

export function CustomLink({ children, to, ...props }: CustomLinkProps) {
  let resolved = useResolvedPath(to);
  const matchEnd = props.matchEnd !== undefined ? props.matchEnd : true;
  let match = useMatch({ path: resolved.pathname, end: matchEnd });

  return (
    <Link className={match ? `${props.class} active` : props.class} to={to} onClick={props.onClick}>
      {children}
    </Link>
  );
}
