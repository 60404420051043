import { Button, Grid } from "@mui/material";
import { DataGrid, GridRowParams } from "@mui/x-data-grid";
import { ApexOptions } from "apexcharts";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { SpotSectionOneType } from "../../../../../models/detailed-data/spot/SpotSectionOne";
import { MssDateRangePicker } from "../common/MssDatePicker";
import { columns, EMPTY_CHART } from "../DoorMoreDetailed/DoorConsts";
import { CSVLink } from "react-csv";
import { TableStyle } from "../GateMoreDetailed/GateSectionOne";

const options: ApexOptions = {
  chart: {
    type: "area",
    height: 350,
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  },
  colors: ["#6AE1E3"],
  fill: {
    gradient: {},
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "straight",
  },

  legend: {
    horizontalAlign: "left",
  },
};

type Props = {
  sectionOne: SpotSectionOneType;
  sensorId: string;
};

const SpotSectionOne = observer(({ sectionOne, sensorId }: Props) => {
  const { startDate, endDate, setStartDate, setEndDate, lineData, tableData, init } = sectionOne;

  useEffect(() => {
    if (startDate && endDate) {
      init(sensorId);
    }
  }, [startDate, endDate]);

  return (
    <Grid container marginTop="50px">
      <Grid container justifyContent="space-between">
        <Grid item>
          <span className="title">전체 유동인구 감지 데이터 (최근 24시간)</span>
          <span className="sub-title">각 실내 공간별 설정 기간에 따른 유동인구 변화 추이를 나타냅니다</span>
        </Grid>
        <Grid item>
          <MssDateRangePicker
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            // customInput={<DateInput />}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item flex={1}>
          <ReactApexChart options={options} series={lineData ? [lineData] : EMPTY_CHART} type="area" height={300} />
        </Grid>
      </Grid>
      <Grid container flexDirection={"column"} alignItems="flex-end">
        <CSVLink data={tableData ? tableData : []} filename={`spot-sensor-data-for-${startDate}`}>
          <Button
            variant="outlined"
            sx={{
              alignSelf: "flex-end",
              borderRadius: "100px",
              paddingX: "20px",
              color: "#333333",
              borderColor: "#333333",
            }}
          >
            데이터 추출하기
          </Button>
        </CSVLink>
        <Grid item container height={"300px"}>
          <DataGrid
            rows={tableData ? tableData : []}
            columns={columns}
            //   disableSelectionOnClick
            sx={TableStyle}
            hideFooter
            // onRowClick={(params: GridRowParams) => {
            //   console.log(params);
            // }}
            isRowSelectable={(params: GridRowParams) => {
              return true;
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
});

export default SpotSectionOne;
