import { Divider, Grid, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useRootStore } from "../../contexts/RootContext";
import Button from "../common/Button";
import MssIcon from "../common/MssIcon";
import PasswordModifyModal from "../modals/PasswordModifyModal";

const ProfileEdit = observer(() => {
  const { myPageStore } = useRootStore();
  const { member } = myPageStore;
  const [passwordModalShow, setPasswordModalShow] = useState(false);
  useEffect(() => {
    myPageStore.fetchMember();
  }, []);

  return (
    <>
      <PasswordModifyModal
        open={passwordModalShow}
        onClose={() => {
          setPasswordModalShow(false);
        }}
      />
      <Typography variant="h3" marginTop={"46px"}>
        기본정보
      </Typography>
      <Divider sx={{ marginTop: "10px", marginBottom: "15px" }} />
      <Grid container alignItems={"center"} rowSpacing="12px">
        <Grid item md={2}>
          <Typography className="input-label">아이디</Typography>
        </Grid>
        <Grid item md={10} container>
          <input className="input" value={member.username} onChange={(e) => {}} />
        </Grid>
        <Grid item md={2}>
          <Typography className="input-label">비밀번호</Typography>
        </Grid>
        <Grid item md={10} container justifyContent={"flex-end"}>
          <Button
            btnType="secondary"
            onClick={() => {
              setPasswordModalShow(true);
            }}
          >
            비밀번호 수정
          </Button>
        </Grid>
        {/* <Grid item md={2}>
          <Typography className="input-label">비밀번호</Typography>
        </Grid>
        <Grid item md={10} container>
          <input type="password" className="input" />
        </Grid>
        <Grid item md={2}>
          <Typography className="input-label">비밀번호 확인</Typography>
        </Grid>
        <Grid item md={10} container>
          <input type="password" className="input" />
        </Grid> */}
        {/* <Grid item md={2}>
          <Typography className="input-label">
            휴대전화 <span style={{ color: "#50CDD0" }}>*</span>
          </Typography>
        </Grid>
        <Grid item md={10} container display={"flex"} alignItems="center">
          <input type="text" maxLength={3} className="input phone-number" style={{ width: "137px" }} />
          <span className="dash">-</span>
          <input type="text" maxLength={4} className="input phone-number" />
          <span className="dash">-</span>
          <input type="text" maxLength={4} className="input phone-number" />
        </Grid> */}
      </Grid>
      <Divider sx={{ marginTop: "40px", marginBottom: "20px" }} />
      <Grid container>
        <Grid item width={"150px"}>
          <Typography marginTop={"13px"}>계정 연결정보</Typography>
        </Grid>
        <Grid item flex={1} container>
          <Grid item container alignItems={"center"}>
            <Grid item container width={"112px"} alignItems={"center"}>
              <MssIcon name="user" width={"26px"} />
              <Typography marginLeft={"8px"}>KAKAO</Typography>
            </Grid>
            <Typography>카카오 계정이 연결되었습니다</Typography>
          </Grid>
          <Grid item container alignItems={"center"}>
            <Grid item container width={"112px"} alignItems={"center"}>
              <MssIcon name="user" width={"26px"} />
              <Typography marginLeft={"8px"}>NAVER</Typography>
            </Grid>
            <Typography>연결된 정보가 없습니다</Typography>
          </Grid>
          <Grid item></Grid>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: "20px" }} />
    </>
  );
});

export default ProfileEdit;
