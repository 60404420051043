import { PlaceRegisterResponse } from "./../../../types/mss-place";
import { Instance, types } from "mobx-state-tree";
import { toast } from "react-toastify";
import api from "../../api/api";
import { SENSORS_LIST } from "../../contexts/dummies";
import { STEPS } from "../../contexts/SensorRegisterContext";
import SensorLocationModel from "./SensorLocationModel";
import SensorModel from "./SensorModel";
import StepperModel from "./StepperModel";
import SensorHubModel from "./SensorHubModel";

const SensorRegisterModel = types
  .model({
    stepper: StepperModel,
    sensor: SensorModel,
    location: SensorLocationModel,
    hub: SensorHubModel,
  })
  .actions((self) => ({
    registerSensor: async (uuid: string, callback: (bool: boolean) => void) => {
      const toastId = "sensor-register";
      toast.loading("Registering sensor", {
        toastId,
      });
      const sensorData = {
        type: self.sensor.selectedType,
        sensor_id: self.sensor.uniqueId,
        distance: self.sensor.distance,
        name: self.sensor.name,
      };
      const res = await api.post<{ code: string }>(`/v1/place/${uuid}/sensor`, sensorData);

      if (!res.ok) {
        const msg =
          res.data?.code === "DUPLICATED_SENSOR_ID_IN_THE_PLACE"
            ? "이미 등록된 장소입니다"
            : "서버문제로 다시 시도해주세요";

        toast.update(toastId, {
          type: "error",
          render: msg,
          hideProgressBar: true,
          isLoading: false,
        });
        callback(false);
      } else {
        toast.update(toastId, {
          type: "success",
          render: "센서가 성공적으로 등록되었습니다",
          hideProgressBar: true,
          isLoading: false,
        });
        callback(true);
      }

      setTimeout(() => {
        toast.dismiss(toastId);
      }, 2000);
    },
    registerPlace: async (callback?: (uuid: string) => void) => {
      if (!self.location.selected?.road_address)
        return toast.error("정확한 주소를 입력해주세요", { isLoading: false, hideProgressBar: true });
      const toastId = "place-register";
      toast.loading("Registering place", {
        toastId,
      });

      const place = {
        name: self.location.buildingName,
        address: self.location.selected?.address_name,
        address_detail: self.location.placeName,
        thumbnail_url: "",
        latitude: self.location.selected?.y,
        longitude: self.location.selected?.x,
        extra_1:
          self.location.selected?.road_address?.building_name +
          `${self.location.selected?.road_address?.main_building_no}`,
        extra_2: self.location.selected?.road_address?.main_building_no,
        extra_3: self.location.selected?.road_address?.zone_no,
        extra_4: self.location.selected?.road_address?.underground_yn,
      };

      const res = await api.post<PlaceRegisterResponse>("/v1/place", place);

      if (!res.ok) {
        toast.update(toastId, {
          type: "error",
          render: "이미 등록된 장소입니다",
          hideProgressBar: true,
          isLoading: false,
        });
      } else {
        toast.update(toastId, {
          type: "success",
          render: "장소가 성공적으로 등록되었습니다",
          hideProgressBar: true,
          isLoading: false,
        });
      }
      callback && res.data && callback(res.data?.place.uuid);

      setTimeout(() => {
        toast.dismiss(toastId);
      }, 2000);
    },
    reset(key?: "stepper" | "sensor" | "location" | "hub") {
      switch (key) {
        case "stepper":
          self.stepper = StepperModel.create({ data: STEPS });
          break;
        case "sensor":
          self.sensor = SensorModel.create({ list: SENSORS_LIST });
          break;
        case "location":
          self.location = SensorLocationModel.create();
          break;
        case "hub":
          self.hub = SensorHubModel.create();
          break;
        default:
          self.stepper = StepperModel.create({ data: STEPS });
          self.sensor = SensorModel.create({ list: SENSORS_LIST });
          self.location = SensorLocationModel.create();
          self.hub = SensorHubModel.create();
      }
    },
  }));

export type SensorRegisterModelType = Instance<typeof SensorRegisterModel>;
export default SensorRegisterModel;
