import { Instance, types } from "mobx-state-tree";
import FireDetectionSectionOne, { FireDetectionSectionOneType } from "./FireDetectionSectionOne";
import FireDetectionSectionTwo, { FireDetectionSectionTwoType } from "./FireDetectionSectionTwo";

const SensorFireDetectionModel = types
  .model({
    sectionOne: types.optional(FireDetectionSectionOne, {} as FireDetectionSectionOneType),
    sectionTwo: types.optional(FireDetectionSectionTwo, {} as FireDetectionSectionTwoType),
  })
  .actions((self) => ({
    refresh(sensorId: string) {
      self.sectionOne.init(sensorId);
      self.sectionTwo.init(sensorId);
    },
  }));

export type SensorFireDetectionModelType = Instance<typeof SensorFireDetectionModel>;
export default SensorFireDetectionModel;
