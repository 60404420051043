import Grid from "@mui/material/Grid";
import { observer } from "mobx-react-lite";
import ReactApexChart from "react-apexcharts";
import { GateSectionTwoType } from "../../../../../models/detailed-data/gate/GateSectionTwo";
import { MssDateRangePicker } from "../common/MssDatePicker";
import { heatmapOptions } from "./GateConstants";

type Props = {
  sectionTwo: GateSectionTwoType;
  sensorId: string;
};

const GateSectionTwo = observer(({ sectionTwo, sensorId }: Props) => {
  const { heatmapData, startDate, endDate, setStartDate, setEndDate } = sectionTwo;
  return (
    <Grid container marginTop={"76px"} justifyContent="space-between">
      <Grid item>
        <span className="title">개별 입출입 데이터 그래프</span>
        <span className="sub-title">각 실내 공간별 설정 기간에 따른 인구수 변화 추이를 나타냅니다</span>
      </Grid>
      <Grid item>
        <MssDateRangePicker
          startDate={startDate}
          endDate={endDate}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
        />
      </Grid>
      <Grid container>
        <Grid item flex={1} marginTop="80px">
          <ReactApexChart options={heatmapOptions} series={heatmapData.slice()} height={210} type="area" />
        </Grid>
      </Grid>
    </Grid>
  );
});

export default GateSectionTwo;
