import Grid from "@mui/material/Grid";
import { observer } from "mobx-react-lite";
import { useSensorRegisterStore } from "../../../contexts/SensorRegisterContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SensorIdInput from "../../common/SensorIdInput";
import Button from "../../common/Button";
import MssIcon, { IconKey } from "../../common/MssIcon";

const MAX_LENGTH = 12;

const Gate = observer(() => {
  const { sensor, stepper } = useSensorRegisterStore();
  const navigate = useNavigate();
  return (
    <Grid container>
      <Grid container flexDirection={"column"}>
        <span className="title">센서의 Mac-Address를 입력해주세요</span>
        <span className="sub-title">구매하신 센서 아래에 적혀있는 Mac-Address를 입력해주세요</span>
      </Grid>

      <Grid container marginTop={"67px"}>
        <SensorIdInput
          length={MAX_LENGTH}
          onEnterPressed={() => {
            if (sensor.uniqueId.length !== MAX_LENGTH) return toast.error("센서의 MAC 주소를 입력해주세요");
            stepper.nextStep();
          }}
          onChange={(v) => sensor.setUniqueId(v)}
          containerClassName="sensor-input"
        />

        <Grid container justifyContent={"flex-end"}>
          <Button btnType="text" sx={{ textDecoration: "underline", marginTop: "16px", fontSize: "12px" }}>
            Mac-Address를 확인할 수 없나요?
          </Button>
        </Grid>
        <Grid container alignItems={"center"} justifyContent="center" marginTop={"30px"}>
          <Grid item>
            <MssIcon name={sensor.selectedType as IconKey} className="sensor-model" />
          </Grid>
          <Grid item>
            <table>
              <tbody>
                <tr>
                  <td className="start">센서 종류</td>
                  <td className="end">Realstep GATE</td>
                </tr>
                <tr>
                  <td className="start">센서 명칭</td>
                  <td className="end">특정소출력 무선기기</td>
                </tr>
                <tr>
                  <td className="start">모 델 명</td>
                  <td className="end">RS-SP-162PW</td>
                </tr>
              </tbody>
            </table>
          </Grid>
        </Grid>
        <Grid container marginTop={"50px"} justifyContent={"space-between"}>
          <Button btnType="text" onClick={() => navigate("/sensor-register")}>
            {"< 이전"}
          </Button>

          <Button
            btnType={sensor.uniqueId.length === MAX_LENGTH ? "default" : "disabled"}
            tooltipLabel={sensor.uniqueId.length === MAX_LENGTH ? "다음" : "센서의 MAC 주소를 입력해주세요"}
            onClick={() => sensor.uniqueId.length === MAX_LENGTH && stepper.nextStep()}
          >
            다음 단계
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default Gate;
