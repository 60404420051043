import { ChangeEvent } from "react";
import { RequiredMark } from "../RequiredMark";

// import Timer from "../../utilities/Timer";

import { Button, TextField, Grid, Container } from "@mui/material";

import "./index.css";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../../../contexts/RootContext";

export const ValidatePhoneNumber = observer(() => {
  const { signupStore } = useRootStore();
  const { phone_number, setPhoneNumber, auth_code, setAuthCode } = signupStore;

  const onPhoneNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    value = value.replaceAll("_", "");
    setPhoneNumber(value);
  };

  const onAuthKeyChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAuthCode(e.target.value);
  };

  const onClick = () => {
    // if (phoneNumber === "" || phoneNumber.length < 11) {
    //   setAlert({
    //     type: "error",
    //     message: "Please enter full cellphone number",
    //   });
    //   return;
    // }
    // if (props.timer) {
    //   props.setTimer(false);
    //   setTimeout(() => {
    //     props.setTimer(true);
    //   }, 1000);
    // } else {
    //   props.setTimer(true);
    // }
    // handleCheckPhoneNumber();
  };

  // const handleCheckPhoneNumber = () => {
  // if (!isLockedSubmit) {
  //   setLockedSubmit(true);
  //   api_pcp
  //     .post("/v1/sign-up/auth-code", { phone_number: phoneNumber })
  //     .then((res) => {
  //       if (!res.ok) {
  //         setAlert({
  //           type: "error",
  //           message: "Phone number is not valid, please try again later",
  //         });
  //         return;
  //       }
  //       setAlert({
  //         type: "success",
  //         message: "We have sent you authentication key!",
  //       });
  //       profile.request_key = res.data.request_key;
  //       profile.phone_number = phoneNumber;
  //       setLockedSubmit(false);
  //     });
  // }
  // };

  const onSubmit = () => {
    // if (!authKey) {
    //   setAlert({
    //     type: "error",
    //     message: "Please enter authentication key!",
    //   });
    //   return;
    // }
    // if (authKey !== "123456") {
    //   setAlert({
    //     type: "error",
    //     message: "Authentication key is not correct, please try  again later!",
    //   });
    //   return;
    // }
    // setAlert({
    //   type: "success",
    //   message: "Successfully verified!",
    // });
    // props.setTimer(false);
    // profile.auth_code = authKey;
    // props.setProfile(profile);
  };

  return (
    <Container>
      <Grid container marginTop="20px" columnSpacing={1}>
        <Grid item xs={12} md={2}>
          <span style={{ fontSize: "18px", fontWeight: "bold" }}>
            <RequiredMark /> 휴대폰
          </span>
        </Grid>

        <Grid item xs={7} md={4}>
          <TextField
            label="010-0000-0000"
            size="small"
            fullWidth
            value={phone_number}
            onChange={onPhoneNumberChange}
            variant={"outlined"}
            InputProps={
              {
                // inputComponent: NumberFormatCustom,
              }
            }
          />
        </Grid>
        <Grid item xs={5} md={2}>
          <Button fullWidth onClick={onClick} variant={"contained"} className={"signup-btn"}>
            인증문자 전송
          </Button>
        </Grid>
        <Grid item xs={7} md={2} marginTop={{ xs: "20px", md: "0px" }}>
          <TextField
            type={"text"}
            value={auth_code}
            onChange={onAuthKeyChange}
            className={"signup-text-input auth-key"}
            variant={"outlined"}
            label={"인증번호입력"}
            size="small"
            InputProps={
              {
                // inputComponent: NumberFormatAuthKey,
              }
            }
          />
        </Grid>
        <Grid
          item
          xs={2}
          md={1}
          container
          alignItems={"center"}
          marginTop={{ xs: "20px", md: "0px" }}
          justifyContent={"center"}
        >
          {/* {props.timer ? <Timer className={"timer"} seconds={180} /> : null} */}
        </Grid>
        <Grid item xs={1} md={1} marginTop={{ xs: "20px", md: "0px" }}>
          <Button fullWidth className={"signup-btn"} variant={"contained"} onClick={onSubmit}>
            학은
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
});
