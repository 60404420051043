import { Box, Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import MssIcon, { IconKey } from "../common/MssIcon";
import { useSensorRegisterStore } from "../../contexts/SensorRegisterContext";
import { StyleSheet } from "../dashboard/sensor/overview/SpotDataInDetail";
import { getSensorInfo } from "../../utils/SensorUtils";
import { usePlaceStore } from "../../contexts/PlaceContext";
import Button from "../common/Button";
import { useState } from "react";

const SensorConfirm = observer(() => {
  const [pending, setPending] = useState(false);
  const { sensor, location, hub, stepper, registerSensor } = useSensorRegisterStore();
  const { selectedPlace, selected } = usePlaceStore();
  console.log("SENSOR_TYPE: ", sensor.type);
  return (
    <Box
      sx={{
        height: "100vh",
        overflowY: "scroll",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        "::-webkit-scrollbar": { display: "none" },
        flex: 3,
      }}
    >
      <Grid container flexDirection={"column"} alignItems={"center"}>
        <span className="title">센서 세부 정보를 확인해 주세요</span>
        <span className="sub-title">센서 세부 정보를 등록하는 중입니다.</span>
      </Grid>

      <Grid container marginTop={"40px"}>
        <Grid item md={6}>
          <Grid container justifyContent={"center"}>
            <MssIcon name={sensor.type ? (sensor.selectedType as IconKey) : "SPOT"} height={350} width={200} />
          </Grid>
        </Grid>
        <Grid item container md={6} alignItems="center" flexDirection={"column"} justifyContent="center">
          <Grid container alignItems={"center"}>
            <span style={styles.title}>센서 이름</span>
            <input
              type="text"
              value={sensor.name}
              autoFocus
              placeholder="센서 이름"
              onChange={(e) => sensor.setName(e.target.value)}
              style={styles.input}
            />
          </Grid>
          <Grid container mt={1} alignItems={"center"}>
            <span style={styles.title}>센서 유형</span>
            <span style={styles.subTitle}>{getSensorInfo("", sensor.selectedType ? sensor.selectedType : "").kr}</span>
          </Grid>
          <Grid container mt={1} alignItems={"center"}>
            <span style={styles.title}>센서 아이디</span>
            <span style={styles.subTitle}>{sensor.uniqueId}</span>
          </Grid>
          {hub.sensorCount > 0 && (
            <Grid container mt={1} alignItems={"center"}>
              <span style={styles.title}>허비 아이디</span>
              <span style={styles.subTitle}>{hub.uuid}</span>
            </Grid>
          )}
          <Grid container mt={1} alignItems={"center"}>
            <span style={styles.title}>센서 설치 장소</span>
            <span style={styles.subTitle}>{selectedPlace?.name}</span>
          </Grid>
          <Grid container mt={1} alignItems={"center"}>
            <span style={styles.title}>센서 설치 주소</span>
            <span style={styles.subTitle}>
              {selectedPlace?.address}, {selectedPlace?.address_detail}
            </span>
          </Grid>
        </Grid>
        <Grid container justifyContent={"space-between"}>
          <Button btnType="text" onClick={() => stepper.previousStep()}>
            {"< 이전"}
          </Button>
          <Button
            loading={pending}
            btnType={sensor.name ? "default" : "disabled"}
            tooltipLabel={sensor.name ? "등록" : "센서 이름을 입력해주세요"}
            sx={{ minWidth: "100px" }}
            onClick={() => {
              if (sensor.name) {
                setPending(true);
                registerSensor(selected ? selected : "", (success) => {
                  setPending(false);
                  success && stepper.nextStep();
                });
              }
            }}
          >
            등록
          </Button>
        </Grid>
      </Grid>

      <Box marginBottom={"200px"} />
    </Box>
  );
});

const styles = StyleSheet.create({
  title: {
    fontSize: "16px",
    // fontWeight: "bold",
    color: "#4b4b4b",
    marginBottom: "2px",
    minWidth: "150px",
  },
  input: {
    height: "35px",
    borderRadius: "5px",
    outline: "none",
    backgroundColor: "#fafaff",
    padding: "0 20px",
    minWidth: "200px",
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    color: "#848484",
    border: "1px solid #abaaba",
    marginTop: "1px",
  },
  subTitle: {
    minHeight: "35px",
    borderRadius: "5px",
    outline: "none",
    backgroundColor: "#fafaff",
    padding: "0 20px",
    minWidth: "200px",
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    color: "#848484",
    // border: "1px solid #abaaba",
    marginTop: "1px",
    textOverflow: "...",
    maxWidth: "200px",
  },
});

export default SensorConfirm;
