import { Box, Container, Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useSensorRegisterStore } from "../../../contexts/SensorRegisterContext";
import { Outlet } from "react-router-dom";
import Stepper from "../../../components/sensor-register/Stepper";
import "../../../css/sensor-register.css";

const SensorRegisterScreen = observer(() => {
  const { stepper, reset } = useSensorRegisterStore();

  useEffect(() => {
    reset();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100%",
        overflowY: "hidden",
        backgroundColor: "#F7F7FC",
      }}
    >
      <Container style={{ marginTop: "70px" }}>
        <Grid container flexDirection={"column"}>
          <span style={{ marginTop: "64px", marginBottom: "32px", fontSize: "32px" }}>센서 등록하기</span>
          <div style={{ width: "53px", backgroundColor: "#333", height: "2px" }}></div>
        </Grid>
        <Grid container>
          <Stepper step={stepper.step} steps={stepper.steps} />
          <Grid
            item
            flex={1}
            sx={{
              backgroundColor: "#fff",
              height: "100vh",
              padding: "50px",
              borderRadius: "10px",
              border: "1px solid #D7D7D7",
            }}
          >
            <Outlet />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
});

export default SensorRegisterScreen;
