import { types } from "mobx-state-tree";

const StepsModel = types.model({
  type: types.string,
  steps: types.array(types.model({ label: types.string })),
});

const StepperModel = types
  .model({
    data: types.array(StepsModel),
    step: types.optional(types.number, 0),
    type: types.optional(types.string, ""),
  })
  .views((self) => ({
    get steps() {
      if (!self.type) return [];
      const steps = self.data.find((step) => step.type === self.type)?.steps;
      return steps ? steps : [];
    },
  }))
  .actions((self) => ({
    setStep(step: number) {
      self.step = step;
    },
    setType(type: string) {
      self.type = type;
    },
  }))
  .actions((self) => ({
    nextStep() {
      self.step <= self.steps?.length - 1 && self.setStep(self.step + 1);
    },
    previousStep() {
      self.step && self.setStep(self.step - 1);
    },
  }));

export default StepperModel;
