import { ChangeEvent } from "react";
import { Instance, types } from "mobx-state-tree";

const SigninModel = types
  .model({
    id: types.optional(types.string, ""),
    password: types.optional(types.string, ""),
    saveId: types.optional(types.boolean, false),
    autoLogin: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    setId(event: ChangeEvent<HTMLInputElement>) {
      self.id = event.target.value;
    },
    setPassword(event: ChangeEvent<HTMLInputElement>) {
      self.password = event.target.value;
    },
    setSaveId(saveId: boolean) {
      self.saveId = saveId;
    },
    setAutoLogin(autoLogin: boolean) {
      self.autoLogin = autoLogin;
    },
  }));

export type SigninType = Instance<typeof SigninModel>;

export default SigninModel;
