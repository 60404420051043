import { Box, Grid, MenuList } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import KakaoMap from "../KakaoMap";
import { useSensorRegisterStore } from "../../../contexts/SensorRegisterContext";
import PlaceRegisterModal from "../../modals/PlaceRegisterModal/PlaceRegisterModal";
import { usePlaceStore } from "../../../contexts/PlaceContext";
import { useDetailedDataStore } from "../../../contexts/DetailedDataContext";
import api from "../../../api/api";
import Place from "./Place";
import "./index.css";
import MuiIcon from "../../common/MuiIcon";
import Button from "../../common/Button";

type Props = {
  mapIncluded?: boolean;
  editButton?: boolean;
  addButton?: boolean;
  title?: string;
};

const PlacesList = observer(({ mapIncluded = true, editButton = true, addButton = false, title }: Props) => {
  const { list, selected, setSelected, fetchPlaces } = usePlaceStore();
  const { stepper, location } = useSensorRegisterStore();
  const { setShowMaster } = useDetailedDataStore();

  useEffect(() => {
    fetchPlaces();
  }, [fetchPlaces]);

  return (
    <>
      <Grid
        item
        sx={{
          position: "sticky",
          top: mapIncluded ? "0" : "20px",
          paddingTop: title ? "33px" : "0px",
          backgroundColor: "#fff",
          zIndex: 10,
          paddingBottom: "10px",
        }}
      >
        {mapIncluded && <KakaoMap />}
        {title && <p style={{ fontSize: 24, fontWeight: "bold", marginBottom: "0px" }}>{title}</p>}
      </Grid>

      <MenuList sx={{ paddingBottom: "100px" }}>
        {addButton && (
          <Button
            fullWidth
            onClick={() => {
              location.setRegisterModal(true);
            }}
            btnType="text"
            sx={{
              boxShadow: "inset 0 0 0 1px #D7D7D7",
              borderRadius: "10px",
              padding: "18px",
              // marginTop: "16px",
              height: "145px",
            }}
          >
            <Grid container flexDirection={"column"} alignItems="center">
              <MuiIcon name="add-circle" fontSize="large" htmlColor="#7D88B0" />
              <p style={{ color: "#7D88B0", fontSize: "18px", fontWeight: "bold", margin: "0px" }}>
                장소나 건물을 등록해보세요!
              </p>
            </Grid>
          </Button>
        )}
        {list.slice().map((place) => {
          place.setThumbnailUrl(`${api.getBaseURL()}/v1/place/${place.uuid}/thumbnail`);
          return (
            <Place
              key={place.uuid}
              place={place}
              onCLick={() => {
                setSelected(place.uuid);
                setShowMaster(false);
                location.setUUID(place.uuid);
              }}
              active={selected === place.uuid ? true : false}
            />
          );
        })}
      </MenuList>
      <Box
        justifyContent={"space-between"}
        sx={{
          position: "sticky",
          bottom: 256,
          display: mapIncluded ? "none" : "flex",
          backgroundColor: "#fff",
          paddingY: "20px",
          zIndex: 20,
        }}
      >
        <Button onClick={() => stepper.previousStep()} btnType="text">
          {"< 이전"}
        </Button>

        <Button
          btnType={selected ? "default" : "disabled"}
          tooltipLabel={selected ? "다음" : "장소를 선택해주세요"}
          onClick={() => selected && stepper.nextStep()}
        >
          다음 단계
        </Button>
      </Box>
      <PlaceRegisterModal />
    </>
  );
});

export default PlacesList;
