import Grid from "@mui/material/Grid";
import { ApexOptions } from "apexcharts";
import { observer } from "mobx-react-lite";
import ReactApexChart from "react-apexcharts";
import { SpotSectionTwoType } from "../../../../../models/detailed-data/spot/SpotSectionTwo";
import { MssDatePicker } from "../common/MssDatePicker";
import { EMPTY_CHART } from "../DoorMoreDetailed/DoorConsts";
import { Pie } from "@nivo/pie";
import { SPOT_PIE_DATA } from "./SpotConstants";

type Props = {
  sectionTwo: SpotSectionTwoType;
  sensorId: string;
};

const SpotSectionTwo = observer(({ sectionTwo, sensorId }: Props) => {
  const { startDate, setStartDate, weekly, init } = sectionTwo;

  const options: ApexOptions = {
    chart: {
      height: 350,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    labels: weekly.bar_categories ? weekly.bar_categories : [],
    colors: ["#352D59", "#6AE1E3", "#6852CF", "#5B8FF9", "#C8CCDB"],
    fill: {
      gradient: {},
    },
    plotOptions: {
      bar: {
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      colors: ["transparent"],
    },

    legend: {
      show: false,
    },
  };

  return (
    <Grid container marginTop="50px">
      <Grid container justifyContent="space-between">
        <Grid item>
          <span className="title">전체 유동인구 감지 데이터 (최근 24시간)</span>
          <span className="sub-title">각 실내 공간별 설정 기간에 따른 유동인구 변화 추이를 나타냅니다</span>
        </Grid>
        <Grid item>
          <MssDatePicker
            startDate={startDate}
            setStartDate={(v: string) => {
              setStartDate(v);
              init(sensorId);
            }}
            // customInput={<DateInput />}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={6}>
          <ReactApexChart
            options={options}
            series={weekly.bar_data ? [weekly.bar_data] : EMPTY_CHART}
            type="bar"
            height={300}
          />
        </Grid>
        <Grid item md={6}>
          <div style={{ textAlign: "center" }}>
            <Pie
              width={350}
              height={250}
              data={weekly.pie ? weekly.pie : SPOT_PIE_DATA}
              margin={{
                top: 30,
                right: 30,
                bottom: 30,
                left: 30,
              }}
              innerRadius={0.02}
              padAngle={2}
              cornerRadius={3}
              //   colorBy="id"
              // if you want use different colors, modify code given below
              colors={["#352D59", "#6AE1E3", "#6852CF", "#5B8FF9", "#C8CCDB"]}
              //   label value format
              arcLabel={function (obj: any) {
                return Math.round((obj.value / 713) * 100) + "%";
              }}
              //  arc value
              arcLinkLabel={function (e) {
                return String(e.value);
              }}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: "color" }}
              arcLabelsTextColor="#ffffff"
              borderColor="inherit:darker(0.6)"
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsStraightLength={10}
              arcLinkLabelsDiagonalLength={10}
              //   radialLabelsTextColor={function () {
              //     return "#333333";
              //   }}
              //   slicesLabelsSkipAngle={10}

              //   slicesLabelsTextColor="#333333"
              animate={true}
              //   motionStiffness={90}
              //   motionDamping={15}
              theme={{
                tooltip: {
                  container: {
                    fontSize: "13px",
                  },
                },
                labels: {
                  text: { color: "#555" },
                },
              }}
            />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default SpotSectionTwo;
