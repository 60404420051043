import { Container, Grid, Typography } from "@mui/material";
import Button from "../../../../components/common/Button";
import SensorIdInput from "../../../../components/common/SensorIdInput";

const Playground = () => {
  return (
    <Container sx={{ border: "1px solid black", paddingY: "20px" }}>
      <Typography variant="h2">Components demo</Typography>
      <Typography variant="h3">Default buttons</Typography>
      <Grid container mb={2}>
        <Button>Primary</Button>
        <Button btnType="secondary">Secondary</Button>
        <Button btnType="disabled">Disabled</Button>
        <Button btnType="text">Text</Button>
      </Grid>
      <Typography variant="h3">Loading buttons</Typography>
      <Grid container mb={2}>
        <Button loading={true}>Primary Loading</Button>
        <Button btnType="secondary" loading={true}>
          Primary Loading
        </Button>
        <Button btnType="disabled" loading={true}>
          Primary Loading
        </Button>
        <Button btnType="text" loading={true}>
          Primary Loading
        </Button>
      </Grid>
      <Typography variant="h3">Default buttons with tooltip</Typography>
      <Grid container mb={2}>
        <Button tooltipLabel="Primary tooltip">Primary</Button>
        <Button tooltipLabel="Secondary tooltip" btnType="secondary">
          Secondary
        </Button>
        <Button tooltipLabel="Disabled tooltip" btnType="disabled">
          Disabled
        </Button>
        <Button tooltipLabel="Text tooltip" btnType="text">
          Text
        </Button>
      </Grid>
      <Typography variant="h3">Sensor id input</Typography>
      <Grid container mb={2}>
        {/* <SensorIdInput length={10} onChange={(v) => console.log(v)} /> */}
        <SensorIdInput length={5} onChange={(v) => console.log(v)} />
      </Grid>
    </Container>
  );
};

export default Playground;
