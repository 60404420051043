import { Grid, MenuItem } from "@mui/material";
import { PlaceListType } from "../../../models/main/PlaceModel";
import { PlaceImage } from "./PlaceImage";
import { RiBuilding2Line } from "react-icons/ri";
import unityViewIcon from "../../../resources/common/3d-printer.svg";
import Unity3DBuildingViewModal from "../../modals/Unity3DBuildingViewModal";
import {useState} from "react";

type Props = {
  place: PlaceListType;
  onCLick: () => void;
  active: boolean;
};

const Place = ({ place, onCLick, active }: Props) => {
    const [unityViewModalShow, setUnityViewModalShow] = useState(false);

  return (
    <Grid
      key={place.uuid}
      sx={{
        boxShadow: active ? "inset 0 0 0  2px  #6049CC" : "inset 0 0 0 1px #D7D7D7",
        borderRadius: "10px",
        padding: "18px",
        marginTop: "16px",
        transitionDuration: "0s !important",
        "&:hover": {
          backgroundColor: "#fff ",
        },
      }}
    >
      <Grid container>
        <Grid
          item
          alignItems={"center"}
          justifyContent="center"
          display={"flex"}
          height={110}
          width={173}
          overflow="hidden"
          sx={{ backgroundColor: "#F7F7FC", borderRadius: "5px" }}
        >
          <PlaceImage editBtn place={place} noImageIconSize={40} />
        </Grid>
        <MenuItem
          disableRipple
          onClick={onCLick}
          sx={{
            flex: 1,
            flexDirection: "column",
            alignItems: "flex-start",
            paddingLeft: "21px",
            "&:hover": { backgroundColor: "transparent" },
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <Grid item container>
            <Grid item container flexDirection={"row"} justifyContent="space-between" alignItems="center">
              <Grid item>
                <span
                  style={{
                    color: active ? "#6049CC" : "#333333",
                    fontSize: "22px",
                    fontWeight: "bold",
                  }}
                >
                  {place.name}
                </span>
                <span style={{ color: "#ABAABA", fontSize: "14px", marginLeft: "9px", textOverflow: "ellipsis" }}>
                  {place.address}, {place.address_detail}
                </span>
              </Grid>
                <Grid>
                    <img src={unityViewIcon} width={25} onClick={() => {setUnityViewModalShow(true)}}/>
                </Grid>

                {/* {editButton && (
                    <IconButton style={{ alignSelf: "start" }}>
                      <FaRegEdit size={17} color="#B3B3B3" />
                    </IconButton>
                  )} */}
            </Grid>
          </Grid>
          <Grid
            item
            container
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems="center"
            sx={{ backgroundColor: "#F7F7FC", borderRadius: "5px" }}
            textAlign="start"
            flex={1}
          >
            <Grid item flex={1} justifyContent={"flex-start"} paddingLeft={"22px"}>
              <span style={{ display: "block", fontSize: "11px", fontWeight: "bold", color: "#7D88B0" }}>
                건물 이름
              </span>
              <span style={{ display: "block", fontSize: "14px", color: "#333333", marginRight: "20px" }}>
                {place.extra_1}
              </span>
            </Grid>
            <Grid item flex={1}>
              <span style={{ display: "block", fontSize: "11px", fontWeight: "bold", color: "#7D88B0" }}>
                건물 좌표
              </span>
              <span style={{ display: "block", fontSize: "14px", color: "#333333" }}>{place.longitude}</span>
            </Grid>
            <Grid item flex={1}>
              <span style={{ display: "block", height: "15px", fontWeight: "bold", color: "#7D88B0" }}></span>
              <span style={{ display: "block", fontSize: "14px", color: "#333333" }}>{place.latitude}</span>
            </Grid>
            <Grid item flex={1}>
              <span style={{ display: "block", fontSize: "11px", fontWeight: "bold", color: "#7D88B0" }}>지하여부</span>
              <span style={{ display: "block", fontSize: "14px", color: "#333333" }}>{place.extra_4}</span>
            </Grid>
          </Grid>
        </MenuItem>
      </Grid>
        <Unity3DBuildingViewModal open={unityViewModalShow} onClose={() => setUnityViewModalShow(false)} />
    </Grid>
  );
};

export default Place;
