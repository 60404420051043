import { Document, KakaoAddressSearchTYpe } from "./../../../types/kakao";
import { Instance, types } from "mobx-state-tree";
import kakap_api from "../../api/kakao_api";

const SensorLocationModel = types
  .model({
    uuid: types.maybeNull(types.string),
    query: types.optional(types.string, ""),
    selected: types.maybeNull(types.frozen<Document>()),
    address: types.maybeNull(types.frozen<KakaoAddressSearchTYpe>()),
    buildingName: types.optional(types.string, ""),
    placeName: types.optional(types.string, ""),
    purpose: types.maybeNull(types.number),
    registerModal: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get addressList() {
      return self.address?.documents;
    },
  }))

  .actions((self) => ({
    setUUID(uuid: string | null) {
      self.uuid = uuid;
    },
    setBuildingName(buildingName: string) {
      self.buildingName = buildingName;
    },
    setPlaceName(placeName: string) {
      self.placeName = placeName;
    },
    setAddress(address: KakaoAddressSearchTYpe) {
      self.address = address;
    },
    setSelected(selected: Document) {
      if (selected.x) {
        self.selected = selected;
      }
    },
    setPurpose(purpose: number) {
      self.purpose = purpose;
    },
    setRegisterModal(bool: boolean) {
      self.registerModal = bool;
    },
  }))
  .actions((self) => ({
    fetchAddresses: async () => {
      if (self.query) {
        const res = await kakap_api.get<KakaoAddressSearchTYpe>("/v2/local/search/address.json", {
          analize_type: "similar",
          page: 1,
          size: 10,
          query: self.query,
        });

        if (!res.ok) return;
        res.data && self.setAddress(res.data);
      }
    },
  }))
  .actions((self) => ({
    setQuery: (query: string) => {
      self.query = query;
    },
  }));

export type SensorLocationModelType = Instance<typeof SensorLocationModel>;
export default SensorLocationModel;
