import { observer } from "mobx-react-lite";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import { useSensorRegisterStore } from "../../../contexts/SensorRegisterContext";
import { useEffect } from "react";
import MuiIcon from "../../../components/common/MuiIcon";
import MssIcon, { IconKey } from "../../../components/common/MssIcon";
import Button from "../../../components/common/Button";

const SensorRegisterSelect = observer(() => {
  const { stepper, sensor } = useSensorRegisterStore();
  const navigate = useNavigate();
  const getStepperTypeName = (sensorType: string) => {
    switch (sensorType) {
      case "SPOT":
        return "SPOT";
      case "GATE":
        return "GATE";
      case "GATE_3D":
        return "GATE_3D";
      default:
        return "HUB";
    }
  };

  useEffect(() => {
    stepper.setStep(0);
  }, []);

  return (
    <Grid item>
      <Grid container flexDirection={"column"}>
        <span className="title">등록할 센서 종류를 선택해 주세요</span>
        <span className="sub-title">아래에서 설치하시는 센서의 모습과 일치하는 사진을 선택해주세요</span>
      </Grid>
      <Grid container display={"flex"} marginTop={"20px"}>
        {sensor.list.map((s) => (
          <Grid
            key={s.id}
            item
            width={"20%"}
            display="flex"
            flexDirection={"column"}
            alignItems="center"
            marginTop={"40px"}
          >
            <IconButton
              style={{ height: "100px", width: "100px", position: "relative", overflow: "hidden" }}
              onClick={() => {
                sensor.setSelected(s.id);
                stepper.setType(getStepperTypeName(s.type));
              }}
            >
              {s.id === sensor.selected && (
                <div
                  style={{
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                    top: 0,
                    left: 0,
                    backgroundColor: "rgba(80,205,208, 0.4)",
                    borderRadius: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: "0 0 0 2px inset rgb(80,205,208)",
                  }}
                >
                  <MuiIcon name="check" sx={{ color: "#fff", fontSize: "44px" }} />
                </div>
              )}
              <MssIcon name={s.type as IconKey} height={"70px"} width={"70px"} />
            </IconButton>
            <span style={{ marginTop: "10px" }}>{s.name}</span>
          </Grid>
        ))}
        <Grid container justifyContent={"flex-end"} marginTop="150px">
          <Button
            tooltipLabel={sensor.selected ? "다음" : "센서를 선택해주세요"}
            btnType={sensor.selected ? "default" : "disabled"}
            onClick={() => {
              if (sensor.selected) {
                navigate("steps");
                stepper.setStep(1);
              }
            }}
          >
            다음 단계
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default SensorRegisterSelect;
