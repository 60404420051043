import { IconButton } from "@mui/material";
import { useState } from "react";
import PlaceThumbnailModal from "../../modals/PlaceThumbnailModal/PlaceThumbnailModal";
import MssIcon from "../../common/MssIcon";
import { PlaceListType } from "../../../models/main/PlaceModel";

type Props = {
  editBtn?: boolean;
  place: PlaceListType;
  noImageIconSize?: number;
};

export const PlaceImage = ({ editBtn, place, noImageIconSize }: Props) => {
  const [open, setOpen] = useState(false);
  const [imageIsAvailable, setImageIsAvailable] = useState(true);
  const openModal = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={editBtn ? "img-wrapper hover" : "img-wrapper "}>
      <PlaceThumbnailModal open={open} onClose={handleClose} place={place} />
      {editBtn && (
        <IconButton className={"edit-btn"} onClick={openModal} sx={{ width: "40", height: "40px" }}>
          <MssIcon name="image-edit" style={{ fontSize: "20px", color: "#fff" }} />
        </IconButton>
      )}
      {imageIsAvailable ? (
        <img
          alt="Thumbnail"
          width={"100%"}
          src={place.thumbnail_url}
          onError={() => {
            setImageIsAvailable(false);
          }}
        />
      ) : (
        <MssIcon name="no-img" style={{ width: noImageIconSize ? noImageIconSize : "auto" }} />
      )}
    </div>
  );
};
