export const SPOT_TABLE_DATA = [
  {
    id: 2,
    created_at: "2022-04-05 12:00:00",
    location: "사무실",
    mac_address: "2C3D6H2F",
    count: "7대",
    last_detection: "5분전",
  },
  {
    id: 3,
    created_at: "2022-04-05 12:00:00",
    location: "사무실",
    mac_address: "2C3D6H2F",
    count: "7대",
    last_detection: "5분전",
  },
  {
    id: 4,
    created_at: "2022-04-05 12:00:00",
    location: "대표님실",
    mac_address: "2C3D6H2F",
    count: "7대",
    last_detection: "5분전",
  },
  {
    id: 5,
    created_at: "2022-04-05 12:00:00",
    location: "큰회의실",
    mac_address: "2C3D6H2F",
    count: "7대",
    last_detection: "5분전",
  },
  {
    id: 6,
    created_at: "2022-04-05 12:00:00",
    location: "작은회의실",
    mac_address: "2C3D6H2F",
    count: "7대",
    last_detection: "5분전",
  },
  {
    id: 7,
    created_at: "2022-04-05 12:00:00",
    location: "복도",
    mac_address: "2C3D6H2F",
    count: "7대",
    last_detection: "5분전",
  },
];

export const PIE_SERIES = [
  {
    type: "place 1",
    value: 20,
  },
  {
    type: "place 2",
    value: 529,
  },
  {
    type: "place 3",
    value: 200,
  },
  {
    type: "place 4",
    value: 701,
  },
];
export const SPOT_COLUMN_SERIES = [
  {
    name: "Total",
    data: [100, 180, 275],
  },
  {
    name: "1주",
    data: [50, 55, 57],
  },
  {
    name: "2주",
    data: [5, 85, 101],
  },
  {
    name: "3주",
    data: [10, 41, 36],
  },
];
export const SPOT_HEATMAP_SERIES = [
  {
    name: "Jan",
    data: generateData(20, {
      min: 0,
      max: 550,
    }),
  },
  {
    name: "Feb",
    data: generateData(20, {
      min: 0,
      max: 550,
    }),
  },
  {
    name: "Mar",
    data: generateData(20, {
      min: 0,
      max: 550,
    }),
  },
  {
    name: "Apr",
    data: generateData(20, {
      min: 0,
      max: 550,
    }),
  },
  {
    name: "May",
    data: generateData(20, {
      min: 0,
      max: 550,
    }),
  },
  {
    name: "Jun",
    data: generateData(20, {
      min: 0,
      max: 550,
    }),
  },
  {
    name: "Jul",
    data: generateData(20, {
      min: 0,
      max: 550,
    }),
  },
  {
    name: "Aug",
    data: generateData(20, {
      min: 0,
      max: 550,
    }),
  },
  {
    name: "Sep",
    data: generateData(20, {
      min: 0,
      max: 550,
    }),
  },
];

function generateData(count: number, yrange: { min: number; max: number }) {
  var i = 0;
  var series = [];
  while (i < count) {
    var x = (i + 1).toString();
    var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

    series.push({
      x: x,
      y: y,
    });
    i++;
  }
  return series;
}

export const SPOT_PIE_DATA = [
  {
    id: 1,
    label: "css (custom)",
    value: 410,
  },
  {
    id: 2,
    label: "sass",
    value: 175,
  },
  {
    id: 3,
    label: "javascript",
    value: 128,
  },
  {
    id: 4,
    label: "sass",
    value: 175,
  },
  {
    id: 5,
    label: "javascript",
    value: 128,
  },
];
