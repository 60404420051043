import Grid from "@mui/material/Grid";
import MssIcon from "../../common/MssIcon";

const SpotHelp = () => {
  return (
    <>
      <Grid container flexDirection={"column"} alignItems="center">
        <span className="title" style={{ textAlign: "center", marginTop: "84px" }}>
          등록이 완료되었습니다 <br />
          이제 피플카운트 시스템을 사용해볼까요?
        </span>
        <span style={{ marginTop: "13px" }}>SPOT 센서를 이용해 실내 공간의 유동인구를 비교하고 분석할 수 있습니다</span>
      </Grid>

      <Grid container display={"flex"} marginTop={"67px"}>
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <Grid container alignItems={"center"} justifyContent="center">
            <Grid item md={3}>
              <MssIcon name="SPOT-help" className="sensor-model" />
            </Grid>
            <Grid item md={6} display={"flex"} flexDirection={"column"} sx={{ color: "#7D88B0" }}>
              <span style={{ fontWeight: "bold", fontSize: "16px" }}>[올바른 센서 설치 안내]</span>
              <ul style={{ marginTop: "13px" }}>
                <li>
                  센서 뒷편에 위치한 <b>WLAN</b> 및 전원(<b>Micro-5pin</b>) 케이블을 연결한 후, <b>LED</b> 상태를 확인해
                  주세요.
                </li>
                <br />
                <li>
                  연결상태 표시등(<b>LED</b>) 4개가 모두 들어오면 정상적으로 연결됨을 나타냅니다!
                </li>
              </ul>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SpotHelp;
