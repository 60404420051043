import { cast, Instance, types } from "mobx-state-tree";
import { lineDataGenerate } from "../../../components/dashboard/sensor/more-detailed/GateMoreDetailed/GateConstants";
import { AI_GATE_TABLE_DATA, OPTIONS } from "./dummies";

const OptionItemModel = types.model({
  value: types.number,
  label: types.string,
});

const AiGateSectionOne = types
  .model({
    options: types.array(OptionItemModel),
    selected: types.optional(types.number, 1),
    series: types.array(types.frozen()),
    tableData: types.array(types.frozen()),
  })
  .actions((self) => ({
    setSelected(selected: number, callback: VoidFunction) {
      self.selected = selected;
      callback();
    },
    setOptions(options: Array<OptionItemType>) {
      self.options = cast(options);
    },
    setSeries(series: any) {
      self.series = series;
    },
    setTableData(tableDate: any) {
      self.tableData = tableDate;
    },
  }))
  .actions((self) => ({
    fetchSeries: async () => {
      const series = lineDataGenerate(self.selected, "4");
      self.setSeries(series);
    },
    fetchOptions: async () => {
      self.setOptions(OPTIONS);
    },
    fetchTableData: async () => {
      self.setTableData(AI_GATE_TABLE_DATA);
    },
    init() {
      this.fetchOptions();
      this.fetchSeries();
      this.fetchTableData();
    },
  }));

export type OptionItemType = Instance<typeof OptionItemModel>;
export type AiGateSectionOneType = Instance<typeof AiGateSectionOne>;
export default AiGateSectionOne;
